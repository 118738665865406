import React, { useEffect, useState } from "react";
import agent from "../../../api/agent";
import LoadingComponent from "../../Layout/loadingComponent";
import Licensedto from "../../../models/schools/licensedto";
import { Icon, Table } from "semantic-ui-react";

interface Props {
  chainCode: string;
}

export default function ChainLicenses({ chainCode }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [licenses, setLicenses] = useState<Licensedto[]>([]);

  useEffect(() => {
    if (!chainCode) return;

    setLoading(true);
    agent.Chains.licenses(chainCode)
      .then((result) => {
        setLicenses(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [chainCode]);
  const content = (
    <>
      <h3>Licenses</h3>
      <Table className="t4-tbl">
        <Table.Header>
          <Table.Row>
            <Table.Cell>Contract start date</Table.Cell>
            <Table.Cell>Contract end date</Table.Cell>
            <Table.Cell>Status</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {licenses &&
            licenses.length > 0 &&
            licenses.map((license) => (
              <Table.Row key={license.id}>
                <Table.Cell>{license.startDateString}</Table.Cell>
                <Table.Cell>{license.endDateString}</Table.Cell>
                {license.isActive ? (
                  <Table.Cell>
                    <Icon name="checkmark" />
                    Active
                  </Table.Cell>
                ) : (
                  <Table.Cell error>
                    <Icon name="attention" />
                    Expired
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={"Loading Licenses"} />}
    </>
  );
}
