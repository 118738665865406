import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon, Segment, Table } from "semantic-ui-react";
import SchoolDocument from "../../models/schools/schoolDocument";
import { DocumentTypes } from "../../models/enums";

export default function ExtraDocuments() {
  let params = useParams();
  const navigate = useNavigate();

  const files: SchoolDocument[] = [
    /*{
      name: "Testing_long_document_name_without_any_spacing_to_wrap_the_name_of_the_document_or_truncate_the_text.doc",
      path: "Testing_long_document_name_without_any_spacing_to_wrap_the_name_of_the_document_or_truncate_the_text.doc",
      type: DocumentTypes.Pdf,
    },
    {
      name: "Test File",
      path: "test.docx",
      type: DocumentTypes.Word,
    },*/
  ];

  return (
    <>
      <Segment className="t4-mainmenu t4-dox">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
              }}
            ></Button>
            <h1>Extra Documents</h1>
          </div>
        </div>
        <div className="t4-mng-btns"></div>
        <Table className="t4-tbl t4-tbl-long">
          <Table.Header>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell>File</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {files &&
              files.map((file, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Icon
                      name={file.type === DocumentTypes.Pdf ? "file pdf" : "file word"}
                      size="large"
                    />
                  </Table.Cell>
                  <Table.Cell className="t4-file">{file.name}</Table.Cell>
                  <Table.Cell className="t4-tbl-action">
                    <a href={`/extradocuments/${file.path}`} target="_blank" rel="noreferrer">
                      <Icon name="eye" />
                    </a>
                  </Table.Cell>
                  <Table.Cell className="t4-tbl-action">
                    <a href={`/extradocuments/${file.path}`} target="_blank" rel="noreferrer" download>
                      <Icon name="download" />
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
}
