import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Header, Segment, Table } from "semantic-ui-react";
import SchoolAccessInfoDto from "../../../models/chains/schoolAccessInfoDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";

interface Props {
  chainCode: string;
}

export default function SchoolAccess({ chainCode }: Props) {
  const navigate = useNavigate();
  const [schools, setSchools] = useState<SchoolAccessInfoDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadSchoolAcess = () => {
    setLoading(true);
    agent.Chains.schoolAccessInfo(chainCode)
      .then((result) => setSchools(result))
      .catch((error) => toast.error("Failed to load schools access info"))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadSchoolAcess();
  }, [chainCode]);

  const content = (
    <>
      <Table.Body>
        {schools &&
          schools.length > 0 &&
          schools.map((school) => (
            <Table.Row key={school.schoolId}>
              <Table.Cell>
                <NavLink to={"/dashboard/schoolDetails/" + school.schoolCode}>
                  {school.schoolName}
                </NavLink>
              </Table.Cell>
              <Table.Cell>{school.invitationSentOn}</Table.Cell>
              <Table.Cell>{school.lastLogin}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </>
  );

  return (
    <>
      <Segment className="t4-mainmenu t4-header-align">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/chain/${chainCode}`);
              }}
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              School Access
            </Header>
          </div>
          <Header as="h4" className="t4-header-right" textAlign="right">
            Total Schools: {!schools || schools == null ? 0 : schools.length}
          </Header>
        </div>

        <Table className="t4-tbl sortable">
          <Table.Header>
            <Table.Row>
              <Table.Cell>School Name</Table.Cell>
              <Table.Cell>Survey Sent</Table.Cell>
              <Table.Cell>Last Login</Table.Cell>
            </Table.Row>
          </Table.Header>
          {!loading && content}
        </Table>
        {loading && <LoadingComponent content={""} />}
      </Segment>
    </>
  );
}
