import React from "react";
import { Card, Segment, Header, Button, Image, Table } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import ChainSchoolsStats from "./chainSchoolsStats";
import { useStore } from "../../../Stores/store";

interface Props {
  chainCode: string;
}

export default function SchoolChainDashboard({ chainCode }: Props) {
  const { userStore } = useStore();
  return (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-home-settings">
          <Header as="h1" floated="left">
            Menu
          </Header>
          <div className="t4-mng-icon-btns">
            <Button
              className="t4-manage-btn"
              basic
              right="true"
              icon={<i className="t4-staff-icn icon" />}
              content="Manage Co-ordinators"
              as={NavLink}
              to={`/dashboard/chain/${chainCode}/admins`}
            />
            <Button className="t4-settings-btn" basic floated="right" right="true" icon="settings" as={NavLink} to={`/dashboard/chain/${chainCode}/settings`} />
          </div>
        </div>
        <Card.Group>
          <Card className="t4-mainmenu-cards" as={NavLink} to={`/dashboard/chain/${chainCode}/manageSchools`}>
            <Card.Content>
              <i className="t4-card-icn settings icon" />
              <Card.Header>Manage Schools</Card.Header>
            </Card.Content>
          </Card>
          <Card className="t4-mainmenu-cards" as={NavLink} to={`/dashboard/chain/${chainCode}/schoolAccess`}>
            <Card.Content>
              <i className="t4-card-icn key icon" />
              <Card.Header>School Access</Card.Header>
            </Card.Content>
          </Card>
          {userStore.isSystemAdmin && (
            <Card className="t4-mainmenu-cards" as={NavLink} to={`/dashboard/chain/${chainCode}/managechainsurveys`}>
              <Card.Content>
                <i className="t4-card-icn t4-waves-icn icon" />
                <Card.Header>Manage Waves</Card.Header>
              </Card.Content>
            </Card>
          )}
        </Card.Group>
        <Card.Group>
          <Card
            className="t4-mainmenu-cards t4-content-cards"
            as={NavLink}
            to={`/dashboard/chain/${chainCode}/reports`}
            /*onClick={() => {
              window.open(`/chain/report/${chainCode}`, "_blank", "noreferrer");
            }}*/
          >
            <Card.Header>Reports</Card.Header>
            <Card.Content>
              <Card.Meta>
                <Image floated="right" size="large" src="/assets/reports.svg" />
              </Card.Meta>
            </Card.Content>
          </Card>
          <Card className="t4-mainmenu-cards t4-content-cards" as={NavLink} to={`/dashboard/chain/${chainCode}/extradocuments`}>
            <Card.Header>Extra Documents</Card.Header>
            <Card.Content>
              <Card.Meta>
                <Image floated="right" size="large" src="/assets/extradocs.png" />
              </Card.Meta>
            </Card.Content>
          </Card>
        </Card.Group>
      </Segment>
      <Segment className="t4-dashboard t4-mainmenu">
        <ChainSchoolsStats chainCode={chainCode} />
      </Segment>
    </>
  );
}
