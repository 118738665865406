import React, { useState } from "react";
import { Button, Checkbox, Dropdown, Input } from "semantic-ui-react";
import LookupDto from "../models/common/lookupDto";

interface Props {
  items: LookupDto[];
  selectedItems: number[];
  //When user closes the filter menu and search should be done
  onFilter: () => void;
  //Fired when user changes selection of items
  onFilterChanged: (selectedItems: number[]) => void;
}

export default function GridColumnFilterConrol({
  items,
  onFilterChanged,
  onFilter,
  selectedItems,
}: Props) {
  const [filter, setFilter] = useState<string>("");

  const selectItem = (id: number) => {
    let items: number[] = [...selectedItems];
    let itemIndex = items.indexOf(id);

    if (itemIndex === -1) items.push(id);
    else items = items.filter((i) => i != id);

    onFilterChanged(items);
  };

  const clearSelection = () => {
    onFilterChanged([]);
  };

  return (
    <>
      <Dropdown
        item
        simple
        icon="filter"
        className={
          selectedItems != null && selectedItems.length > 0
            ? "t4-multi-filter-ddl selected"
            : "t4-multi-filter-ddl"
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item>
            <Input
              value={filter}
              type="text"
              icon="search"
              iconPosition="left"
              className="search t4-multi-filter-src"
              onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item className="t4-clear-item">
            <Button
              className="t4-clear-icn"
              icon="filter"
              content={"Clear"}
              onClick={() => {
                clearSelection();
              }}
            ></Button>
          </Dropdown.Item>
          <Dropdown.Divider />
          <div className="t4-filter-content-scroll">
            {items &&
              items
                .filter(
                  (i) =>
                    filter.trim() === "" || i.text.toLowerCase().includes(filter.trim().toLowerCase())
                )
                .map((item) => (
                  <Dropdown.Item key={item.value}>
                    <Checkbox
                      label={item.text}
                      checked={selectedItems.indexOf(item.value) > -1}
                      onChange={() => selectItem(item.value)}
                    />
                  </Dropdown.Item>
                ))}
          </div>
          <Dropdown.Item>
            <Button onClick={() => onFilter()}>Apply</Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
