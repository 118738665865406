import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

export default function LeftNavigationMenu() {
  return (
    <>
      <Dropdown className="t4-mng-menu" button direction="left" floating labeled icon="bars">
        <Dropdown.Menu className="t4-menu-dd">
          <Dropdown.Header icon="sitemap" content="Licensing" />
          <Dropdown.Item as={NavLink} to={"/dashboard/managechains"}>
            Chains
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={"/dashboard/manageschools"}>
            Schools
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={"/dashboard/adminview"}>
            Dashboard
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Header icon="cog" content="Settings" />
          <Dropdown.Item as={NavLink} to={"/dashboard/managequestions"}>
            Questions
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={"/dashboard/notifications"}>
            Notifications
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={"/dashboard/scoringmatrix"}>
            Scoring Matrix
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
