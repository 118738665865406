import React, { useEffect, useState } from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { QuestionTypes } from "../../../models/enums";
import { Button, Icon, Input, Label, List, Message, Radio, Segment } from "semantic-ui-react";
import QuestionChoiceDto from "../../../models/surveys/questionChoiceDto";
import { useSearchParams } from "react-router-dom";

interface Props {
  editQuestion: QuestionDto | null;
  onSave: (question: QuestionDto) => void;
  onCancel: () => void;
  saving: boolean;
}

export default function AddEditMcqDropDownList({ onSave, onCancel, editQuestion, saving }: Props) {
  const [question, setQuestion] = useState<QuestionDto>({} as QuestionDto);
  let [searchParams, setSearchParams] = useSearchParams();
  const[showStartTranslationMessage, setShowStartTranslationMessage]=useState<boolean>(false);

  useEffect(() => {
    if (!editQuestion || editQuestion == null) {
      setQuestion({
        questionType: QuestionTypes.McqDropDown,
        questionOrder: 0,
        title: "New Question",
        body: "",
        choices: [
          { body: "", id: 0 },
          { body: "", id: 0 },
        ],
        code: "",
        id: 0,
        foundationId: null,
        isAnswered: false,
      } as QuestionDto);
    } else {
      setQuestion({ ...editQuestion });
      const showTranslationMessage = searchParams.get('translation');
      if(showTranslationMessage)
        setShowStartTranslationMessage(true);
    }
  }, [editQuestion]);

  const addOption = () => {
    let option: QuestionChoiceDto = { body: "", id: 0 };
    if (question.choices == null) question.choices = [];
    let choices = [...question.choices];
    choices.push(option);
    setQuestion({ ...question, choices: choices });
  };

  const removeOption = (index: number) => {
    if (question.choices == null || question.choices.length === 0) return;
    let choices = [...question.choices];
    choices.splice(index, 1);
    setQuestion({ ...question, choices: choices });
  };

  const setOption = (index: number, val: string) => {
    if (question.choices == null || question.choices.length === 0) return;
    let choices = [...question.choices];
    choices[index].body = val;
    setQuestion({ ...question, choices: choices });
  };

  const [isValidBody, setIsValidBody] = useState<boolean>(true);
  const [invalidOptions, setInvalidOptions] = useState<number[]>([]);

  const validateForm = (values: QuestionDto): boolean => {
    setIsValidBody(true);
    setInvalidOptions([]);
    let isValid = true;

    if (!values.body || values.body == null || values.body.trim() === "") {
      isValid = false;
      setIsValidBody(false);
    }

    if (question.id === 0 && values.choices && values.choices.length > 0) {
      let errors: number[] = [];
      values.choices.forEach((choice, index) => {
        if (!choice.body || choice.body == null || choice.body.trim() === "") {
          isValid = false;
          errors.push(index);
        }
      });
      setInvalidOptions(errors);
    }
    return isValid;
  };

  const submitForm = async (values: QuestionDto) => {
    let isValid = validateForm(values);
    if (isValid) onSave({ ...values });
  };

  return (
    <>
      <Segment className="t4-mainmenu t4-settings-mcqq">  
      {showStartTranslationMessage && <Message className="t4-edit-trans-settings" content="Select ‘Edit Translations’ now to add the translated text"/>}
        <List className="t4-add-mcq">
          
          <List.Item className="t4-add-q">
            <Input
              value={question.body}
              placeholder="Question"
              disabled={showStartTranslationMessage}
              onChange={(e, d) => {
                setQuestion({ ...question, body: d.value });
              }}
            />
            {!isValidBody ? (
              <Label basic color="red">
                {"Required"}
              </Label>
            ) : null}
          </List.Item>
          {question &&
            question.choices &&
            question.choices != null &&
            question.choices.length > 0 &&
            question.choices.map((choice, index) => (
              <List.Item key={index}>
                <Radio key="" label="" value={choice.id} disabled={showStartTranslationMessage} />
                <Input
                  disabled={question.id !== 0 || showStartTranslationMessage}
                  value={choice.body}
                  onChange={(e, d) => {
                    setOption(index, d.value);
                  }}
                />
                {invalidOptions && invalidOptions.includes(index) ? (
                  <Label basic color="red">
                    {"Required"}
                  </Label>
                ) : null}
                {index > 1 && (
                  <Button
                    disabled={question.id !== 0}
                    onClick={() => {
                      removeOption(index);
                    }}
                  >
                    <Icon name="trash alternate outline"></Icon>
                  </Button>
                )}
              </List.Item>
            ))}
          <List.Item>
            <Button
              disabled={question.id !== 0}
              content={"+Add option"}
              onClick={() => {
                addOption();
              }}
            ></Button>
          </List.Item>
        </List>
        <div className="t4-actions t4-save-btns">
          
         {!showStartTranslationMessage && <Button
            floated="right"
              loading={saving}
              type="submit"
              color="blue"
              content="Save"
              className="t4-btn-active"
              onClick={() => submitForm(question)}
          />}
          <Button
            className="t4-btn-non"
            floated="right"
            disabled={saving}
            onClick={() => {
              onCancel();
            }}
          >
            Close
          </Button>
        </div>
      </Segment>
    </>
  );
}
