import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { Container, Dimmer, Loader, Segment, Image, Menu } from "semantic-ui-react";
import SurveyPlayer from "./surveyPlayer";
import SurveyDto from "../../models/surveys/surveyDto";
import { useTranslation } from "react-i18next";
import { useStore } from "../../Stores/store";
import LanguageSwitcher from "../Layout/languageSwitcher";

export default function FillSurvey() {
  let params = useParams();
  const { t } = useTranslation("common");
  const { commonStore, translationStore } = useStore();

  const [validLink, setValidLink] = useState<boolean>(false);
  const [checkingSurvey, setCheckingSurvey] = useState<boolean>(true);
  const [loaderMessage, setLoaderMessage] = useState<string>("");
  const [survey, setSurvey] = useState<SurveyDto>();
  const [surveyFinished, setSurveyFinished] = useState<boolean>();

  useEffect(() => {
    if (!params.invitationCode) {
      toast.error(t("ErrorMessages.InvalidSurveyLink"));
      return;
    }

    translationStore.setCurrentInvitationCode(params.invitationCode);
    setCheckingSurvey(true);
    setLoaderMessage(t("Common.VeriyingSurveyLink"));
    agent.Invitations.check(params.invitationCode)
      .then((result) => {
        setValidLink(true);
        commonStore.setCurrentInvitationCode(params.invitationCode);
        loadSurvey();
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 422) {
          setSurveyFinished(true);
          setCheckingSurvey(false);
          setLoaderMessage("");
        } else {
          toast.error(t("ErrorMessages.InvalidSurveyLink"));
          setValidLink(false);
          setCheckingSurvey(false);
        }
      });
  }, [params.invitationCode]);

  const loadSurvey = () => {
    setLoaderMessage(t("Common.LoadingSurvey"));
    if (!params.invitationCode) return;
    agent.Invitations.startSurvey(params.invitationCode)
      .then((result) => {
        setSurvey(result);
      })
      .catch((error) => {
        toast.error(t("ErrorMessages.OfflineErrorMessage"));
      })
      .finally(() => {
        setCheckingSurvey(false);
        setLoaderMessage("");
      });
  };

  const loader = (
    <Segment>
      <Dimmer active inverted>
        <Loader size="large">{loaderMessage}</Loader>
      </Dimmer>
    </Segment>
  );
  return (
    <>
      <div className="App t4-content-wrapper">
        <Container
          className={translationStore.lang === "ar" ? "t4-survey-wrapper ar-lang" : "t4-survey-wrapper"}
        >
          <Menu className="t4-topnav-menu">
            <Menu.Item>
              <div className="banner1">
                <Image src="/assets/T4_logo.svg" size="small" floated="left" />
              </div>
            </Menu.Item>
            <Menu.Menu position="right" className="t4-topnav-item-ar">
              <Menu.Item>
                <div className="top-nav-lang-ddl">
                  <LanguageSwitcher />
                </div>
              </Menu.Item>
            </Menu.Menu>
          </Menu>

          <Image className="survey-banner" src="/assets/t4_banner.svg" />
          <Segment>
            {checkingSurvey && loader}
            {!checkingSurvey && validLink && survey && <SurveyPlayer survey={survey} finished={false} />}
            {!checkingSurvey && surveyFinished && (
              <SurveyPlayer survey={{} as SurveyDto} finished={true} />
            )}
          </Segment>
        </Container>
      </div>
    </>
  );
}
