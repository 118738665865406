import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import QuestionDto from "../../../models/surveys/questionDto";
import { Segment, Container } from "semantic-ui-react";
import { QuestionTypes } from "../../../models/enums";
import ViewMcqRadioList from "../McqRadioList/viewMcqRadioList";
import ViewMcqDropDownList from "../McqDropDownList/viewMcqDropDownList";
import ViewLikert from "../Likert/viewLikert";
import ViewText from "../Text/viewText";
import ViewNumericText from "../NumericText/viewNumericText";
import ViewMcqMultipleResponse from "../McqMultipleResponse/viewMcqMultipleResponse";
import ViewSalary from "../Salary/viewSalary";
import LoadingComponent from "../../Layout/loadingComponent";
import LanguageDto from "../../../models/surveys/languageDto";
import QuestionTranslationSelector from "./questionTranslationSelector";

export default function QuestionDetails() {
  let params = useParams();
  const { t } = useTranslation("common");
  const [question, setQuestion] = useState<QuestionDto | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [langauges, setLanguages] = useState<LanguageDto[]>([]);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loadQuestion = () => {
    setLoading(true);
    if (!params.questionId) return;
    agent.Questions.details(+params.questionId)
      .then((result) => {
        setQuestion(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadQuestion();

    agent.Translations.languages().then((result) => {
      setLanguages(result);
    });
  }, [params.questionId]);

  const content = (
    <>
      <Container className="t4-survey-wrapper">
        <Segment>
          <div>
            <h1>Question Details</h1>
            <QuestionTranslationSelector
              languages={langauges}
              question={question}
              onTranslationUpdated={() => {
                loadQuestion();
              }}
            />
          </div>
          {question?.questionType === QuestionTypes.McqDropDown && (
            <ViewMcqDropDownList question={question} onAnswer={() => {}} />
          )}
          {question?.questionType === QuestionTypes.McqRadioList && (
            <ViewMcqRadioList question={question} onAnswer={() => {}} />
          )}
          {question?.questionType === QuestionTypes.Likert && (
            <ViewLikert question={question} onAnswer={() => {}} />
          )}
          {question?.questionType === QuestionTypes.Text && (
            <ViewText question={question} onAnswer={() => {}} />
          )}
          {question?.questionType === QuestionTypes.NumericText && (
            <ViewNumericText question={question} onAnswer={() => {}} />
          )}
          {question?.questionType === QuestionTypes.McqMultipleResponse && (
            <ViewMcqMultipleResponse question={question} onAnswer={() => {}} />
          )}
          {question?.questionType === QuestionTypes.Salary && (
            <ViewSalary question={question} onAnswer={() => {}} />
          )}
        </Segment>
      </Container>
    </>
  );

  return (
    <>
      {loading && <LoadingComponent content={""} />}
      {!loading && content}
    </>
  );
}
