import axios, { AxiosResponse } from "axios";
import { store } from "../Stores/store";
import LoginDto from "../models/users/loginDto";
import UserDto from "../models/users/userDto";
import SurveyDto from "../models/surveys/surveyDto";
import ReportEmbedConfigDto from "../models/reports/reportEmbedConfigDto";
import QuestionDto from "../models/surveys/questionDto";
import QuestionLanguageTranslationDto from "../models/surveys/questionLanguageTranslationDto";
import SurveyTranslationsDto from "../models/translations/surveyTranslationsDto";
import SchoolDto from "../models/schools/schoolDto";
import StaffMemberDto from "../models/schools/staffMemberDto";
import InvitationDto from "../models/surveys/invitationDto";
import SurveyPageDto from "../models/surveys/surveyPageDto";
import PillarScoringDto from "../models/scoring/pillarScoringDto";
import SchoolSurveyStatsDto from "../models/schools/schoolSurveyStatsDto";
import SchoolSurveyDto from "../models/schools/schoolSurveyDto";
import LookupDto from "../models/common/lookupDto";
import StaffMemberValidationDto from "../models/schools/staffMemberValidationDto";
import LanguageDto from "../models/surveys/languageDto";
import SchoolChainDto from "../models/schools/schoolChainDto";
import { PaginatedResult, PagingParams } from "../models/common/pagination";
import Licensedto from "../models/schools/licensedto";
import { ContactDto } from "../models/schools/contactDto";
import RenewLicenseDetailsDto from "../models/schools/renewLicenseDetailsDto";
import { AddSchoolChainDto } from "../models/schools/addSchoolChainDto";
import { ChainInfoDto } from "../models/schools/chainInfoDto";
import NewSchoolDto from "../models/schools/newSchoolDto";
import ProjectsFilterDto from "../models/schools/projectsFilterDto";
import SchoolProjectStatusDto from "../models/schools/schoolProjectStatusDto";
import ChainSchoolDto from "../models/schools/chainSchoolDto";
import { ReportStatuses, SurveyStatuses } from "../models/enums";
import { SchoolLicenseSummaryDto } from "../models/schools/schoolLicenseSummaryDto";
import ImportProgressDto from "../models/schools/importProgressDto";
import SchoolChainStatsDto from "../models/chains/schoolChainStatsDto";
import SchoolAccessInfoDto from "../models/chains/schoolAccessInfoDto";
import AddChainSchoolDto from "../models/chains/addChainSchoolDto";
import ItemValidationDto from "../models/common/itemValidationDto";
import ChainsPagingParamsDto from "../models/chains/chainsPagingParamsDto";
import FoundationScoringDto from "../models/scoring/foundationScoringDto";
import QuestionScoringDto from "../models/scoring/questionScoringDto";
import ChainReportPublishInfoDto from "../models/chains/chainReportPublishInfoDto";
import { toast } from "react-toastify";
import SchoolChainSurveyDto from "../models/chains/schoolChainSurveyDto";
import NotificationDto from "../models/Settings/notificationDto";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.Token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(response.data, pagination);
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error) => {
    if (error == null || error.response == null) return Promise.reject(error);

    //const navigate = useNavigate();
    const { status } = error.response;
    switch (status) {
      case 401:
        toast.error("Unauthorized");
        window.location.pathname = "/";
        break;
      /*case 400:
                if (error.response && error.response.data) toast.error(error.response.data);
                else toast.error("Bad Request");
                break;
            case 404:
                navigate("/not-found");
                break;
            case 500:
                toast.error("Server Error");
                break;
            default:
                break;*/
    }
    return Promise.reject(error);
  }
);

const requests = {
  get: <T>(url: string, signal?: { signal: AbortSignal }) => axios.get<T>(url, signal).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Accounts = {
  current: () => requests.get<UserDto>("/api/account"),
  login: (user: LoginDto) => requests.post<UserDto>("/api/account/login", user),
};

const Invitations = {
  check: (code: string) => requests.get(`/invitations/${code}/check`),
  languages: (code: string) => requests.get<LanguageDto[]>(`/invitations/${code}/languages`),
  defaultLanguage: (code: string) => requests.get<LanguageDto>(`/invitations/${code}/defaultLanguage`),
  startSurvey: (code: string) => requests.get<SurveyDto>(`/invitations/${code}/startsurvey`),
  createAnonymousSurveyInvitations: (schoolCode: string, surveyId: number, count: number) =>
    requests.post(`/invitations/${schoolCode}/AnonymousInvitations`, { count: count, surveyId: surveyId }),
  getAnonymousSurveyInvitations: (schoolCode: string, schoolSurveyId: number) =>
    requests.get<InvitationDto[]>(`/invitations/${schoolCode}/AnonymousInvitations/${schoolSurveyId}`),
  getDirectSurveyInvitations: (schoolCode: string, schoolSurveyId: number, pagingParams: PagingParams) =>
    requests.post<InvitationDto[]>(`/invitations/${schoolCode}/Invitations/${schoolSurveyId}`, pagingParams),
  saveUserAnswers: (code: string, page: SurveyPageDto) => requests.post(`/usersurveys/${code}/save`, page),
  finish: (code: string, page: SurveyPageDto) => requests.post(`/usersurveys/${code}/finish`, page),
};

const Questions = {
  list: () => requests.get<QuestionDto[]>(`/Questions/`),
  load: (questionCode: string) => requests.get<QuestionDto>(`/Questions/${questionCode}/load`),
  add: (question: QuestionDto) => requests.post(`/Questions/`, question),
  addSchoolQuestion: (question: QuestionDto, schoolCode: string) => requests.post<string>(`/Questions/school/${schoolCode}`, question),
  deleteSchoolQuestion: (schoolCode: string, questionCode: string) => requests.delete(`/Questions/school/${schoolCode}/question/${questionCode}`),
  updateSchoolQuestion: (schoolCode: string, question: QuestionDto) => requests.put(`/Questions/school/${schoolCode}/`, question),
  details: (questionId: number) => requests.get<QuestionDto>(`/Questions/${questionId}/details`),
  scoring: () => requests.get<PillarScoringDto[]>(`/Questions/scoring`),
  foundationScoring: (foundationId: number) => requests.get<FoundationScoringDto>(`/Questions/foundationscoring/${foundationId}`),
  updatePillarsScoring: (scores: PillarScoringDto[]) => requests.put(`/Questions/pillarsscoring`, scores),
  updateQuestionScores: (scores: QuestionScoringDto[]) => requests.put(`/Questions/questionsscoring`, scores),
};

const Translations = {
  languages: () => requests.get<LanguageDto[]>(`/Translations/languages`),
  surveyTranslations: (code: string, language: string) => requests.get<SurveyTranslationsDto>(`/Translations/${code}/${language}`),
  questionTranslation: (questionId: number, language: string) => requests.get<QuestionLanguageTranslationDto>(`/Questions/${questionId}/translation/${language}`),
  saveQuestionTranslation: (questionId: number, lang: string, translation: QuestionLanguageTranslationDto) =>
    requests.post(`/Questions/${questionId}/addtranslation/${lang}`, translation),
};

const Chains = {
  list: (pagingParams: ChainsPagingParamsDto) => {
    if (controller) controller.abort();
    controller = new AbortController();
    return requests.post<PaginatedResult<SchoolChainDto[]>>(`chains/list`, pagingParams);
  },
  editDetails: (chainCode: string) => requests.get<AddSchoolChainDto>(`chains/${chainCode}/editdetails`),
  licenses: (chainCode: string) => requests.get<Licensedto[]>(`chains/${chainCode}/licenses`),
  contacts: (chainCode: string) => requests.get<ContactDto[]>(`chains/${chainCode}/contacts`),
  renew: (chainCode: string, details: RenewLicenseDetailsDto) => requests.post(`chains/${chainCode}/renew`, details),
  add: (chain: AddSchoolChainDto) => requests.post(`chains`, chain),
  update: (chain: AddSchoolChainDto) => requests.put(`chains`, chain),
  delete: (chainCode: string) => requests.delete(`chains/${chainCode}/delete`),
  info: (chainCode: string) => requests.get<ChainInfoDto>(`chains/${chainCode}/info`),
  coordinators: (chainCode: string) => requests.get<StaffMemberDto[]>(`chains/${chainCode}/coordinators`),
  addCoordinator: (user: StaffMemberDto, chainCode: string) => requests.post(`chains/${chainCode}/coordinators`, user),
  updateCoordinator: (user: StaffMemberDto, chainCode: string) => requests.put(`chains/${chainCode}/coordinators`, user),
  deleteCoordinator: (userId: number, chainCode: string) => requests.delete(`chains/${chainCode}/coordinators/${userId}`),
  schools: (chainCode: string) => requests.get<ChainSchoolDto[]>(`chains/${chainCode}/schools`),
  addSchool: (chainCode: string, school: AddChainSchoolDto) => requests.post(`chains/${chainCode}/schools`, school),
  lookups: () => requests.get<LookupDto[]>(`chains/lookups`),
  schoolsStats: (chainCode: string) => requests.get<SchoolChainStatsDto[]>(`chains/${chainCode}/schoolsstats`),
  schoolAccessInfo: (chainCode: string) => requests.get<SchoolAccessInfoDto[]>(`chains/${chainCode}/schoolsaccess`),
  validateSchools: (chainCode: string, schools: AddChainSchoolDto[]) => requests.post<ItemValidationDto[]>(`chains/${chainCode}/validateschools`, schools),
  newImport: (chainCode: string, schoolsCount: number) => requests.post<string>(`chains/${chainCode}/newimport`, { value: schoolsCount }),
  importSchools: (chainCode: string, importlogCode: string, schools: AddChainSchoolDto[]) => requests.post(`chains/${chainCode}/importmembers/${importlogCode}`, schools),
  reportPublishInfo: (chainCode: string) => requests.get<ChainReportPublishInfoDto>(`chains/${chainCode}/reportPublishInfo`),

  closeAllSchoolSurveys: (chainCode: string) => requests.put(`chains/${chainCode}/closeSurveys`, chainCode),
  unPublishedSchoolReportsCount: (chainCode: string) => requests.get<number>(`chains/unpublishedSchoolReportsCount/${chainCode}`),
  updateChainSchoolsReportPublishStatus: (chainCode: string, status: SurveyStatuses) => requests.put(`chains/${chainCode}/updateschoolsreportstatus`, { status: status }),

  updateChainReportPublishStatus: (chainCode: string, surveyCode: string, status: ReportStatuses) =>
    requests.put(`chains/${chainCode}/updatereportstatus/${surveyCode}`, { status: status }),
  surveySchools: (chainCode: string, surveyCode: string) => requests.get<SchoolSurveyDto[]>(`chains/${chainCode}/survey/${surveyCode}/schools`),
  surveyDetails: (chainCode: string, surveyCode: string) => requests.get<SchoolChainSurveyDto>(`chains/${chainCode}/survey/${surveyCode}`),
  surveys: (chainCode: string) => requests.get<SchoolChainSurveyDto[]>(`chains/${chainCode}/surveys`),
  newSurvey: (chainCode: string, surveyName: string) => requests.post(`chains/${chainCode}/surveys`, { Value: surveyName }),
  updateSurveyName: (chainCode: string, schoolSurveyId: number, surveyName: string) =>
    requests.put(`chains/${chainCode}/updatesurveyname/${schoolSurveyId}`, { Value: surveyName }),
  updateSchoolsSurveyStatus: (chainCode: string, surveyCode: string, status: SurveyStatuses) =>
    requests.put(`chains/${chainCode}/updatestatus/${surveyCode}`, { status: status }),
};

let controller = new AbortController();

const FileDownload = require("js-file-download");

const Schools = {
  list: (pagingParams: PagingParams) => {
    if (controller) controller.abort();
    controller = new AbortController();
    return requests.post<SchoolLicenseSummaryDto[]>(`schools/list`, pagingParams);
  },
  add: (school: NewSchoolDto) => requests.post(`schools`, school),
  delete: (schoolCode: string) => requests.delete(`schools/${schoolCode}`),
  projects: (filter: ProjectsFilterDto) => requests.post<SchoolProjectStatusDto[]>(`schools/projects`, filter),
  title: (schoolCode: string) => requests.get<string>(`schools/${schoolCode}/title`),
  members: (schoolCode: string) => requests.get<StaffMemberDto[]>(`schools/${schoolCode}/members`),
  coordinators: (schoolCode: string) => requests.get<StaffMemberDto[]>(`schools/${schoolCode}/coordinators`),
  addCoordinator: (user: StaffMemberDto, schoolCode: string) => requests.post(`schools/${schoolCode}/coordinators`, user),
  updateCoordinator: (user: StaffMemberDto, schoolCode: string) => requests.put(`schools/${schoolCode}/coordinators`, user),
  resetCoordinatorPwd: (user: StaffMemberDto, schoolCode: string) => requests.put(`schools/${schoolCode}/coordinators/resetpassword`, user),
  deleteCoordinator: (userId: number, schoolCode: string) => requests.delete(`schools/${schoolCode}/coordinators/${userId}`),
  profile: (schoolCode: string) => requests.get<SchoolDto>(`schools/${schoolCode}/profile`),
  inviteAllMembers: (schoolCode: string, language: string) => requests.post(`schools/${schoolCode}/inviteAll`, { language: language, surveyId: 1 }),
  membersCount: (schoolCode: string) => requests.get<number>(`schools/${schoolCode}/memberscount`),
  adminCount: (schoolCode: string) => requests.get<number>(`schools/${schoolCode}/admincount`),
  activeSchoolsurvey: (schoolCode: string) => requests.get<SchoolSurveyDto>(`schools/${schoolCode}/activeschoolSurvey`),
  schoolSurveyStats: (schoolCode: string, schoolSurveyId: number) => requests.get<SchoolSurveyStatsDto>(`schools/${schoolCode}/surveystats/${schoolSurveyId}`),
  surveys: (schoolCode: string) => requests.get<SchoolSurveyDto[]>(`schools/${schoolCode}/surveys`),
  survey: (schoolCode: string, schoolSurveyCode: string) => requests.get<SchoolSurveyDto>(`schools/${schoolCode}/surveys/${schoolSurveyCode}`),
  newSurvey: (schoolCode: string, surveyName: string) => requests.post(`schools/${schoolCode}/surveys`, { Value: surveyName }),
  updateSurveyName: (schoolCode: string, schoolSurveyId: number, surveyName: string) =>
    requests.put(`schools/${schoolCode}/updatesurvey/${schoolSurveyId}`, { Value: surveyName }),
  updateSurveyStatus: (schoolCode: string, schoolSurveyId: number, status: SurveyStatuses) =>
    requests.put<SchoolSurveyDto>(`schools/${schoolCode}/surveys/${schoolSurveyId}`, { status: status }),
  updateProfile: (schoolCode: string, school: SchoolDto) => requests.put(`schools/${schoolCode}/profile`, school),
  addMember: (user: StaffMemberDto, schoolCode: string) => requests.post(`schools/${schoolCode}/members`, user),
  updateMember: (user: StaffMemberDto, schoolCode: string) => requests.put(`schools/${schoolCode}/members`, user),
  updateMemberAndInvite: (user: StaffMemberDto, schoolCode: string) => requests.put(`schools/${schoolCode}/membersInvite`, user),
  deleteMember: (users: StaffMemberDto[], schoolCode: string) => requests.post(`schools/${schoolCode}/deletemembers`, users),
  validateMembers: (schoolCode: string, members: StaffMemberDto[]) => requests.post<StaffMemberValidationDto[]>(`schools/${schoolCode}/validatemembers`, members),
  newImport: (schoolCode: string, memberCount: number) => requests.post<string>(`schools/${schoolCode}/newimport`, { value: memberCount }),
  checkImportStatus: (importCode: string) => requests.get<ImportProgressDto>(`schools/${importCode}/importstatus`),
  importMembers: (schoolCode: string, importlogCode: string, members: StaffMemberDto[]) => requests.post(`schools/${schoolCode}/importmembers/${importlogCode}`, members),
  questions: (schoolCode: string) => requests.get<QuestionDto[]>(`/Questions/school/${schoolCode}`),
  newQuestionOrder: (schoolCode: string) => requests.get<number>(`/schools/${schoolCode}/newquestionorder`),
  invalidEmailInvitationsCount: (schoolCode: string) => requests.get<number>(`/invitations/${schoolCode}/invalidEmails`),
  missingInvitationsCount: (schoolCode: string) => requests.get<number>(`/invitations/${schoolCode}/missingInvitationscount`),
  missingInvitationMembers: (schoolCode: string) => requests.get<StaffMemberDto[]>(`/invitations/${schoolCode}/noinvitationmembers`),
  invalidEmailStaff: (schoolCode: string) => requests.get<StaffMemberDto[]>(`/schools/${schoolCode}/invalidstaffemails`),
  invitationPossible: (schoolCode: string) => requests.get<boolean>(`/invitations/${schoolCode}/invitationpossible`),
  languages: (schoolCode: string) => requests.get<LanguageDto[]>(`/schools/${schoolCode}/languages`),
  updateLanguages: (schoolCode: string, languages: number[]) => requests.post(`/schools/${schoolCode}/languages`, languages),
  defaultLanguage: (schoolCode: string) => requests.get<number>(`/schools/${schoolCode}/defaultLanguage`),
  updateDefaultLanguage: (schoolCode: string, languageId: number) => requests.post(`/schools/${schoolCode}/defaultLanguage`, { value: languageId, text: "" }),
  isProfileCompeted: (schoolCode: string) => requests.get<boolean>(`/schools/${schoolCode}/isprofilecompleted`),
  //endSchoolSurvey: (schoolCode: string) => requests.put(`/schools/${schoolCode}/endcurrentsurvey`, schoolCode),
  //  createSchoolSurvey: (schoolCode: string) => requests.put(`/schools/${schoolCode}/createschoolsurvey`, schoolCode),
  lookups: () => requests.get<LookupDto[]>(`schools/lookups`),
  licenses: (schoolCode: string) => requests.get<Licensedto[]>(`schools/${schoolCode}/licenses`),
  contacts: (schoolCode: string) => requests.get<ContactDto[]>(`schools/${schoolCode}/contacts`),
  sendFinalReminder: (schoolCode: string, schoolSurveyId: number) => requests.get(`/schools/${schoolCode}/${schoolSurveyId}/finalreminder`),
  downloadSurveyResponses: async (schoolCode: string, schoolSurveyId: number) => {
    await axios({
      url: `/schools/${schoolCode}/${schoolSurveyId}/downloadresponses`,
      method: "GET",
      //data: { reportHtml: report },
      responseType: "arraybuffer",
    }).then((response) => {
      FileDownload(response.data, "report.xlsx");
    });
  },
};

const Reports = {
  embed: (schoolCode: string, schoolSurveyId: number) => requests.get<ReportEmbedConfigDto>(`/EmbedReport/school/${schoolCode}/${schoolSurveyId}/params`),
  embedChainReport: (chainCode: string, chainSurveyId: number) => requests.get<ReportEmbedConfigDto>(`/EmbedReport/chain/${chainCode}/${chainSurveyId}/params`),
};

const Lookups = {
  countries: () => requests.get<LookupDto[]>(`Lookups/countries`),
};

const ContentFiles = {
  open: (fileName: string) => requests.get<any>(`Files/${fileName}`),
  download: async (fileName: string) => {
    await axios({
      url: `Files/${fileName}/download`,
      method: "GET",
      //data: { reportHtml: report },
      responseType: "arraybuffer",
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  },
};

const Settings = {
  notifications: () => requests.get<NotificationDto[]>(`Settings/notifications`),
  addNotificationEmail: (email: string) => requests.post(`Settings/addnotification`, { value: email }),
  deleteNotificationEmail: (email: string) => requests.post(`Settings/deletenotification`, { value: email }),
};
const agent = {
  Accounts,
  Invitations,
  Reports,
  Questions,
  Translations,
  Schools,
  Lookups,
  Chains,
  ContentFiles,
  Settings,
};

export default agent;
