import { makeAutoObservable, reaction } from "mobx";

export default class CommonStore {

    private token = window.localStorage.getItem("jwt");
    defaultLang = "en";
    currentInvitationCode: string | null = null;
    appLoaded = false;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            (token) => {
                if (token) window.localStorage.setItem("jwt", token);
                else window.localStorage.removeItem("jwt");
            }
        );
    }

    get Token(): string | null {
        if (window.localStorage.getItem("jwt") == null)
            return null;
        return this.token;
    }

    setToken = (token: string | null) => {
        this.token = token;
    };

    setAppLoaded = () => {
        this.appLoaded = true;
    };

    setCurrentInvitationCode = (code: string | undefined) => {
        if (code)
            this.currentInvitationCode = code;
    }
}
