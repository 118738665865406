import React, { useEffect, useState } from "react";
import { Button, Segment, Select, Image } from "semantic-ui-react";
import InvitationDto from "../../../models/surveys/invitationDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../../Layout/loadingComponent";
import { useNavigate, useParams } from "react-router-dom";
import AnonymousInvitationsList from "./AnonymousInvitationsList";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import SchoolNameControl from "../schoolNameControl";
import { SurveyStatuses } from "../../../models/enums";

export default function ManageAnonymousInvitations() {
  const { t } = useTranslation("common");
  let params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [actioning, setActioning] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<InvitationDto[]>();
  const [surveys, setSurveys] = useState<SchoolSurveyDto[]>();
  const [noSurveySetup, setNoSurveySetup] = useState<boolean>(false);
  const [surveyOptions, setSurveyOptions] = useState<{ text: string; value: number }[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<SchoolSurveyDto>();

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loadInvites = (schoolSurveyId: number) => {
    setLoading(true);
    agent.Invitations.getAnonymousSurveyInvitations(params.schoolCode!, schoolSurveyId)
      .then((result) => {
        setInvitations(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectSurvey = (schoolSurveyId: number) => {
    if (!surveys) return;
    loadInvites(schoolSurveyId);
    for (var i = 0; i < surveys.length; i++) {
      if (surveys[i].id === schoolSurveyId) {
        setSelectedSurvey(surveys[i]);
        break;
      }
    }
  };

  useEffect(() => {
    if (!params.schoolCode) return;

    setNoSurveySetup(false);
    setLoading(true);
    agent.Schools.surveys(params.schoolCode)
      .then((result) => {
        setSurveys(result);

        if (
          !result ||
          result == null ||
          result.length === 0 ||
          result.filter(
            (s) => s.status === SurveyStatuses.SurveyRunning || s.status === SurveyStatuses.setup
          ).length === 0
        ) {
          //no surveys setup for the school
          setNoSurveySetup(true);
          setLoading(false);
          return;
        } else {
          let opts: { text: string; value: number }[] = [];
          result.forEach((s) => opts.push({ text: s.name, value: s.id }));
          setSurveyOptions(opts);

          for (var i = 0; i < result.length; i++) {
            if (
              result[i].status === SurveyStatuses.setup ||
              result[i].status === SurveyStatuses.SurveyRunning
            ) {
              setSelectedSurvey(result[i]);
              loadInvites(result[i].id);
              break;
            }
          }
        }
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });
  }, [params.schoolCode]);

  const createInvitations = (count: number, surveyId: number) => {
    setActioning(true);
    agent.Invitations.createAnonymousSurveyInvitations(params.schoolCode!, surveyId, count)
      .then(() => {
        if (selectedSurvey) loadInvites(selectedSurvey?.id);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setActioning(false);
      });
  };

  const noSurveyMessage = (
    <>
      <Segment>
        <Image src="/assets/nosurveys.svg" size="small" centered />
        <h3 className="t4-error-msg">There are no current active surveys!</h3>
      </Segment>
    </>
  );

  const list = (
    <>
      <div className="t4-action-btns">
        <Button
          className="t4-btn t4-create"
          basic
          disabled={selectedSurvey && selectedSurvey.status === SurveyStatuses.BiLive}
          loading={actioning}
          onClick={() => {
            createInvitations(10, 1);
          }}
        >
          Create 10 Anonymous Invitations
        </Button>
      </div>
      <div className="t4-action-ddl">
        <Select
          value={selectedSurvey?.id}
          options={surveyOptions}
          onChange={(e, d) => {
            selectSurvey(d.value as number);
          }}
        ></Select>
      </div>
      <AnonymousInvitationsList schoolCode={params.schoolCode!} invitations={invitations} />
    </>
  );
  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      <Segment className="t4-mainmenu">
        <div className="t4-headers t4-header-collection t4-with-ddl">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
            }}
          ></Button>
          <h1>Invitations</h1>
        </div>
        {loading && <LoadingComponent content={"Loading invitaitons"} />}
        {noSurveySetup && noSurveyMessage}
        {!loading && !noSurveySetup && list}
      </Segment>
    </>
  );
}
