import React, { useEffect, useState } from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { Checkbox, Segment } from "semantic-ui-react";
import parse from "html-react-parser";
import { useStore } from "../../../Stores/store";
import QuestionChoiceDto from "../../../models/surveys/questionChoiceDto";

interface Props {
  question: QuestionDto;
  onAnswer: (value: number[]) => void;
}

export default function ViewMcqMultipleResponse({ question, onAnswer }: Props) {
  const { translationStore } = useStore();
  const [answers, setAnswers] = useState<number[]>([]);
  const [choices, setChoices] = useState<QuestionChoiceDto[] | null>([]);

  useEffect(() => {
    if (question.userAnswers == null) setAnswers([]);
    else setAnswers(question.userAnswers);
  }, [question]);

  useEffect(() => {
    if (question.choices && question.randomizeChoices === true)
      setChoices(question.choices.sort(() => Math.random() - 0.5));
    else setChoices(question.choices);
  }, []);

  const setQuestionAnswer = (choice: number, choiceText: string) => {
    let updateAnswers: number[] = [...answers];
    if (updateAnswers.indexOf(choice) === -1) updateAnswers.push(choice);
    else {
      updateAnswers = answers.filter((c) => {
        return c != choice;
      });

      if (!updateAnswers) setAnswers([]);
      else setAnswers(updateAnswers);
    }
    onAnswer(updateAnswers);
  };

  return (
    <>
      <Segment className={question.isAnswered === false ? "t4-mcq required" : "t4-mcq"}>
        <div>{parse(translationStore.questionBody(question))}</div>
        <div className="t4-mcq-checkbox">
          {choices &&
            choices.length > 0 &&
            choices.map((choice) => (
              <Checkbox
                key={choice.id}
                label={parse(translationStore.choiceBody(choice))}
                checked={answers != null && answers.indexOf(choice.id) > -1}
                onChange={() => {
                  setQuestionAnswer(choice.id, choice.body);
                }}
              />
            ))}
        </div>
        {/*{question.isAnswered === false && <Message color={"red"}>Required</Message>}*/}
      </Segment>
    </>
  );
}
