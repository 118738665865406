import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import ChainSchoolsList from "./chainSchoolsList";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";
import ChainSchoolDto from "../../../models/schools/chainSchoolDto";
import ConfirmDeleteSchool from "../../Licensing/schools/confirmDeleteSchool";
import { ChainInfoDto } from "../../../models/schools/chainInfoDto";

interface Props {
  chainCode: string;
}

export default function ManageSchools({ chainCode }: Props) {
  const navigate = useNavigate();
  const [chain, setChain] = useState<ChainInfoDto | null>(null);
  const [schools, setSchools] = useState<ChainSchoolDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [selectedSchool, setSelectedSchool] = useState<ChainSchoolDto | null>(null);

  useEffect(() => {
    agent.Chains.info(chainCode).then((result) => {
      setChain(result);
    });

    loadSchools();
  }, [chainCode]);

  const loadSchools = () => {
    setLoading(true);
    agent.Chains.schools(chainCode)
      .then((result) => setSchools(result))
      .catch(() => {
        toast.error("Failed to load schools");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addNewChain = () => {
    if (chain != null && schools != null && chain.licensedSchools <= schools.length)
      toast.error(
        "You have reached your allocation of school licenses. If you need to add more schools please email hello@bestschooltowork.com."
      );
    else navigate(`/dashboard/chain/${chainCode}/newschool`);
  };

  const importSchools = () => {
    if (chain != null && schools != null && chain.licensedSchools <= schools.length)
      toast.error(
        "You have reached your allocation of school licenses. If you need to add more schools please email hello@bestschooltowork.com."
      );
    else navigate(`/dashboard/chain/${chainCode}/importschools`);
  };

  const content = (
    <>
      <Segment className="t4-mainmenu t4-header-align">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/chain/${chainCode}`);
              }}
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              Manage Schools
            </Header>
          </div>

          <Header as="h4" className="t4-header-right" textAlign="right">
            Total Schools: {schools.length}
          </Header>
        </div>
        <div className="t4-mng-btns">
          <Button
            icon="add"
            disabled={chain == null || schools == null}
            content={"Add New"}
            onClick={() => {
              addNewChain();
            }}
          ></Button>
          <Button
            icon="edit"
            content={"Edit"}
            disabled={selectedSchool == null}
            onClick={() => {
              navigate(`/dashboard/editschoolprofile/${selectedSchool?.code}`);
            }}
          ></Button>
          <Button
            icon="delete"
            content={"Delete"}
            disabled={selectedSchool == null}
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
          ></Button>
          <Button
            icon={<i className="t4-import-icn icon" />}
            content={"Import"}
            onClick={() => {
              importSchools();
            }}
          ></Button>
        </div>
        <ChainSchoolsList
          schools={schools}
          onSchoolSelected={(school) => {
            if (selectedSchool != null && selectedSchool.schoolId === school.schoolId)
              setSelectedSchool(null);
            else setSelectedSchool(school);
          }}
          selectedSchool={selectedSchool}
        />
        {showDeleteConfirm && (
          <ConfirmDeleteSchool
            school={selectedSchool}
            onClose={() => {
              setShowDeleteConfirm(false);
              setSelectedSchool(null);
              loadSchools();
            }}
          />
        )}
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={"Loading Schools..."} />}
    </>
  );
}
