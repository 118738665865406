import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Stores/store";
import { toast } from "react-toastify";
import { Button, Card, Header, Segment } from "semantic-ui-react";
import LoadingComponent from "../../Layout/loadingComponent";
import { useEffect, useState } from "react";
import SchoolChainSurveyDto from "../../../models/chains/schoolChainSurveyDto";
import ChainSurveyReportSummary from "./chainSurveyReportSummary";
import agent from "../../../api/agent";
import { ReportStatuses } from "../../../models/enums";

interface Props {
  chainCode: string;
}

export default function ChainReportsList({ chainCode }: Props) {
  const navigate = useNavigate();
  const { lookupStore, userStore } = useStore();
  let params = useParams();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<SchoolChainSurveyDto[]>([]);

  const handleError = () => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    if (!params.chainCode) return;

    setLoading(true);

    agent.Chains.surveys(params.chainCode)
      .then((result) => {
        setSurveys(result);
      })
      .catch((error) => {
        toast.error("Failed to load surveys");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.schoolCode]);

  const content = (
    <Segment className="t4-mainmenu t4-header-align">
      <div className="t4-header-collection">
        <div className="t4-headers">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/chain/${chainCode}`);
            }}
          ></Button>
          <Header as="h1" className="t4-header-left" textAlign="left">
            Reports
          </Header>
        </div>
      </div>
      <div className="t4-spacing">
        <Card.Group>
          {surveys &&
            surveys.length > 0 &&
            surveys.map((survey) => (
              <>
                {userStore.isChainAdmin && survey.reportStatus == ReportStatuses.Published && (
                  <ChainSurveyReportSummary key={survey.id} survey={survey} chainCode={params.chainCode ? params.chainCode : ""} />
                )}
                {userStore.isSystemAdmin && <ChainSurveyReportSummary key={survey.id} survey={survey} chainCode={params.chainCode ? params.chainCode : ""} />}
              </>
            ))}
        </Card.Group>
      </div>
    </Segment>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={"Loading Surveys"} />}
    </>
  );
}
