import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "semantic-ui-css/semantic.min.css";
import common_pt from "./translations/pt/common.json";
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";
import common_se from "./translations/se/common.json";
import common_es from "./translations/es/common.json";
import common_th from "./translations/th/common.json";
import { store, StoreContext } from "./Stores/store";
import { BrowserRouter } from "react-router-dom";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: { common: common_en },
    pt: { common: common_pt },
    ar: { common: common_ar },
    es: { common: common_es },
    se: { common: common_se },
    th: { common: common_th },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StoreContext.Provider value={store}>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </StoreContext.Provider>
);
