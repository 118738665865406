import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Input, Segment, Table } from "semantic-ui-react";
import FoundationScoringDto from "../../../models/scoring/foundationScoringDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { DefaultScoringMatrix } from "../../../models/constants";
import cloneDeep from "lodash.clonedeep";

export default function EditQuestionsWeightings() {
  const navigate = useNavigate();
  const { foundationId } = useParams();
  const [foundation, setFoundation] = useState<FoundationScoringDto>();
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!foundationId) return;

    setLoading(true);
    agent.Questions.foundationScoring(+foundationId)
      .then((result) => setFoundation(result))
      .catch(() => toast.error("Failed to foundation details"))
      .finally(() => setLoading(false));
  }, []);

  const updateQuestionPrivateScoring = (questionId: number, weight: number | null) => {
    if (!foundation || !foundation.questions) return;

    let questions = [...foundation.questions];
    questions.forEach((q) => {
      if (q.questionId === questionId) q.privateSchoolWeight = weight;
    });
    setFoundation({ ...foundation, questions: questions });
  };

  const updateQuestionStateScoring = (questionId: number, weight: number | null) => {
    if (!foundation || !foundation.questions) return;

    let questions = [...foundation.questions];
    questions.forEach((q) => {
      if (q.questionId === questionId) q.stateSchoolWeight = weight;
    });
    setFoundation({ ...foundation, questions: questions });
  };

  const saveScores = () => {
    if (!foundation || !foundation.questions) return;
    let totalPrivateScore = foundation.questions
      .map((q) => q.privateSchoolWeight)
      .reduce((a, b) => {
        let n1 = a == null ? 0 : a;
        let n2 = b == null ? 0 : b;
        return n1 + n2;
      });

    if (totalPrivateScore !== 100) {
      toast.error("Total points for private schools must be equal to 100");
      return;
    }

    let totalStateScore = foundation.questions
      .map((q) => q.stateSchoolWeight)
      .reduce((a, b) => {
        let n1 = a == null ? 0 : a;
        let n2 = b == null ? 0 : b;
        return n1 + n2;
      });

    if (totalStateScore !== 100) {
      toast.error("Total points for state schools must be equal to 100");
      return;
    }

    setSaving(true);
    agent.Questions.updateQuestionScores(foundation.questions)
      .then(() => toast.success("scores updated successfully"))
      .catch(() => toast.error("Failed to update scores"))
      .finally(() => setSaving(false));
  };

  const resetScores = () => {
    if (!DefaultScoringMatrix || !foundationId) return;

    DefaultScoringMatrix.forEach((p) => {
      if (p.foundations == null || p.foundations.length === 0) return;
      p.foundations.forEach((f) => {
        if (f.foundationId === +foundationId) {
          setFoundation(cloneDeep(f));
        }
      });
    });
  };

  const content = (
    <>
    
      <Grid className="t4-banner-info stackable">
        <Grid.Row>
          <Grid.Column width={4} className="t4-hide"></Grid.Column>
          <Grid.Column width={8}></Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <span>State School Weight: </span>
              <span className="t4-banner-numbers">
                {foundation &&
                  foundation.questions &&
                  foundation.questions
                    .map((q) => q.stateSchoolWeight)
                    .reduce((a, b) => {
                      let n1 = a == null ? 0 : a;
                      let n2 = b == null ? 0 : b;
                      return n1 + n2;
                    })}
              </span>
            </Segment>
            <Segment>
              <span>Private School Weight: </span>
              <span className="t4-banner-numbers">
                {foundation &&
                  foundation.questions &&
                  foundation.questions
                    .map((q) => q.privateSchoolWeight)
                    .reduce((a, b) => {
                      let n1 = a == null ? 0 : a;
                      let n2 = b == null ? 0 : b;
                      return n1 + n2;
                    })}
              </span>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment className="t4-mainmenu t4-edit-score">
      <ul className="t4-scoring-wrapper">

        <ul className="t4-pillars">
          <li className="t4-foundation">
            <h3>
              {foundation && foundation.foundationName} - {foundation && foundation.weight}
            </h3>
            <Table className="t4-tbl">
              <Table.Header>
                <Table.HeaderCell>Question</Table.HeaderCell>
                <Table.HeaderCell>State School Weight</Table.HeaderCell>
                <Table.HeaderCell>Private School Weight</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {foundation &&
                  foundation.questions &&
                  foundation.questions.map((question) => (
                    <Table.Row key={question.questionId}>
                      <Table.Cell>{question.questionBody}</Table.Cell>
                      <Table.Cell>
                        <Input
                          value={question.stateSchoolWeight}
                          onChange={(e) => {
                            if (e.target.value === "")
                              updateQuestionStateScoring(question.questionId, null);
                            else if (!isNaN(+e.target.value)) {
                              updateQuestionStateScoring(question.questionId, +e.target.value);
                            }
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          value={question.privateSchoolWeight}
                          onChange={(e) => {
                            if (e.target.value === "")
                              updateQuestionPrivateScoring(question.questionId, null);
                            else if (!isNaN(+e.target.value)) {
                              updateQuestionPrivateScoring(question.questionId, +e.target.value);
                            }
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </li>
        </ul>
      </ul>
      <div className="t4-form-bot-btns">
          
        <Button
          floated="right"
          type="submit"
          color="blue"
          content="Save"
          loading={saving}
          className="t4-btn-active"
          onClick={() => {
            saveScores();
          }}
        />
        <Button
          className="t4-btn-non"
          floated="right"
          disabled={saving}
          onClick={() => {
            navigate(`/dashboard/scoringmatrix`);
          }}
        >
          Cancel
        </Button>
        <Button
            icon="refresh"
            content={"Reset"}
            className="t4-btn-non"
          floated="right"
            onClick={() => {
              resetScores();
            }}
          ></Button>
      </div>
      </Segment>
    </>
  );

  return <>{content}</>;
}
