import "./App.css";
import React from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import FillSurvey from "./Components/TakeSurvey/fillSurvey";
import Home from "./Components/Home/home";
import LoginForm from "./Components/UserLogin/loginForm";
import DashboardHost from "./Components/Dashboard/dashboardHost";
import SchoolReports from "./Components/SchoolManagement/Reports/schoolReports";
import FileViewer from "./Common/fileViewer";
import ChainSurveyReport from "./Components/SchoolChainManagement/Reports/chainSurveyReport";

export default function App() {
  return (
    <>
      <ToastContainer position="bottom-right" hideProgressBar />
      {/*<div className="App t4-content-wrapper">*/}
      <Routes>
        <Route path="/takesurvey/:invitationCode" element={<FillSurvey />} />
        <Route path="/school/:schoolCode/report/:surveyCode" element={<SchoolReports />} />
        <Route path="/chain/report/:chainCode/survey/:surveyCode" element={<ChainSurveyReport />} />
        <Route path="/dashboard/*" element={<DashboardHost />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/documents/:fileName" element={<FileViewer />} />
        <Route path="*" element={<Home />} />
      </Routes>
      {/*</div>*/}
    </>
  );
}
