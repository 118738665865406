import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Header, Modal, Segment } from "semantic-ui-react";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import AdminList from "../../SchoolManagement/ProjectCoordinators/adminList";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";
import AddEditProjectCoordinator from "../../SchoolManagement/ProjectCoordinators/addEditProjectCoordinator";
import ResetPasswordControl from "../../SchoolManagement/ProjectCoordinators/resetPasswordControl";
import { useStore } from "../../../Stores/store";
interface Props {
  chainCode: string;
}
export default function ChainAdmins({ chainCode }: Props) {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<StaffMemberDto[] | null>(null);
  const [currentUser, setCurrentUser] = useState<StaffMemberDto | null>(null);
  const [openAddEditPopup, setOpenAddEditPopup] = useState<boolean>(false);
  const [actioning, setActioning] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

  const loadAdmins = () => {
    setLoading(true);
    agent.Chains.coordinators(chainCode)
      .then((result) => {
        setMembers(result);
      })
      .catch((error) => {
        toast.error("Failed to load members");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadAdmins();
  }, [chainCode]);

  const addNewProjectCoordinator = (member: StaffMemberDto) => {
    if (!chainCode) return;
    setActioning(true);
    agent.Chains.addCoordinator(member, chainCode)
      .then((result) => {
        toast.success("Project Coordinator added successfully");
        setOpenAddEditPopup(false);
        loadAdmins();
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error("User email already in use!");
        else toast.error("Failed to add user");
      })
      .finally(() => {
        setActioning(false);
      });
  };

  const updateProjectCoordinator = (member: StaffMemberDto) => {
    if (!chainCode) return;
    setActioning(true);
    agent.Chains.updateCoordinator(member, chainCode)
      .then((result) => {
        toast.success("Project Coordinator updated successfully");
        setOpenAddEditPopup(false);
        loadAdmins();
      })
      .catch((error) => {
        toast.error("Failed to add user");
      })
      .finally(() => setActioning(false));
  };

  const deleteUser = () => {
    if (currentUser == null || !chainCode) return;
    setActioning(true);
    agent.Chains.deleteCoordinator(currentUser.id, chainCode)
      .then(() => {
        toast.success("Project Coordinator deleted successfully");
        setShowConfirmDelete(false);
        loadAdmins();
      })
      .catch((error) => {
        toast.error("Failed to delete coordinator");
      })
      .finally(() => {
        setActioning(false);
      });
  };

  const addAdminPopup = (
    <>
      <Modal className="t4-popup" open={openAddEditPopup}>
        <Modal.Header>Project Co-ordinator</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AddEditProjectCoordinator
              organisationCode={chainCode}
              member={currentUser}
              saving={actioning}
              onClose={(refresh: boolean) => {
                setOpenAddEditPopup(false);
                if (refresh) loadAdmins();
              }}
              onSubmitNew={(member) => {
                addNewProjectCoordinator(member);
              }}
              onUpdate={(member) => {
                updateProjectCoordinator(member);
              }}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const confirmDeletePopup = (
    <>
      <Modal className="t4-popup" open={showConfirmDelete}>
        <Modal.Header>Please Confirm</Modal.Header>
        <Modal.Content>
          <Modal.Description>Are you sure you want to delete the selected user?</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={actioning}
            onClick={() => {
              setShowConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="blue"
            content="Confirm"
            loading={actioning}
            className="t4-btn-active"
            onClick={() => {
              deleteUser();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );

  const resetPasswordPopup = (
    <>
      <Modal className="t4-popup t4-reset-popup" open={showChangePassword}>
        <Modal.Header>Select new password</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ResetPasswordControl
              onClose={() => setShowChangePassword(false)}
              member={currentUser}
              schoolCode={chainCode}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const content = (
    <>
      {resetPasswordPopup}
      {addAdminPopup}
      {confirmDeletePopup}
      <Segment className="t4-mainmenu t4-header-align">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/chain/${chainCode}`);
              }}
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              Project Co-ordinators
            </Header>
          </div>
          <Header as="h4" className="t4-header-right" textAlign="right">
            Total Project Co-ordinators: {members && members != null ? members.length : "0"}
          </Header>
        </div>
        <div className="t4-mng-btns">
          <Button
            icon="add user"
            content={"Add New"}
            onClick={() => {
              setCurrentUser(null);
              setOpenAddEditPopup(true);
            }}
          ></Button>
          <Button
            icon="edit"
            content={"Edit"}
            disabled={currentUser == null}
            onClick={() => {
              setOpenAddEditPopup(true);
            }}
          ></Button>
          <Button
            icon="user delete"
            content={"Delete"}
            disabled={
              currentUser == null ||
              userStore.user?.userName.toLowerCase() === currentUser.email.toLowerCase()
            }
            onClick={() => {
              setShowConfirmDelete(true);
            }}
          ></Button>
          <Button
            content={"Reset Password"}
            disabled={currentUser == null}
            onClick={() => {
              setShowChangePassword(true);
            }}
          ></Button>
        </div>
        <AdminList
          members={members}
          selectedUser={currentUser}
          onUserSelected={(user: StaffMemberDto | null) => {
            setCurrentUser(user);
          }}
        />
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={"Loading..."} />}
    </>
  );
}
