import React, { useEffect, useState } from "react";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import { useStore } from "../../../Stores/store";
import agent from "../../../api/agent";
import { Checkbox, Label } from "semantic-ui-react";
import { SurveyStatuses } from "../../../models/enums";
import { toast } from "react-toastify";

interface Props {
  schoolCode: string;
  survey: SchoolSurveyDto;
}

export default function ReportPublishSatus({ schoolCode, survey }: Props) {
  const { lookupStore, userStore } = useStore();
  const [loading, setloading] = useState<boolean>(false);
  const [currentSurvey, setCurrentSurvey] = useState<SchoolSurveyDto>();

  useEffect(() => {
    setloading(true);
    loadSurvey();
  }, [schoolCode, survey]);

  const loadSurvey = () => {
    agent.Schools.survey(schoolCode, survey.code)
      .then((result) => {
        setCurrentSurvey(result);
      })
      .catch(() => {
        toast.error("Failed to load survey info");
      })
      .finally(() => {
        setloading(false);
      });
  };

  const updateReportStatus = (published: boolean | undefined) => {
    setloading(true);
    let status: SurveyStatuses = published && published === true ? SurveyStatuses.BiLive : SurveyStatuses.PendingBIApproval;

    agent.Schools.updateSurveyStatus(schoolCode, survey.id, status)
      .then((result) => {
        loadSurvey();
        toast.success("Survey status changed successfully.");
      })
      .catch((error) => {
        toast.error("Failed to change survey status.");
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <>
      <Label className="t4-status-lbl" content="Status:" /> {currentSurvey && lookupStore.getSurveyStatus(currentSurvey.status)}
      <div className="t4-toggle-lbl">
        {userStore && userStore.isLoggedIn && userStore.isSystemAdmin && (
          <>
            <Label className="t4-toggle-lbl" content="Publish:" />
            <Checkbox
              toggle
              disabled={loading || !currentSurvey || currentSurvey.status === SurveyStatuses.setup || currentSurvey.status === SurveyStatuses.SurveyRunning}
              checked={currentSurvey && currentSurvey.status === SurveyStatuses.BiLive}
              onClick={(e, d) => {
                updateReportStatus(d.checked);
              }}
            />
          </>
        )}
      </div>
    </>
  );
}
