import React, { useState } from "react";
import AddChainSchoolDto from "../../../models/chains/addChainSchoolDto";
import { toast } from "react-toastify";
import Papa from "papaparse";
import agent from "../../../api/agent";
import { Button, Container, Icon, Message, Segment } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import ImportMembersProgress from "../../Layout/importProgress";
import ItemValidationDto from "../../../models/common/itemValidationDto";

interface Props {
  chainCode: string;
}

export default function ImportSchools({ chainCode }: Props) {
  const navigate = useNavigate();
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [templateDownloaded, setTemplateDownloaded] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [importSchools, setImportSchools] = useState<AddChainSchoolDto[] | null>(null);
  const [currentImportLogId, setCurrentImportLogId] = useState<string | null>(null);
  const [validationResults, setValidationResults] = useState<ItemValidationDto[]>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [file, setFile] = useState();

  const downloadTemplate = () => {
    setTemplateDownloaded(true);
    var link = document.createElement("a");
    link.download = "schooltemplate.csv";
    link.href = "/csvTemplates/schooltemplate.csv";
    link.click();
  };

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    setValidationResults([]);
    setIsValid(false);
    setIsValidating(true);
    setIsImporting(false);
    if (file) {
      Papa.parse(file, {
        complete: function (results: any) {
          let data = parseSchools(results.data);
          if (data == null || data.length === 0) {
            toast.warn("file is empty !");
            setIsValidating(false);
            return;
          }

          setImportSchools(data);
          setValidationResults([]);

          if (!chainCode) return;
          agent.Chains.validateSchools(chainCode, data)
            .then((result) => {
              setValidationResults(result);
              if (!result || result == null || result.length === 0) setIsValid(true);
            })
            .catch((error) => toast.error("Failed to validate csv data for schools"))
            .finally(() => {
              setIsValidating(false);
            });
        },
      });
    }
  };

  const parseSchools = (data: any): AddChainSchoolDto[] => {
    let result: AddChainSchoolDto[] = [];
    if (data == null || data.length <= 1) return result;

    let invalidRowCount = 0;
    data.forEach((element: any, index: number) => {
      if (index === 0) return;
      if (element.length === 1) return;
      if (element.length < 10) {
        invalidRowCount++;
        return;
      }
      let school: AddChainSchoolDto = {
        id: 0,
        fullSchoolName: element[0],
        contactFirstName: element[1],
        contactLastName: element[2],
        contactEmail: element[3],
        contactPhone: element[4],
        projectCoordinatorFirstName: element[5],
        projectCoordinatorLastName: element[6],
        projectCoordinatorEmail: element[7],
        projectCoordinatorPhone: element[8],
        projectCoordinatorPassword: element[9],
      };
      result.push(school);
    });

    if (invalidRowCount > 0)
      toast.warn(`${invalidRowCount} rows were not loaded due to missing fields.`);

    return result;
  };

  const startImport = () => {
    if (importSchools == null || !chainCode) return;
    setIsImporting(true);

    agent.Chains.newImport(chainCode, importSchools.length).then((result) => {
      if (importSchools == null || !chainCode || !result) return;
      setCurrentImportLogId(result);
      agent.Chains.importSchools(chainCode, result, importSchools)
        .then((result) => {
          toast.success("Import finished successfully");
          navigate(`/dashboard/chain/${chainCode}/manageSchools`);
        })
        .catch((error) => toast.error("Failed to import schools"))
        .finally(() => {
          setIsImporting(false);
        });
    });
  };

  return (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/chain/${chainCode}`);
            }}
          ></Button>
          <h2>Import Schools</h2>
        </div>

        <Container>
          <div className="t4-import-step">
            <ul className="t4-StepProgress">
              <li
                className={`t4-StepProgress-item ${
                  file != null || templateDownloaded ? "is-done" : ""
                } `}
              >
                <strong>Download Template.</strong>
                <p className="t4-substep">
                  Add school details. <br />
                  Do not change column headers.
                  <br />
                  Save the template.
                  <br />
                  <Button
                    className="t4-btn basic ui button dl-btn t4-import-btn"
                    compact
                    onClick={downloadTemplate}
                  >
                    Download Template
                  </Button>
                </p>
              </li>
              <li className={`t4-StepProgress-item ${file == null ? "" : "is-done"} `}>
                <strong>Select the template below.</strong>
                <div>
                  <div className="t4-upload">
                    <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} />{" "}
                  </div>
                  <div className="react-file-reader-button"></div>
                </div>
              </li>
              <li className={`t4-StepProgress-item ${isValid ? "is-done" : ""} `}>
                <strong>Select Validate.</strong>
                <Button
                  className="t4-btn-push t4-btn basic"
                  loading={isValidating}
                  disabled={file == null || isImporting}
                  onClick={(e) => {
                    handleOnSubmit(e);
                  }}
                >
                  Validate
                </Button>
                <br />
                <div id="divValidationMessages">
                  {validationResults && validationResults.length > 0 && (
                    <Message icon negative className="t4-error-msg">
                      <Icon name="exclamation triangle" />
                      <Message.Content>
                        <Message.Header>
                          {" "}
                          <h3>Errors</h3>
                        </Message.Header>
                        <p>
                          {validationResults &&
                            validationResults.length > 0 &&
                            validationResults.map((v, index) => (
                              <p key={index}>
                                {v.item}
                                <Icon name="arrow right" className="t4-errors" />
                                {v.error}
                              </p>
                            ))}
                        </p>
                      </Message.Content>
                    </Message>
                  )}
                </div>
              </li>
              <li className={`t4-StepProgress-item ${isImporting ? "is-done" : ""} `}>
                <strong>Start Importing</strong>
                <div>
                  <Button
                    className="t4-btn-push t4-btn basic"
                    loading={isImporting}
                    content={"Start Import"}
                    onClick={() => {
                      startImport();
                    }}
                    disabled={(validationResults != null && validationResults.length > 0) || !isValid}
                  />
                </div>
              </li>
            </ul>
          </div>
        </Container>
        {isImporting && <ImportMembersProgress logCode={currentImportLogId} />}
      </Segment>
    </>
  );
}
