import React from "react";
import { Icon, SemanticICONS, Table, TableCell, TableHeader } from "semantic-ui-react";
import QuestionDto from "../../../models/surveys/questionDto";
import { NavLink } from "react-router-dom";

interface Props {
  questions: QuestionDto[] | null;
}

export default function QuestionList({ questions }: Props) {
  const list = (
    <>
      <Table className="t4-tbl">
        <TableHeader>
          <TableCell>#</TableCell>
          <TableCell>Title</TableCell>
          <TableCell></TableCell>
        </TableHeader>
        <Table.Body>
          {questions != null &&
            questions.length > 0 &&
            questions.map((question) => (
              <Table.Row key={question.id}>
                <Table.Cell>{question.id}</Table.Cell>
                <Table.Cell>{question.title}</Table.Cell>
                <Table.Cell>
                  <NavLink to={"/dashboard/managequestions/" + question.id}>
                    <Icon name={"edit" as SemanticICONS} />
                  </NavLink>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
  return <>{list}</>;
}
