import React, { useEffect, useState } from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { Segment, Select } from "semantic-ui-react";
import parse from "html-react-parser";
import { useStore } from "../../../Stores/store";

interface Props {
  question: QuestionDto;
  onAnswer: (value: number, response: string) => void;
}

export default function ViewMcqDropDownList({ question, onAnswer }: Props) {
  const { translationStore } = useStore();
  const [options, setOptions] = useState<{ text: string; value: number }[]>([]);
  useEffect(() => {
    if (question && question.choices && question.choices.length > 0) {
      let opts: { text: string; value: number }[] = [];
      question.choices.forEach((c) => opts.push({ text: translationStore.choiceBody(c), value: c.id }));
      setOptions(opts);
    }
  }, [question]);

  return (
    <>
      <Segment className={question.isAnswered === false ? "t4-mcq required" : "t4-mcq"}>
        <div>{parse(translationStore.questionBody(question))}</div>
        <div className="t4-mcq-radio">
          {question.choices && question.choices.length > 0 && (
            <Select
              value={question.userAnswer!}
              options={options}
              onChange={(e, d) => {
                if (d.value && d.options) {
                  var text = d.options.find((o) => o.value === d.value)?.text;
                  onAnswer(+d.value, text ? text.toString() : "");
                }
              }}
            />
          )}
        </div>
        {/*{question.isAnswered === false && <Message color={"red"}>Required</Message>}*/}
      </Segment>
    </>
  );
}
