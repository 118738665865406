import { QuestionTypes, SchoolGenders, SchoolLocations, SchoolTypes, SurveyStatuses } from "../models/enums";

export default class LookupStore {
  getSchoolLocation = (location: SchoolLocations | null | undefined): string => {
    if (!location || location == null) return "-";
    if (location === SchoolLocations.City_Urban_Metropolitan) return "City / urban / metropolitan";
    else if (location === SchoolLocations.Town_SemiDense_Suburbs) return "Town / semi dense / suburbs";
    else if (location === SchoolLocations.Rural) return "Rural";
    return "";
  };

  getSchoolGenders = (gender: number | null | undefined): string => {
    if (!gender || gender == null) return "-";
    if (gender === SchoolGenders.Boys) return "Boys";
    else if (gender === SchoolGenders.Girls) return "Girls";
    else if (gender === SchoolGenders.Coeducational) return "Co-educational";
    return "";
  };

  getSchoolType = (type: SchoolTypes | null | undefined): string => {
    if (!type || type == null) return "-";
    if (type === SchoolTypes.CharitySchool) return "Charity School";
    else if (type === SchoolTypes.GovernmentAlternativeStructure) return "Government Alternative Structure";
    else if (type === SchoolTypes.GovernmentFaithSchool) return "Government Faith School";
    else if (type === SchoolTypes.GovernmentWithSelectiveEntrance) return "Government With Selective Entrance";
    else if (type === SchoolTypes.PrivateIndependent) return "Private Independent";
    else if (type === SchoolTypes.PrivateIndependentFaithSchool) return "Private Independent Faith School";
    else if (type === SchoolTypes.StandardGovernmentSchool) return "Standard Government School";
    return "";
  };

  getQuestionType = (type: QuestionTypes | undefined): string => {
    if (!type) return "-";
    if (type === QuestionTypes.Likert) return "Likert";
    else if (type === QuestionTypes.McqDropDown) return "Multiple choice";
    else if (type === QuestionTypes.McqMultipleResponse) return "Mcq Multiple Response";
    else if (type === QuestionTypes.McqRadioList) return "Multiple choice";
    else if (type === QuestionTypes.NumericText) return "Numeric Text";
    else if (type === QuestionTypes.Rating) return "Rating";
    else if (type === QuestionTypes.Text) return "Text";
    return "";
  };

  getSurveyStatus = (status: SurveyStatuses): string => {
    if (status === SurveyStatuses.setup) return "Setup";
    else if (status === SurveyStatuses.SurveyRunning) return "Survey Running";
    else if (status === SurveyStatuses.PendingBIApproval) return "BI Awaiting Approval";
    else if (status === SurveyStatuses.BiLive) return "Published";
    return "-";
  };
}
