import React, { useEffect, useState } from "react";
import { Grid, Segment } from "semantic-ui-react";
import agent from "../../api/agent";
import SchoolSurveyDto from "../../models/schools/schoolSurveyDto";

interface Props {
  schoolCode: string | undefined;
}

export default function SchoolNameControl({ schoolCode }: Props) {
  const [title, setTitle] = useState<string>();
  const [adminCount, setAdminCount] = useState<number>();
  const [staffCount, setStaffCount] = useState<number>();
  const [activeSurvey, setActiveSurvey] = useState<SchoolSurveyDto>();

  useEffect(() => {
    if (!schoolCode) return;
    agent.Schools.title(schoolCode).then((result) => setTitle(result));
    agent.Schools.adminCount(schoolCode).then((result) => setAdminCount(result));
    agent.Schools.membersCount(schoolCode).then((result) => setStaffCount(result));
    agent.Schools.activeSchoolsurvey(schoolCode).then((result) => setActiveSurvey(result));
  }, [schoolCode]);

  return (
    <>
      <Grid className="t4-banner-info stackable">
        <Grid.Row>
          <Grid.Column width={4} className="t4-hide"></Grid.Column>
          <Grid.Column width={8}>
            <h1 className="t4-school-name">{title}</h1>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <span>Co-ordinators: </span>
              <span className="t4-banner-numbers">{adminCount}</span>
            </Segment>
            <Segment>
              <span>Staff Members: </span>
              <span className="t4-banner-numbers">{staffCount}</span>
            </Segment>
            <Segment>
              <span>{activeSurvey?.name}</span>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
