import React, { useState } from "react";
import CustomDateInput from "../../../Common/customDateInput";
import { Formik } from "formik";
import RenewLicenseDetailsDto from "../../../models/schools/renewLicenseDetailsDto";
import * as Yup from "yup";
import { Button, Form } from "semantic-ui-react";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

interface Props {
  chainCode: string | null;
  onCancel: () => void;
  onUpdate: () => void;
}

export default function RenewLicense({ chainCode, onCancel, onUpdate }: Props) {
  const [isSaving, setIsSaving] = useState(false);
  let details: RenewLicenseDetailsDto = {
    startDate: new Date(),
    endDate: new Date(),
  };

  const validationSchema = Yup.object({
    startDate: Yup.date().typeError("Invalid date value").required("Required"),
    endDate: Yup.date()
      .typeError("Invalid date value")
      .required("Required")
      .min(Yup.ref("startDate"), "End date can't be before Start date"),
  });

  const submitForm = (values: RenewLicenseDetailsDto) => {
    if (chainCode == null) return;
    setIsSaving(true);
    agent.Chains.renew(chainCode, values)
      .then(() => onUpdate())
      .catch((error) => toast.error("Failed to renew license"))
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={details}
        onSubmit={(values) => submitForm(values)}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <CustomDateInput name="startDate" placeholder="Start date" label="Start date" />
            <CustomDateInput name="endDate" placeholder="End date" label="End date" />
            <div className="t4-actions">
              <Button
                disabled={isSaving}
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="blue"
                content="Save"
                loading={isSaving}
                className="t4-btn-active"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
