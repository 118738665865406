import React, { useEffect, useState } from "react";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
  TableCell,
  TableHeader,
  Image,
} from "semantic-ui-react";
import { gridPageSize } from "../../../models/constants";

interface Props {
  members: StaffMemberDto[] | null;
  selectedUser: StaffMemberDto | null;
  onUserSelected: (user: StaffMemberDto | null) => void;
}

export default function AdminList({ members, onUserSelected, selectedUser }: Props) {
  const { t } = useTranslation("common");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>("");
  const [sortedMembers, setSortedMembers] = useState<StaffMemberDto[] | null>();

  useEffect(() => {
    loadPage(1);
  }, []);

  const emptyList = (
    <Segment>
      <Image src="/assets/t4-nomembers.svg" size="small" centered />
      <h3 className="t4-error-msg">There are no project co-ordinators in this school!</h3>
    </Segment>
  );

  const gridPaging = (
    <Pagination
      disabled={members == null || members.length <= gridPageSize}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={members == null ? 0 : Math.ceil(members?.length / gridPageSize)}
      onPageChange={(e, d) => {
        if (d.activePage) loadPage(+d.activePage);
      }}
    />
  );

  const loadPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
    if (!members || members == null || members.length === 0) return;

    let startIndex = (pageIndex - 1) * gridPageSize;
    let users = members.slice(startIndex, startIndex + gridPageSize);
    if (users) setSortedMembers(users);
  };

  const searchUsers = (query: string) => {
    setFilter(query);
    if (query.trim() === "") {
      loadPage(1);
    } else {
      let users = members?.filter((m) => filterUser(m));
      if (users) setSortedMembers(users);
      else setSortedMembers(null);
    }
  };

  const filterUser = (user: StaffMemberDto) => {
    if (filter === "") return true;
    if (user.displayName.toLowerCase().includes(filter.toLowerCase())) return true;
    if (user.email.toLowerCase().includes(filter.toLowerCase())) return true;
    return false;
  };

  const list = (
    <>
      <Table className="t4-tbl">
        <TableHeader>
          <Table.Row>
            <TableCell></TableCell>
            <TableCell>#</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            {/*<TableCell>Job</TableCell>*/}
            <TableCell>Last Login</TableCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {sortedMembers != null &&
            sortedMembers.length > 0 &&
            sortedMembers.map((member) => (
              <Table.Row key={member.id}>
                <Table.Cell>
                  <Checkbox
                    checked={selectedUser != null && selectedUser.id === member.id}
                    onClick={() => {
                      if (selectedUser != null && selectedUser.id === member.id) onUserSelected(null);
                      else onUserSelected(member);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>{member.id}</Table.Cell>
                <Table.Cell>{member.firstName}</Table.Cell>
                <Table.Cell>{member.lastName}</Table.Cell>
                <Table.Cell>{member.email}</Table.Cell>
                <Table.Cell>{member.phone != null ? member.phone : "-"}</Table.Cell>
                {/*<Table.Cell>{member.jobRole != null ? member.jobRole : "-"}</Table.Cell>*/}
                <Table.Cell>
                  {member.lastLogin != null ? member.lastLoginDateString : "Never"}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {members != null && members.length > gridPageSize && filter === "" && gridPaging}
    </>
  );
  return (
    <>
      <Input
        onChange={(e) => searchUsers(e.target.value)}
        value={filter}
        autoComplete="new-password"
        placeholder={"Search..."}
        icon={
          <Icon
            name="delete"
            link
            onClick={() => {
              loadPage(1);
              setFilter("");
            }}
          />
        }
      />
      {sortedMembers != null && sortedMembers.length > 0 && list}
      {(sortedMembers == null || sortedMembers.length === 0) && emptyList}
    </>
  );
}
