import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { Formik } from "formik";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import CustomTextInput from "../../../Common/customTextInput";
import AddChainSchoolDto from "../../../models/chains/addChainSchoolDto";

interface Props {
  chainCode: string;
}
export default function AddChainSchool({ chainCode }: Props) {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [school, setSchool] = useState<AddChainSchoolDto>({
    id: 0,
    contactEmail: "",
    contactFirstName: "",
    contactLastName: "",
    contactPhone: "",
    fullSchoolName: "",
    projectCoordinatorEmail: "",
    projectCoordinatorFirstName: "",
    projectCoordinatorLastName: "",
    projectCoordinatorPassword: "",
    projectCoordinatorPhone: "",
  });

  useEffect(() => {
    //intialize school contact
    let s = { ...school, name: "", contactFirstName: "", contactLastName: "" };
    setSchool(s);
  }, []);

  const submitForm = (values: AddChainSchoolDto) => {
    addNewSchool(values);
  };

  const addNewSchool = (values: AddChainSchoolDto) => {
    setIsSaving(true);
    agent.Chains.addSchool(chainCode, values)
      .then((result) => {
        toast.success("School added successfully");
        navigate(`/dashboard/chain/${chainCode}/manageSchools`);
      })
      .catch((error) => {
        //console.log(error);
        if (error.response && error.response.status && error.response.status === 409)
          toast.error(error.response.data);
        else toast.error("Failed to add school !");
      })
      .finally(() => setIsSaving(false));
  };

  const validationSchema = Yup.object({
    fullSchoolName: Yup.string().required("Required"),
    contactFirstName: Yup.string().required("Required"),
    contactLastName: Yup.string().required("Required"),
    contactPhone: Yup.string().required("Required"),
    contactEmail: Yup.string().required("Required").email("Invalid email format"),
    projectCoordinatorFirstName: Yup.string().required("Required"),
    projectCoordinatorLastName: Yup.string().required("Required"),
    projectCoordinatorPassword: Yup.string().required("Required"),
    projectCoordinatorPhone: Yup.string().required("Required"),
    projectCoordinatorEmail: Yup.string().required("Required").email("Invalid email format"),
  });

  const content = (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
          <h2>New School</h2>
        </div>

        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={school}
          onSubmit={(values) => submitForm(values)}
        >
          {({ handleSubmit, values }) => (
            <Form className="t4-add-edit t4-add-new-school" onSubmit={handleSubmit}>
              <Grid columns={2}>
                <h3 className="t4-school-info-sub">School Information Information</h3>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Full School Name *"}
                      placeholder={"Full School Name"}
                      name="fullSchoolName"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Contact First Name *"}
                      placeholder={"Contact First Name"}
                      name="contactFirstName"
                      disabled={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Contact Last Name *"}
                      placeholder={"Contact Last Name"}
                      name="contactLastName"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Contact Email *"}
                      placeholder={"Contact Email"}
                      name="contactEmail"
                      disabled={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Contact Phone *"}
                      placeholder={"Contact Phone"}
                      name="contactPhone"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <h3 className="t4-school-info-sub">Project Co-ordinator Information</h3>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Project Co-ordinator First Name *"}
                      placeholder={"Project Co-ordinator First Name"}
                      name="projectCoordinatorFirstName"
                      disabled={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Project Co-ordinator Last Name *"}
                      placeholder={"Project Co-ordinator Last Name"}
                      name="projectCoordinatorLastName"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Project Co-ordinator Phone *"}
                      placeholder={"Project Co-ordinator Phone"}
                      name="projectCoordinatorPhone"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Project Co-ordinator Email *"}
                      placeholder={"Project Co-ordinator Email"}
                      name="projectCoordinatorEmail"
                      disabled={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Project Co-ordinator Password *"}
                      placeholder={"Project Co-ordinator Password"}
                      name="projectCoordinatorPassword"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="t4-form-bot-btns">
                <Button
                  type="submit"
                  color="blue"
                  content="Save"
                  loading={isSaving}
                  floated="right"
                  className="t4-btn-active"
                />
                <Button
                  className="t4-btn-non"
                  floated="right"
                  disabled={isSaving}
                  onClick={() => {
                    navigate(`/dashboard/chain/${chainCode}/manageSchools`);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
  return <>{content}</>;
}
