import React, { useEffect, useState } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import agent from "../../../api/agent";
import { LanguageLookupDto } from "../../../models/common/languageLookupDto";
import { getLanguageLookups } from "../../../Common/helpers";

interface Props {
  schoolCode: string | undefined;
}

export default function SchoolLanguageSettings({ schoolCode }: Props) {
  const [surveyLanguages, setSurveyLanguages] = useState<number[]>([]);
  const [savingLanguages, setSavingLanguages] = useState<boolean>(false);

  const [allLanguages, setAllLanguages] = useState<LanguageLookupDto[]>([]);

  const [defaultLanguageId, setDefaultLanguageId] = useState<number | null>(null);
  const [defaultLanguageOptions, setDefaultLanguageOptions] = useState<LanguageLookupDto[]>([]);
  const [savingDefaultLanguageId, setSavingDefaultLanguageId] = useState<boolean>(false);

  useEffect(() => {
    if (
      !surveyLanguages ||
      surveyLanguages == null ||
      surveyLanguages.length === 0 ||
      !allLanguages ||
      allLanguages == null
    )
      setDefaultLanguageOptions([]);
    else {
      let list: LanguageLookupDto[] = [];
      list = allLanguages.filter((l) => surveyLanguages.indexOf(l.key) > -1);
      setDefaultLanguageOptions(list);
    }
  }, [allLanguages, surveyLanguages]);

  const saveSchoolLanguages = (languages: number[]) => {
    if (!schoolCode || !languages) return;
    setSurveyLanguages(languages);
    setSavingLanguages(true);
    agent.Schools.updateLanguages(schoolCode, languages).finally(() => {
      setSavingLanguages(false);
    });
  };

  const saveDefaultLanguage = (languageId: number) => {
    if (!schoolCode) return;

    setDefaultLanguageId(languageId);
    setSavingDefaultLanguageId(true);
    agent.Schools.updateDefaultLanguage(schoolCode, languageId).finally(() => {
      setSavingDefaultLanguageId(false);
    });
  };

  useEffect(() => {
    if (!schoolCode) return;

    setSavingLanguages(true);
    setSavingDefaultLanguageId(true);
    agent.Translations.languages().then((result) => {
      if (result && result.length > 0) {
        let lookups = getLanguageLookups(result);
        setAllLanguages(lookups);
      }
    });

    agent.Schools.languages(schoolCode)
      .then((result) => {
        if (!result || result == null || result.length === 0) setSurveyLanguages([1]);
        else {
          let languages: number[] = [];
          result.forEach((element) => {
            languages.push(element.id);
          });
          setSurveyLanguages(languages);
        }
      })
      .finally(() => {
        setSavingLanguages(false);
      });

    agent.Schools.defaultLanguage(schoolCode)
      .then((result) => {
        setDefaultLanguageId(result);
      })
      .finally(() => {
        setSavingDefaultLanguageId(false);
      });
  }, [schoolCode]);

  return (
    <>
      <div className="t4-survey-settings-section">
        <h3>Language</h3>

        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <span className="t4-lang-set">Survey Languages: </span>
            </Grid.Column>
            <Grid.Column width={10} className="Lang-set-dd">
              <Dropdown
                multiple={true}
                selection
                className="t4-lang-multi-settings"
                disabled={savingLanguages}
                options={allLanguages}
                value={surveyLanguages}
                onChange={(e, d) => {
                  let values = d.value as number[];
                  if (values.length === 0 || values[0] !== 1) values.unshift(1);
                  setSurveyLanguages(values);
                  saveSchoolLanguages(d.value as number[]);
                }}
                onClose={(e, d) => {
                  saveSchoolLanguages(d.value as number[]);
                }}
              ></Dropdown>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <span className="t4-lang-set">Default Survey Language: </span>
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                value={defaultLanguageId == null ? 1 : defaultLanguageId}
                disabled={savingDefaultLanguageId}
                selection
                className="t4-lang-settings"
                options={defaultLanguageOptions}
                onChange={(e, d) => {
                  saveDefaultLanguage(d.value as number);
                }}
              ></Dropdown>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
}
