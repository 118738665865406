import React from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { Radio, Segment, Table } from "semantic-ui-react";
import parse from "html-react-parser";
import { useStore } from "../../../Stores/store";

interface Props {
  question: QuestionDto;
  onAnswer: (value: number, response: string) => void;
}

export default function ViewLikert({ question, onAnswer }: Props) {
  const { translationStore } = useStore();

  return (
    <>
      <Segment className={question.isAnswered === false ? "t4-likert required" : "t4-likert"}>
        <div className="likert-main-header" style={{ display: "none" }}>
          <Table className="t4-likert-options t4-likert-head" striped stackable>
            <Table.Header className="t4-tbl-head">
              <Table.Row className="likert-emo">
                <Table.HeaderCell width={6}></Table.HeaderCell>
                {question.choices &&
                  question.choices.length > 0 &&
                  question.choices.map((c) => (
                    <Table.Cell key={c.id}>
                      <ul className="t4-likert-choices">
                        <li className="emojie-option worst">
                          {/*the five emojies classes are "worst, fine, neutral, good, loveit "*/}
                          <Radio className="hide-emo" label={parse(translationStore.choiceBody(c))} />
                        </li>
                      </ul>
                    </Table.Cell>
                  ))}
              </Table.Row>
            </Table.Header>
          </Table>
        </div>
        <Table className="t4-likert-options likert-tbl" striped>
          {/*Likert Table begining*/}
          <Table.Body>
            <Table.Row className="likert-emo">
              <Table.Cell width={6}>{parse(translationStore.questionBody(question))}</Table.Cell>
              {question.choices &&
                question.choices.length > 0 &&
                question.choices.map((c) => (
                  <Table.Cell key={c.id}>
                    <ul className="t4-likert-choices">
                      <li className="emojie-option worst">
                        {/*the firve emojies classes are "worst, fine, neutral, good, loveit "*/}
                        <Radio
                          label={""}
                          checked={question.userAnswer != undefined && question.userAnswer === c.id}
                          onChange={() => onAnswer(+c.id, c.body)}
                        />
                      </li>
                    </ul>
                  </Table.Cell>
                ))}
            </Table.Row>
            <Table.Row className="t4-req"></Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
}
