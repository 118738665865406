import React, { useEffect, useState } from "react";
import SchoolMembersList from "./membersList";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";
import { Button, Header, Modal, Segment } from "semantic-ui-react";
import AddEditStaffProfile from "./addEditStaffProfile";
import SchoolNameControl from "../schoolNameControl";

export default function SchoolStaffMembers() {
  let params = useParams();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [members, setMembers] = useState<StaffMemberDto[] | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<StaffMemberDto[]>([]);
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loadMembers = () => {
    if (!params.schoolCode) return;
    setLoading(true);
    agent.Schools.members(params.schoolCode)
      .then((result) => {
        setMembers(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMembers();
  }, [params.schoolCode]);

  const deleteUser = () => {
    if (selectedUsers == null || selectedUsers.length === 0 || !params.schoolCode) return;
    setIsDeleting(true);
    agent.Schools.deleteMember(selectedUsers, params.schoolCode)
      .then(() => {
        toast.success("Staff profile deleted successfully");
        setShowConfirmDelete(false);
        setSelectedUsers([]);
        loadMembers();
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const editPopup = (
    <>
      <Modal open={dialogOpened}>
        <Modal.Header>Staff Profile Information</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AddEditStaffProfile
              schoolCode={params.schoolCode!}
              member={selectedUsers[0]}
              onClose={(refresh: boolean) => {
                setDialogOpened(false);
                if (refresh) loadMembers();
              }}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const confirmDeletePopup = (
    <>
      <Modal open={showConfirmDelete}>
        <Modal.Header>Please Confirm</Modal.Header>
        <Modal.Content>
          <Modal.Description>Are you sure you want to delete the selected user?</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={isDeleting}
            onClick={() => {
              setShowConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="blue"
            content="Confirm"
            loading={isDeleting}
            className="t4-btn-active"
            onClick={() => {
              deleteUser();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );

  const content = (
    <>
      <SchoolMembersList
        members={members}
        selectedUsers={selectedUsers}
        onUserSelected={(user: StaffMemberDto) => {
          if (selectedUsers.filter((u) => u.id === user.id).length > 0) {
            let selected = selectedUsers.filter((u) => u.id != user.id);
            setSelectedUsers(selected);
          } else {
            let selected = [...selectedUsers, user];
            setSelectedUsers(selected);
          }
        }}
      />
    </>
  );

  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      <Segment className="t4-mainmenu t4-header-align">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
              }}
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              Staff Profile Information
            </Header>
          </div>
          {/*<Header as="h4" className="t4-header-right" textAlign="right">
            Total Staff Members: {members && members != null && members.length}
        </Header>*/}
        </div>
        {editPopup}
        {confirmDeletePopup}
        <div className="t4-mng-btns">
          <Button
            icon="add user"
            content={"Add New"}
            onClick={() => {
              setSelectedUsers([]);
              setDialogOpened(true);
            }}
          ></Button>
          <Button
            icon="edit"
            content={"Edit"}
            disabled={selectedUsers.length != 1}
            onClick={() => {
              setDialogOpened(true);
            }}
          ></Button>
          <Button
            icon="user delete"
            content={"Delete"}
            disabled={selectedUsers.length === 0}
            onClick={() => {
              setShowConfirmDelete(true);
            }}
          ></Button>
          <Button
            icon={<i className="t4-import-icn icon" />}
            content={"Import"}
            onClick={() => {
              navigate(`/dashboard/importmembers/${params.schoolCode}`);
            }}
          ></Button>
        </div>
        {!loading && content}
        {loading && <LoadingComponent content={"Loading Staff Profile Information"} />}
      </Segment>
    </>
  );
}
