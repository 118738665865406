import React from "react";
import InvitationDto from "../../../models/surveys/invitationDto";
import { Pagination, Table } from "semantic-ui-react";
import { useStore } from "../../../Stores/store";

interface Props {
  invitations: InvitationDto[] | undefined;
  schoolCode: string;
  totalItemCount: number;
  currentPage: number;
  onPageChange: (newPageNumber: number) => void;
}

export default function InvitationsList({
  invitations,
  schoolCode,
  totalItemCount,
  currentPage,
  onPageChange,
}: Props) {
  const { userStore } = useStore();

  const gridPaging = (
    <Pagination
      disabled={invitations == null || totalItemCount <= 200}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={invitations == null ? 0 : Math.ceil(totalItemCount / 200)}
      onPageChange={(e, d) => {
        if (d.activePage) onPageChange(+d.activePage);
      }}
    />
  );

  return (
    <>
      <Table className="t4-tbl">
        <Table.Header>
          <Table.Row>
            <Table.Cell>#</Table.Cell>
            <Table.Cell>Staff Name</Table.Cell>
            <Table.Cell>Invitation Date</Table.Cell>
            {userStore.isLoggedIn && userStore.user && userStore.isSystemAdmin && (
              <Table.Cell>Status</Table.Cell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invitations != null &&
            invitations.length > 0 &&
            invitations.map((invitation) => (
              <Table.Row key={invitation.id}>
                <Table.Cell>{invitation.id}</Table.Cell>
                <Table.Cell>{invitation.staffMemberDisplayName}</Table.Cell>
                <Table.Cell>{invitation.inviteDate}</Table.Cell>
                {userStore.isLoggedIn && userStore.user && userStore.isSystemAdmin && (
                  <Table.Cell>{invitation.isFilled ? "Completed" : "-"}</Table.Cell>
                )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {invitations != null && gridPaging}
    </>
  );
}
