import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

interface Props {
  schoolCode: string | undefined;
  selectedSurvey: SchoolSurveyDto | undefined;
  onClose: (refresh: boolean) => void;
}

export default function ConfirmFinalReminderPopup({ schoolCode, selectedSurvey, onClose }: Props) {
  const [sendingFinalReminder, setSendingFinalReminder] = useState<boolean>(false);

  const sendFinalReminder = () => {
    if (!schoolCode || !selectedSurvey) return;
    setSendingFinalReminder(true);
    agent.Schools.sendFinalReminder(schoolCode, selectedSurvey.id)
      .then(() => {
        toast.success("Final reminder sent.");
      })
      .catch(() => {
        toast.error("Failed to send final reminder");
      })
      .finally(() => {
        setSendingFinalReminder(false);
        onClose(true);
      });
  };

  return (
    <>
      <Modal open={true}>
        <Modal.Header>Confirmation required</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {!sendingFinalReminder && (
              <div className="t4-confirm-popup">
                <p>
                  The default notification process is a reminder email will be sent after 7 days, with a
                  final reminder email being sent after another 3 days.
                </p>
                <p>
                  If you wish to send an additional reminder, after these reminders have been sent,
                  please confirm here.
                </p>
                <p> Please note the reminder email will be sent immediately.</p>
              </div>
            )}
            {sendingFinalReminder && (
              <div className="t4-confirm-popup">Sending final reminder. Please wait....</div>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={sendingFinalReminder}
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="t4-btn t4-pop-btn"
            content="Confirm"
            loading={sendingFinalReminder}
            onClick={() => {
              sendFinalReminder();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}
