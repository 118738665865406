import { makeAutoObservable } from "mobx";
import SurveyDto from "../../models/surveys/surveyDto";
import SurveyPageDto from "../../models/surveys/surveyPageDto";
import cloneDeep from "lodash.clonedeep";
import QuestionDto from "../../models/surveys/questionDto";

export default class SurveyPlayerManager {
  private _survey: SurveyDto | null = null;
  private _currentPageIndex: number = 0;

  constructor(s: SurveyDto) {
    makeAutoObservable(this);
    this._survey = cloneDeep(s);
    if (s.currentPage != null && s.currentPage > 0) this._currentPageIndex = s.currentPage;
  }

  public get totalPageCount(): number {
    if (this._survey == null || this._survey.surveyPages == null) return 0;
    return this._survey.surveyPages.length;
  }

  public get currentPageIndex(): number {
    return this._currentPageIndex;
  }

  start = (): SurveyPageDto | null => {
    this._currentPageIndex = 0;
    if (this._survey && this._survey.surveyPages && this._survey.surveyPages.length > 0) {
      if (this._survey.currentPage != null && this._survey.currentPage > 0)
        this._currentPageIndex = this._survey.currentPage;
      let page = this._survey.surveyPages[this._currentPageIndex];
      //return cloneDeep(page);

      let newPage = this.filterPage(page);
      if (newPage.questions?.length === 0 && page.questions && page.questions?.length > 0)
        return this.nextPage();
      return cloneDeep(newPage);
    }
    return null;
  };

  nextPage = (): SurveyPageDto | null => {
    if (!this._survey || !this._survey.surveyPages) return null;

    let index = this._currentPageIndex + 1;
    this._currentPageIndex = index;
    let page = this._survey.surveyPages[this._currentPageIndex];
    let newPage = this.filterPage(page);

    if (newPage.questions?.length === 0 && page.questions && page.questions?.length > 0)
      return this.nextPage();
    return cloneDeep(newPage);
  };

  previousPage = (): SurveyPageDto | null => {
    if (!this._survey || !this._survey.surveyPages) return null;
    this._currentPageIndex = this._currentPageIndex - 1;
    let page = this._survey.surveyPages[this._currentPageIndex];
    let newPage = this.filterPage(page);

    if (newPage.questions?.length === 0 && page.questions && page.questions?.length > 0)
      return this.previousPage();

    return cloneDeep(newPage);
  };

  filterPage = (page: SurveyPageDto): SurveyPageDto => {
    //checks if any question has a conditional branching condition and apply
    let newPage: SurveyPageDto = { ...page, questions: [] };
    if (page.questions == null || page.questions.length === 0) return newPage;

    page.questions.forEach((q) => {
      if (q.conditionalSurveyQuestionId == null) {
        //question has no condition
        newPage.questions?.push(cloneDeep(q));
        return;
      } else {
        //question has condition
        let conditionQuestion: QuestionDto | null = this.getQuestion(q.conditionalSurveyQuestionId);
        if (
          conditionQuestion != null &&
          conditionQuestion.userAnswer != null &&
          q.conditionalSurveyQuestionChoiceIds?.indexOf(+conditionQuestion.userAnswer) != -1
        ) {
          newPage.questions?.push(cloneDeep(q));
        }
      }
    });
    return newPage;
  };

  getQuestion = (questionId: number): QuestionDto | null => {
    if (
      this._survey == null ||
      this._survey.surveyPages == null ||
      this._survey.surveyPages.length === 0
    )
      return null;

    for (var i = 0; i < this._survey.surveyPages.length; i++) {
      let page = this._survey.surveyPages[i];
      if (page.questions && page.questions.length > 0) {
        let question = page.questions.find((q) => q.id === questionId);
        if (question && question != null) return question;
      }
    }
    return null;
  };

  setUserResponse = (pageResponse: SurveyPageDto) => {
    if (
      !this._survey ||
      !this._survey.surveyPages ||
      pageResponse.questions == null ||
      pageResponse.questions.length === 0
    )
      return;

    let page: SurveyPageDto | undefined = this._survey.surveyPages.find((p) => p.id === pageResponse.id);
    if (!page) return;

    pageResponse.questions.forEach((q) => {
      let question: QuestionDto | undefined = page?.questions?.find((e) => e.id === q.id);
      if (question) {
        question.userAnswer = q.userAnswer;
        question.response = q.response;
        question.isAnswered = q.isAnswered;
        if (q.userAnswers && q.userAnswers != null && q.userAnswers.length > 0)
          question.userAnswers = [...q.userAnswers];
      }
    });
  };
}
