import React, { useEffect, useState } from "react";
import SurveyDto from "../../models/surveys/surveyDto";
import SurveyPager from "./surveyPager";
import SurveyPageDto from "../../models/surveys/surveyPageDto";
import { Button, Container, Message } from "semantic-ui-react";
import PageViewer from "./pageViewer";
import SurveyPlayerManager from "./surveyPlayerManager";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SurveyFinished from "./surveyFinished";
import { useStore } from "../../Stores/store";
import cloneDeep from "lodash.clonedeep";
import { QuestionTypes } from "../../models/enums";
import agent from "../../api/agent";

interface Props {
  survey: SurveyDto;
  finished: boolean;
}

export default function SurveyPlayer({ survey, finished }: Props) {
  const { t } = useTranslation("common");
  const { commonStore } = useStore();
  const [saving, setSaving] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<SurveyPageDto>();
  const [manager, setManager] = useState<SurveyPlayerManager>(new SurveyPlayerManager(survey));
  const [surveyFinished, setSurveyFinished] = useState<boolean>(false);
  const [missingQuestion, setMissingQuestion] = useState<boolean>(false);

  useEffect(() => {
    if (finished) {
      setSurveyFinished(true);
      return;
    }
    setManager(new SurveyPlayerManager(survey));
    let page = manager.start();
    if (page !== null) setCurrentPage(page);
    else toast.error("Invalid Survey");
  }, []);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const navigateNextPage = () => {
    //validate all questions have been answered on the page
    if (!validteAllQuestionsHasBeenAnswered()) return;

    setSaving(true);
    if (!commonStore.currentInvitationCode || !currentPage) {
      //refresh page to reload survey
      toast.error("Session has expired!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }

    agent.Invitations.saveUserAnswers(commonStore.currentInvitationCode, currentPage)
      .then((result) => {
        //navigate to next page
        if (currentPage) manager.setUserResponse(currentPage);
        let page = manager.nextPage();
        if (page !== null) setCurrentPage(page);
      })
      .catch((error) => {
        toast.error(t("ErrorMessages.OfflineErrorMessage"));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const navigatePreviousPage = () => {
    setSurveyFinished(false);
    if (currentPage) manager.setUserResponse(currentPage);
    let page = manager.previousPage();
    if (page !== null) setCurrentPage(page);
  };

  const finishSurvey = () => {
    if (!commonStore.currentInvitationCode || !currentPage) {
      //refresh page to reload survey
      toast.error("Session has expired!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }

    if (!validteAllQuestionsHasBeenAnswered()) return;

    setSaving(true);
    agent.Invitations.finish(commonStore.currentInvitationCode, currentPage)
      .then((result) => {
        if (currentPage) manager.setUserResponse(currentPage);
        setSurveyFinished(true);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const validteAllQuestionsHasBeenAnswered = (): Boolean => {
    if (!currentPage || !currentPage.questions || currentPage.questions.length === 0) return true;

    let allQuestionsAnswered = true;
    let pageQuestions = cloneDeep(currentPage.questions);

    pageQuestions.forEach((q) => {
      if (
        q.questionType === QuestionTypes.NumericText &&
        q.response !== "" &&
        q.response != null &&
        q.response
      ) {
        q.isAnswered = true;
      } else if (
        q.questionType === QuestionTypes.Text &&
        q.response !== "" &&
        q.response != null &&
        q.response
      ) {
        q.isAnswered = true;
      } else if (
        q.questionType != QuestionTypes.McqMultipleResponse &&
        q.userAnswer &&
        q.userAnswer != null &&
        q.response !== ""
      ) {
        q.isAnswered = true;
      } else if (
        q.questionType === QuestionTypes.McqMultipleResponse &&
        q.userAnswers != null &&
        q.userAnswers.length > 0
      ) {
        q.isAnswered = true;
      } else {
        allQuestionsAnswered = false;
        q.isAnswered = false;
      }
      setCurrentPage({ ...currentPage, questions: pageQuestions });
    });

    setMissingQuestion(!allQuestionsAnswered);
    return allQuestionsAnswered;
  };

  const setQuestionAnswer = (questionId: number, answer: number | null, response: string | null) => {
    if (currentPage && currentPage.questions) {
      let updatedQuestions = currentPage.questions.map((q) => {
        if (q.id === questionId)
          return { ...q, userAnswer: answer, response: response, isAnswered: true };
        return q;
      });
      setCurrentPage({ ...currentPage, questions: updatedQuestions });
    }
  };

  const setMultiQuestionAnswer = (questionId: number, answer: number[]) => {
    if (currentPage && currentPage.questions) {
      let updatedQuestions = currentPage.questions.map((q) => {
        if (q.id === questionId) return { ...q, userAnswers: answer, isAnswered: true };
        return q;
      });
      setCurrentPage({ ...currentPage, questions: updatedQuestions });
    }
  };

  const player = (
    <div className={survey && survey.schoolCountry}>
      {currentPage && !surveyFinished && (
        <PageViewer
          page={currentPage}
          questionAnswerUpdated={(questionId: number, answer: number | null, response: string) => {
            setQuestionAnswer(questionId, answer, response);
          }}
          multiResponseQuestionAnswerUpdated={(questionId: number, answer: number[]) => {
            setMultiQuestionAnswer(questionId, answer);
          }}
        />
      )}
      {surveyFinished && <SurveyFinished />}
      {missingQuestion && (
        <Message color={"red"}>{t("ErrorMessages.CompleteMissingQuestionsMessage")}</Message>
      )}
      <div className="t4-action-btn t4-surv">
        {manager && manager.currentPageIndex > 0 && !surveyFinished && (
          <Button className="t4-btn prev" onClick={() => navigatePreviousPage()}>
            {t("Common.Previous")}
          </Button>
        )}
        {survey &&
          !surveyFinished &&
          survey.surveyPages &&
          manager.currentPageIndex < survey.surveyPages.length - 1 && (
            <Button className="t4-btn nxt" loading={saving} onClick={() => navigateNextPage()}>
              {t("Common.Next")}
            </Button>
          )}
        {survey &&
          survey.surveyPages &&
          !surveyFinished &&
          manager.currentPageIndex === survey.surveyPages.length - 1 && (
            <Button className="t4-btn" loading={saving} onClick={() => finishSurvey()}>
              {t("Common.Finish")}
            </Button>
          )}
      </div>
    </div>
  );

  return (
    <>
      <Container>
        <div className="t4-pager">
          {!surveyFinished && (
            <SurveyPager
              totalPageCount={manager.totalPageCount}
              currentPage={manager.currentPageIndex}
            />
          )}
        </div>
      </Container>
      {survey && player}
    </>
  );
}
