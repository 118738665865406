import React, { useEffect, useState } from "react";
import { Dropdown, Header, Segment } from "semantic-ui-react";
import agent from "../../../api/agent";
import { useStore } from "../../../Stores/store";
import { useNavigate } from "react-router-dom";
import { gridPageSize } from "../../../models/constants";
import ProjectsFilterDto from "../../../models/schools/projectsFilterDto";
import SchoolProjectStatusDto from "../../../models/schools/schoolProjectStatusDto";
import ProjectsList from "./projectsList";
import LoadingComponent from "../../Layout/loadingComponent";
import { PaginatedResult } from "../../../models/common/pagination";
import { SortBy } from "../../../models/enums";

export default function AdminView() {
  const { userStore } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<SchoolProjectStatusDto[]>();
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [pagingParams, setPagingParams] = useState<ProjectsFilterDto>({
    pageIndex: 1,
    pageSize: gridPageSize,
    filter: "",
    chainsFilter: [],
    schoolsFilter: [],
    statusFilter: [],
    orderBy: SortBy.LastLoginDsc,
  });

  const loadProjects = (params: ProjectsFilterDto) => {
    setLoading(true);
    agent.Schools.projects(params)
      .then((response) => {
        let result = response as unknown as PaginatedResult<SchoolProjectStatusDto[]>;
        setTotalItemCount(result.pagination.totalItems);
        setProjects(result.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!userStore.isLoggedIn || !userStore.isSystemAdmin) navigate("/");
    loadProjects(pagingParams);
  }, []);

  const content = (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-header-collection">
          <Header as="h1" className="t4-header-left" textAlign="left">
            Dashboard
          </Header>
        </div>
        {/*<Input
          onChange={(e) => {} /*searchUsers(e.target.value)
          value={filter}
          autoComplete="new-password"
          placeholder={"Search..."}
          icon={
            <Icon
              name="delete"
              link
              onClick={() => {
                //loadPage(1);
                //setFilter("");
              }}
            />
          }
        />*/}
        {projects && (
          <>
            {/*{"Sort by: "}
            <Dropdown
              value={pagingParams.orderBy as number}
              options={[
                //{ text: "First Invite Date Ascending", value: SortBy.InviteDateAsc },
                //{ text: "First Invite Date Descending", value: SortBy.InviteDateDesc },
                { text: "Last Login Date Ascending", value: SortBy.LastLoginAsc },
                { text: "Last Login Date Descending", value: SortBy.LastLoginDsc },
              ]}
              onChange={(e, d) => {
                setLoading(true);
                let params = {
                  ...pagingParams,
                  pageIndex: 1,
                  orderBy: d.value as SortBy,
                };
                setPagingParams(params);
                loadProjects(params);
              }}
            /> */}
            <ProjectsList
              items={projects}
              totalItemCount={totalItemCount}
              currentPage={pagingParams.pageIndex}
              selectedChainsFilter={!pagingParams.chainsFilter ? [] : pagingParams.chainsFilter}
              selectedSchoolsFilter={!pagingParams.schoolsFilter ? [] : pagingParams.schoolsFilter}
              selectedStatusFilter={!pagingParams.statusFilter ? [] : pagingParams.statusFilter}
              onFilter={() => {
                let param = { ...pagingParams, pageIndex: 1 };
                setPagingParams(param);
                loadProjects(param);
              }}
              onChainsFilterChanged={(chains) =>
                setPagingParams({ ...pagingParams, chainsFilter: [...chains] })
              }
              onSchoolsFilterChanged={(schools) =>
                setPagingParams({ ...pagingParams, schoolsFilter: [...schools] })
              }
              onStatusFilterChanged={(statuses) =>
                setPagingParams({ ...pagingParams, statusFilter: [...statuses] })
              }
              onPageChange={(pageNumber) => {
                let param = { ...pagingParams, pageIndex: pageNumber };
                setPagingParams(param);
                loadProjects(param);
              }}
            />
          </>
        )}
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={""} />}
    </>
  );
}
