import React, { useEffect, useState } from "react";
import agent from "../../../api/agent";
import { PowerBIEmbed } from "powerbi-client-react";
import ReportEmbedConfigDto from "../../../models/reports/reportEmbedConfigDto";
import { toast } from "react-toastify";
import { models, Report } from "powerbi-client";
import { useStore } from "../../../Stores/store";
import { useNavigate } from "react-router-dom";

interface Props {
  chainCode: string;
  chainSurveyId: number;
}

export default function ChainReportViewer({ chainCode, chainSurveyId }: Props) {
  const { userStore } = useStore();
  const navigate = useNavigate();
  const [reportConfig, setReportconfig] = useState<ReportEmbedConfigDto | undefined>(undefined);
  const [report, setReport] = useState<Report>();

  useEffect(() => {
    agent.Reports.embedChainReport(chainCode, chainSurveyId)
      .then((result) => {
        setReportconfig(result);
        setTimeout(() => {
          userStore.logout();
          navigate("/");
        }, 7200000);
        //report?.reload();
      })
      .catch((error) => {
        toast.error("Failed to load chain report. Please make sure the school chain has at least one project coordinator and try again.");
      });
  }, []);

  return (
    <>
      {reportConfig && (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: reportConfig.reportId, //report id
            embedUrl: reportConfig.embedUrl,
            accessToken: reportConfig.token,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed || Embed
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  if (event) console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={(embeddedReport) => {
            setReport(embeddedReport as Report);
          }}
        />
      )}
    </>
  );
}
