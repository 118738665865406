import React from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Checkbox,
  Icon,
  Pagination,
  Popup,
  Table,
  TableCell,
  TableHeader,
} from "semantic-ui-react";
import SchoolChainDto from "../../../models/schools/schoolChainDto";
import { gridPageSize } from "../../../models/constants";
import GridColumnFilterConrol from "../../../Common/gridColumnFilterConrol";
import LookupDto from "../../../models/common/lookupDto";

interface Props {
  chains: SchoolChainDto[];
  selectedChain: SchoolChainDto | null;
  onRenewChainLicense: (chain: SchoolChainDto) => void;
  totalItemCount: number;
  currentPage: number;
  onPageChange: (newPageNumber: number) => void;
  onChainSelected: (chain: SchoolChainDto | null) => void;
  onFilter: () => void;
  onLicenseFilterChanged: (selected: number[]) => void;
  selectedStatusFilter: number[];
}

export default function ChainsList({
  chains,
  selectedChain,
  onRenewChainLicense,
  totalItemCount,
  currentPage,
  onPageChange,
  onChainSelected,
  onFilter,
  onLicenseFilterChanged,
  selectedStatusFilter,
}: Props) {
  const filterItems: LookupDto[] = [{ text: "Renew", value: 1 }];
  const gridPaging = (
    <Pagination
      disabled={chains == null || totalItemCount <= gridPageSize}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={chains == null ? 0 : Math.ceil(totalItemCount / gridPageSize)}
      onPageChange={(e, d) => {
        if (d.activePage) onPageChange(+d.activePage);
      }}
    />
  );

  const list = (
    <>
      <Table className="t4-tbl t4-tbl-long">
        <TableHeader>
          <Table.Row>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Schools in chain</TableCell>
            <TableCell>Surveys per year</TableCell>
            <TableCell>Contract Start Date</TableCell>
            <TableCell>Contract End Date</TableCell>
            <TableCell>Benchmark data</TableCell>
            <TableCell>Previous customer</TableCell>
            <TableCell>
              Renew Now
              <GridColumnFilterConrol
                items={filterItems}
                onFilterChanged={(items: number[]) => {
                  onLicenseFilterChanged(items);
                }}
                onFilter={() => onFilter()}
                selectedItems={selectedStatusFilter}
              />
            </TableCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {chains &&
            chains.length > 0 &&
            chains.map((chain) => (
              <Table.Row key={chain.id}>
                <Table.Cell>
                  <Checkbox
                    checked={selectedChain != null && selectedChain.id === chain.id}
                    onClick={() => {
                      if (selectedChain && selectedChain.id === chain.id) {
                        onChainSelected(null);
                      } else {
                        onChainSelected(chain);
                      }
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <NavLink to={`/dashboard/chain/${chain.code}`}>{chain.name}</NavLink>
                </Table.Cell>
                <Table.Cell>
                  {chain.createdSchoolCount}/{chain.schoolsInChain}
                </Table.Cell>
                <Table.Cell>{chain.numberOfSurveysPerYear}</Table.Cell>
                {chain.contractStartDate === "-" ? (
                  <Table.Cell error>
                    <Icon name="attention" />
                    Not Licensed
                  </Table.Cell>
                ) : (
                  <Table.Cell>{chain.contractStartDate}</Table.Cell>
                )}
                {chain.contractEndDate === "-" ? (
                  <Table.Cell error>
                    <Icon name="attention" />
                    Not Licensed
                  </Table.Cell>
                ) : (
                  <Table.Cell>
                    {chain.contractExpired ? (
                      <>
                        <Popup trigger={<Icon name="attention" />} content="Expired" inverted />
                        {chain.contractEndDate}
                      </>
                    ) : (
                      chain.contractEndDate
                    )}
                  </Table.Cell>
                )}
                <Table.Cell>
                  {chain.benchmarkData ? (
                    <>
                      <Icon name="checkmark" />
                    </>
                  ) : (
                    <>
                      {" "}
                      <Icon name="close" />
                    </>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {chain.previousCustomer ? (
                    <>
                      <Icon name="checkmark" />
                    </>
                  ) : (
                    <>
                      {" "}
                      <Icon name="close" />
                    </>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {chain.renewAvailable && (
                    <Button
                      basic
                      className="t4-btn"
                      content="Renew"
                      onClick={() => onRenewChainLicense(chain)}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {chains != null && gridPaging}
    </>
  );
  return <>{list}</>;
}
