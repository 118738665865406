import React, { useState } from "react";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { Button, Modal } from "semantic-ui-react";

interface Props {
  chainCode: string | null;
  onClose: (refresh: boolean) => void;
}

export default function ConfirmDeleteChainPopup({ chainCode, onClose }: Props) {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const deleteChain = () => {
    if (!chainCode) return;
    setIsDeleting(true);
    agent.Chains.delete(chainCode)
      .then(() => {
        toast.success("School chain delete successfully");
        onClose(true);
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 422)
          toast.error("Chain cannot be delete. Please delete schools under the chain first");
        else toast.error("Failed to delete school chain");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };
  return (
    <>
      <>
        <Modal open={true}>
          <Modal.Header>Please Confirm</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Are you sure you want to delete the selected school chain?
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={isDeleting}
              onClick={() => {
                onClose(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="t4-btn-active"
              color="blue"
              content="Confirm"
              loading={isDeleting}
              onClick={() => {
                deleteChain();
              }}
            />
          </Modal.Actions>
        </Modal>
      </>
    </>
  );
}
