import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { Icon, Message } from "semantic-ui-react";

interface Props {
  schoolCode: string;
}

export default function InvalidEmailsWarning({ schoolCode }: Props) {
  const [invalidMailCount, setInvalidMailCount] = useState<number>(0);

  useEffect(() => {
    agent.Schools.invalidEmailInvitationsCount(schoolCode)
      .then((result) => setInvalidMailCount(result))
      .catch((error) => {
        //toast.error("Failed to check invalid emails");
      });
  }, [schoolCode]);

  const content = (
    <>
      <div id="divValidationMessages">
        <Message icon negative className="t4-error-msg">
          <Icon className="t4-email-error-icn" />
          <Message.Content>
            Invalid email: {invalidMailCount} email(s) have been rejected.
            <NavLink to={`/dashboard/failedInvitations/${schoolCode}`}>{" View details"}</NavLink>
          </Message.Content>
        </Message>
      </div>
    </>
  );

  return <>{invalidMailCount > 0 && content}</>;
}
