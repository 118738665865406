import React from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { Checkbox, Table } from "semantic-ui-react";
import { useStore } from "../../../Stores/store";

interface Props {
  questions: QuestionDto[] | null;
  onQuestionSelected: (question: QuestionDto | null) => void;
  selectedQuestion: QuestionDto | null;
  loading: boolean;
}

export default function SchoolQuestionsList({
  questions,
  onQuestionSelected,
  selectedQuestion,
  loading,
}: Props) {
  const { lookupStore } = useStore();

  const content = (
    <Table className="t4-tbl">
      <Table.Header>
        <Table.Row>
          <Table.Cell></Table.Cell>
          <Table.Cell>#</Table.Cell>
          <Table.Cell>Title</Table.Cell>
          <Table.Cell>Question Type</Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {questions != null &&
          questions.length > 0 &&
          questions.map((question) => (
            <Table.Row key={question.id}>
              <Table.Cell>
                <Checkbox
                  checked={selectedQuestion != null && selectedQuestion.id === question.id}
                  onClick={() => {
                    if (selectedQuestion != null && selectedQuestion.id === question.id)
                      onQuestionSelected(null);
                    else onQuestionSelected(question);
                  }}
                />
              </Table.Cell>
              <Table.Cell>{question.id}</Table.Cell>
              <Table.Cell>{question.title}</Table.Cell>
              <Table.Cell>{lookupStore.getQuestionType(question.questionType)}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );

  return <>{!loading && content}</>;
}
