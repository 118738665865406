import React, { useState } from "react";
import { Checkbox, Icon, Pagination, Table, TableCell, TableHeader } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { SchoolLicenseSummaryDto } from "../../../models/schools/schoolLicenseSummaryDto";
import { gridPageSize } from "../../../models/constants";

interface Props {
  schools: SchoolLicenseSummaryDto[] | null;
  totalItemCount: number;
  currentPage: number;
  onPageChange: (newPageNumber: number) => void;
  onSchoolSelected: (school: SchoolLicenseSummaryDto | null) => void;
}

export default function SchoolsList({
  schools,
  onSchoolSelected,
  totalItemCount,
  currentPage,
  onPageChange,
}: Props) {
  const [selectedSchool, setSelectedSchool] = useState<SchoolLicenseSummaryDto | null>(null);
  const gridPaging = (
    <Pagination
      disabled={schools == null || totalItemCount <= gridPageSize}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={schools == null ? 0 : Math.ceil(totalItemCount / gridPageSize)}
      onPageChange={(e, d) => {
        if (d.activePage) onPageChange(+d.activePage);
      }}
    />
  );

  const list = (
    <>
      <Table className="t4-tbl t4-tbl-long">
        <TableHeader>
          <Table.Row>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Surveys per year</TableCell>
            <TableCell>Contract Start Date</TableCell>
            <TableCell>Contract End Date</TableCell>
            <TableCell>Benchmark data</TableCell>
            <TableCell>Previous customer</TableCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {schools != null &&
            schools.length > 0 &&
            schools.map((school) => (
              <Table.Row key={school.id}>
                <Table.Cell>
                  <Checkbox
                    checked={selectedSchool != null && selectedSchool.id === school.id}
                    onClick={() => {
                      if (selectedSchool && selectedSchool.id === school.id) {
                        setSelectedSchool(null);
                        onSchoolSelected(null);
                      } else {
                        setSelectedSchool(school);
                        onSchoolSelected(school);
                      }
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <NavLink to={"/dashboard/schoolDetails/" + school.code}>{school.name}</NavLink>
                </Table.Cell>
                <Table.Cell>{school.consumedSurveys}</Table.Cell>
                <Table.Cell>{school.contractStartDate}</Table.Cell>
                <Table.Cell>{school.contractEndDate}</Table.Cell>
                <Table.Cell>{school.includeBenchmarkData ? <Icon name="checkmark" /> : <Icon name="close" />}</Table.Cell>
                <Table.Cell>{school.previousCustomer ? <Icon name="checkmark" /> : <Icon name="close" />}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {schools != null && gridPaging}
    </>
  );

  return <>{schools != null && list}</>;
}
