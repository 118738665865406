import React, { useEffect, useState } from "react";
import agent from "../../api/agent";
import { ImportStatuses } from "../../models/enums";
import { Modal, Progress } from "semantic-ui-react";
import ImportProgressDto from "../../models/schools/importProgressDto";

interface Props {
  logCode: string | null;
}

export default function ImportProgress({ logCode }: Props) {
  const [currentProgress, setCurrentProgress] = useState<ImportProgressDto | null>(null);

  useEffect(() => {
    setCurrentProgress({ progress: 0, totalItems: 0, status: ImportStatuses.Running });
    checkStatus();
  }, [logCode]);

  const checkStatus = () => {
    if (logCode == null) return;
    agent.Schools.checkImportStatus(logCode).then((result) => {
      setCurrentProgress(result);
      if (
        result.status !== ImportStatuses.Succes &&
        result.status !== ImportStatuses.Failed &&
        result.progress < result.totalItems
      )
        checkStatus();
    });
  };

  return (
    <>
      <Modal open={true}>
        <Modal.Header>Importing</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="t4-confirm-popup">
              {currentProgress && (
                <Progress
                  success
                  value={currentProgress.progress}
                  total={currentProgress.totalItems}
                  progress="ratio"
                />
              )}
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );
}
