import React, { useEffect, useState } from "react";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, Grid } from "semantic-ui-react";
import CustomTextInput from "../../../Common/customTextInput";
import UserCredenatialsDto from "../../../models/users/userCredenatialsDto";
import agent from "../../../api/agent";

interface Props {
  schoolCode: string;
  onClose: (refresh: boolean) => void;
  member: StaffMemberDto | null;
}

export default function ResetPasswordControl({ onClose, member, schoolCode }: Props) {
  const { t } = useTranslation("common");
  const [isSaving, setIsSaving] = useState(false);
  const [user, setUser] = useState<StaffMemberDto>({} as StaffMemberDto);
  const [credentials, setCredentials] = useState<UserCredenatialsDto>({} as UserCredenatialsDto);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    if (!member || member == null) {
      return;
    }
    setUser({ ...member });
    setCredentials({ userId: member.id, password: null, cofirmedPassword: null });
  }, [member]);

  const validationSchema = Yup.object({
    cofirmedPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    password: Yup.string().required("Required"),
  });

  const submitForm = (values: UserCredenatialsDto) => {
    if (!member || !values) return;
    setIsSaving(true);
    let updatedUser = { ...user, password: values.password };
    agent.Schools.resetCoordinatorPwd(updatedUser, schoolCode)
      .then(() => {
        onClose(false);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={credentials}
        onSubmit={(values) => submitForm(values)}
      >
        {({ handleSubmit }) => (
          <Form className="t4-reset-form" onSubmit={handleSubmit}>
            <CustomTextInput
              labeltext={"Password *"}
              placeholder={"Password"}
              name="password"
              disabled={false}
            />
            <CustomTextInput
              labeltext={"Confirm Password *"}
              placeholder={"Confirm Password"}
              name="cofirmedPassword"
              disabled={false}
            />
            <div className="t4-actions t4-popup-btns">
              <Button
                disabled={isSaving}
                onClick={() => {
                  onClose(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="blue"
                content="Reset"
                loading={isSaving}
                className="t4-btn-active"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
