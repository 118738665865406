import React from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { Input, Segment } from "semantic-ui-react";
import parse from "html-react-parser";
import { useStore } from "../../../Stores/store";

interface Props {
  question: QuestionDto;
  onAnswer: (value: number | null, response: string) => void;
}

export default function ViewNumericText({ question, onAnswer }: Props) {
  const { translationStore } = useStore();
  return (
    <>
      <Segment className={question.isAnswered === false ? "t4-input required" : "t4-input"}>
        <div>{parse(translationStore.questionBody(question))}</div>
        <Input
          className="t4-input-no"
          type="text"
          value={question.response ? question.response : ""}
          onChange={(e) => {
            const regex = /^[0-9\b]+$/;
            if (e.target.value === "0" || e.target.value === "" || regex.test(e.target.value))
              onAnswer(null, e.target.value);
          }}
        />
        {/* {question.isAnswered === false && <Message color={"red"}>Required</Message>}*/}
      </Segment>
    </>
  );
}
