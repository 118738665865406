import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";
import {
  Button,
  ButtonGroup,
  ButtonOr,
  Checkbox,
  Header,
  Icon,
  Label,
  Message,
  Modal,
  Radio,
  Rating,
  Segment,
  Step,
  StepContent,
  StepGroup,
  StepTitle,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "semantic-ui-react";
import agent from "../../../api/agent";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import { useStore } from "../../../Stores/store";
import AddEditSurvey from "./addEditSurvey";
import SchoolNameControl from "../schoolNameControl";
import SchoolDto from "../../../models/schools/schoolDto";
import { SurveyStatuses } from "../../../models/enums";
import { stat } from "fs";

export default function ManageSchoolSurveys() {
  let params = useParams();
  const navigate = useNavigate();
  const { lookupStore } = useStore();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const [waves, setWaves] = useState<SchoolSurveyDto[]>([]);
  const [selectedWave, setSelectedWave] = useState<SchoolSurveyDto | null>(null);
  const [openEditSurvey, setOpenEditSurvey] = useState<boolean>(false);
  const [editSurveyMode, setEditSurveyMode] = useState<boolean>(false);
  const [createdSurveysCount, setCreatedSurveysCount] = useState<number | null>(null);
  const [currentSchool, setCurrentSchool] = useState<SchoolDto | null>(null);
  const [activeSchoolSurvey, setActiveSchoolSurvey] = useState<SchoolSurveyDto | null>(null);
  const [saving, setSaving] = useState<boolean>(false);

  const handleError = () => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loadSurveys = () => {
    if (!params.schoolCode) return;

    setLoading(true);
    agent.Schools.surveys(params.schoolCode)
      .then((result) => {
        setWaves(result);
        if (result == null) setCreatedSurveysCount(0);
        else setCreatedSurveysCount(result.length);
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        setLoading(false);
      });

    agent.Schools.profile(params.schoolCode)
      .then((result) => {
        setCurrentSchool(result);
      })
      .catch(() => {
        toast.error("Faild to load school profile");
      });

    agent.Schools.activeSchoolsurvey(params.schoolCode)
      .then((result) => {
        setActiveSchoolSurvey(result);
      })
      .catch(() => {
        toast.error("Faild to load actvive school wave");
      });
  };

  const changeSurveyStatus = (schoolSurveyId: number, status: SurveyStatuses) => {
    if (status === SurveyStatuses.SurveyRunning && waves != null && waves.length > 0 && waves.filter((w) => w.status === SurveyStatuses.SurveyRunning).length > 0) {
      toast.error("Only one survey can be running at a time. Please close running surveys before running a new one");
      return;
    }

    if (!params.schoolCode) return;
    setSaving(true);
    agent.Schools.updateSurveyStatus(params.schoolCode, schoolSurveyId, status)
      .then(() => {
        loadSurveys();
      })
      .catch((error) => {
        if (error.response.status === 409) toast.error(error.response.data);
        else toast.error("Faild to change survey status");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    loadSurveys();
  }, []);

  const editSurvey = (
    <>
      <Modal open={openEditSurvey}>
        <Modal.Header>{editSurveyMode ? "Edit Wave" : "New Wave"}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {params.schoolCode && (
              <AddEditSurvey
                schoolCode={params.schoolCode}
                survey={selectedWave}
                surveyNumber={!waves ? 1 : waves.length + 1}
                onCancel={() => {
                  setOpenEditSurvey(false);
                }}
                onUpdate={() => {
                  setOpenEditSurvey(false);
                  loadSurveys();
                }}
              />
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const content = (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      {editSurvey}
      <Segment className="t4-mainmenu t4-header-align">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
              }}
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              Manage Waves
            </Header>
          </div>
        </div>
        <div className="t4-mng-btns">
          <Button
          icon="add"
            content={"New"}
            disabled={createdSurveysCount == null || currentSchool == null || createdSurveysCount >= currentSchool.numberOfSurveys || currentSchool.schoolChainId != null}
            onClick={() => {
              setEditSurveyMode(false);
              setOpenEditSurvey(true);
            }}
          ></Button>
          <Button
          icon="edit"
            disabled={selectedWave == null}
            content={"Edit"}
            onClick={() => {
              setEditSurveyMode(true);
              setOpenEditSurvey(true);
            }}
          ></Button>
          <div id="divSurveyCounters"> 
          <span className="t4-waves-counter">Waves: </span>
            <Label horizontal>
              {createdSurveysCount == null ? "-" : createdSurveysCount} / {currentSchool == null ? "-" : currentSchool.numberOfSurveys}
            </Label>
        </div>
        </div>
        <div id="divChainWarningMessage">
          {currentSchool != null && currentSchool.schoolChainId != null && (
            <Message color="yellow" content={"This school is part of a chain. Creating new waves is done on the chain level from the chain dashboard."} />
          )}
        </div>
          <Table className="t4-tbl">
            <TableHeader>
              <Table.Row>
                <TableCell></TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Started On</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </Table.Row>
            </TableHeader>
            <Table.Body>
              {waves &&
                waves.length > 0 &&
                waves.map((wave) => (
                  <Table.Row key={wave.id}>
                    <Table.HeaderCell>
                      <Checkbox
                        checked={selectedWave != null && selectedWave.id === wave.id}
                        onChange={(e) => {
                          if (selectedWave == null) setSelectedWave(wave);
                          else if (selectedWave.id === wave.id) setSelectedWave(null);
                          else if (selectedWave.id != wave.id) setSelectedWave(wave);
                        }}
                      />
                    </Table.HeaderCell>
                    <TableCell>{wave.id}</TableCell>
                    <TableCell>
                      {activeSchoolSurvey == null || activeSchoolSurvey.id != wave.id ? wave.name : ""}
                      {activeSchoolSurvey != null && activeSchoolSurvey.id === wave.id && <Label className="t4-wave-lbl">{wave.name}</Label>}
                    </TableCell>
                    <TableCell>{wave.createdOn}</TableCell>
                    <TableCell> {lookupStore.getSurveyStatus(wave.status)}</TableCell>
                    <TableCell>
                    <ButtonGroup>
                    {wave.status === SurveyStatuses.PendingBIApproval && (
                        <Button
                        className="t4-btn-combo t4-btn-combo-lft"
                        basic
                        color='blue'
                          disabled={saving}
                          onClick={() => {
                            changeSurveyStatus(wave.id, SurveyStatuses.SurveyRunning);
                          }}
                        >
                          Reopen
                        </Button>
                      )}
                      <ButtonOr className="t4-btn-mid" text='' />
                      {wave.status === SurveyStatuses.PendingBIApproval && (
                        <Button
                        className="t4-btn-combo t4-btn-combo-rt"
                        basic
                          color='blue'
                          disabled={saving}
                          onClick={() => {
                            changeSurveyStatus(wave.id, SurveyStatuses.BiLive);
                          }}
                        >
                          Publish
                        </Button>
                      )}
                      
                      </ButtonGroup>
                      {wave.status === SurveyStatuses.BiLive && (
                        <Button
                        className="t4-btn-combo"
                        basic
                        color='blue'
                          disabled={saving}
                          onClick={() => {
                            changeSurveyStatus(wave.id, SurveyStatuses.PendingBIApproval);
                          }}
                        >
                          Unpublish
                        </Button>
                      )}
                      {wave.status === SurveyStatuses.setup && (
                        <Button
                        className="t4-btn-combo"
                        basic
                        color='blue'
                          disabled={saving}
                          onClick={() => {
                            changeSurveyStatus(wave.id, SurveyStatuses.SurveyRunning);
                          }}
                        >
                          Run
                        </Button>
                      )}
                      {wave.status === SurveyStatuses.SurveyRunning && (
                        <Button
                        className="t4-btn-combo"
                        basic
                        color='blue'
                          disabled={saving}
                          onClick={() => {
                            changeSurveyStatus(wave.id, SurveyStatuses.PendingBIApproval);
                          }}
                        >
                          Close
                        </Button>
                      )}
                      
                    </TableCell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={"Loading Surveys"} />}
    </>
  );
}
