import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddEditTextQuestion from "../../Questions/Text/addEditTextQuestion";
import AddEditMcqDropDownList from "../../Questions/McqDropDownList/addEditMcqDropDownList";
import agent from "../../../api/agent";
import QuestionDto from "../../../models/surveys/questionDto";
import { toast } from "react-toastify";
import SchoolNameControl from "../schoolNameControl";
import { QuestionTypes } from "../../../models/enums";
import LoadingComponent from "../../Layout/loadingComponent";
import QuestionTranslationSelector from "../../Questions/QuestionManagement/questionTranslationSelector";
import LanguageDto from "../../../models/surveys/languageDto";

export default function AddEditSchoolQuestion() {
  let params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>();
  const [languages, setLanguages] = useState<LanguageDto[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [questionType, setQuestionType] = useState<QuestionTypes>();
  const [editQuestion, setEditQuestion] = useState<QuestionDto | null>(null);

  const loadQuestion = () => {
    if (!params.schoolCode || !params.questionCode) return;
    setLoading(true);
    agent.Questions.load(params.questionCode)
      .then((result) => {
        setEditQuestion(result);
        setQuestionType(result.questionType);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!params.schoolCode) return;

    agent.Schools.languages(params.schoolCode).then((result) => setLanguages(result));

    if (params.questionCode) {
      //edit question
      loadQuestion();
    } else if (params.questionType && params.questionType.toLowerCase() === "text")
      setQuestionType(QuestionTypes.Text);
    else if (params.questionType && params.questionType.toLowerCase() === "mcq")
      setQuestionType(QuestionTypes.McqDropDown);
  }, [params.questionCode]);

  const saveQuestion = (question: QuestionDto) => {
    setSaving(true);
    if (question.id === 0) addNewQuestion(question);
    else updateQuestion(question);
  };

  const addNewQuestion = (question: QuestionDto) => {
    if (!params.schoolCode) return;
    let newQuestion = {
      ...question,
    };

    agent.Questions.addSchoolQuestion(newQuestion, params.schoolCode)
      .then((result) => {
        navigate(`/dashboard/editchoolquestion/${params.schoolCode}/${result}?translation=1`);
      })
      .catch((error) => {
        toast.error("An error has occured while saving your question. Please try again.");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const updateQuestion = (question: QuestionDto) => {
    if (!params.schoolCode) return;
    let newQuestion = {
      ...question,
    };

    agent.Questions.updateSchoolQuestion(params.schoolCode, newQuestion)
      .then((result) => {
        navigate(`/dashboard/surveysettings/${params.schoolCode}`);
      })
      .catch((error) => {
        toast.error("An error has occured while saving your question. Please try again.");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const content = (
    <>
      <div className="t4-settings-edit-quest">
        <SchoolNameControl schoolCode={params.schoolCode} />
        {editQuestion != null && editQuestion.id != 0 && (
          <QuestionTranslationSelector
            languages={languages}
            question={editQuestion}
            onTranslationUpdated={() => {
              loadQuestion();
            }}
          />
        )}
        {questionType && questionType === QuestionTypes.Text && (
          <AddEditTextQuestion
            saving={saving}
            editQuestion={editQuestion}
            onCancel={() => {
              navigate(`/dashboard/surveysettings/${params.schoolCode}`);
            }}
            onSave={(question: QuestionDto) => {
              saveQuestion(question);
            }}
          />
        )}

        {questionType && questionType === QuestionTypes.McqDropDown && (
          <AddEditMcqDropDownList
            saving={saving}
            editQuestion={editQuestion}
            onCancel={() => {
              navigate(`/dashboard/surveysettings/${params.schoolCode}`);
            }}
            onSave={(question: QuestionDto) => {
              saveQuestion(question);
            }}
          />
        )}
      </div>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={""} />}
    </>
  );
}
