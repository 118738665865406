import React, { useEffect, useState } from "react";
import { Button, Input } from "semantic-ui-react";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

interface Props {
  survey: SchoolSurveyDto | null;
  surveyNumber: number;
  schoolCode: string;
  onCancel: () => void;
  onUpdate: () => void;
}

export default function AddEditSurvey({ surveyNumber, schoolCode, onCancel, onUpdate, survey }: Props) {
  const [isSaving, setIsSaving] = useState(false);
  const [surveyName, setSurveyName] = useState<string>(`Wave ${surveyNumber}`);

  useEffect(() => {
    if (survey != null) setSurveyName(survey.name);
  }, []);

  const save = () => {
    if (survey == null) createNewSurvey();
    else updateSurvey();
  };

  const createNewSurvey = () => {
    agent.Schools.newSurvey(schoolCode, surveyName)
      .then(() => {
        onUpdate();
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409) toast.error("Wave name already in use!");
        else if (error.response && error.response.status && error.response.status === 400) toast.error("You have reached the maximum number of surveys for that school");
        else toast.error("Failed to create wave !");
      });
  };

  const updateSurvey = () => {
    if (survey == null) return;
    agent.Schools.updateSurveyName(schoolCode, survey.id, surveyName)
      .then(() => {
        onUpdate();
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409) toast.error("Wave name already in use!");
        else toast.error("Failed to create wave !");
      });
  };

  return (
    <>
      <div>
        <label>Name: </label>
        <Input
          type="text"
          value={surveyName}
          onChange={(e) => {
            setSurveyName(e.target.value);
          }}
        />
      </div>
      <div className="t4-form-bot-btns">
        <Button
          floated="right"
          className="t4-btn-active"
          color="blue"
          content="Save"
          loading={isSaving}
          onClick={() => {
            save();
            onUpdate();
          }}
        />
        <Button
          className="t4-btn-non"
          floated="right"
          disabled={isSaving}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
