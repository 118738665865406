import React from "react";
import SurveyPageDto from "../../models/surveys/surveyPageDto";
import { Segment, Image } from "semantic-ui-react";
import { QuestionTypes } from "../../models/enums";
import parse from "html-react-parser";
import ViewMcqDropDownList from "../Questions/McqDropDownList/viewMcqDropDownList";
import ViewLikert from "../Questions/Likert/viewLikert";
import ViewText from "../Questions/Text/viewText";
import ViewMcqRadioList from "../Questions/McqRadioList/viewMcqRadioList";
import ViewNumericText from "../Questions/NumericText/viewNumericText";
import ViewMcqMultipleResponse from "../Questions/McqMultipleResponse/viewMcqMultipleResponse";
import ViewSalary from "../Questions/Salary/viewSalary";
import { useStore } from "../../Stores/store";
import { useTranslation } from "react-i18next";
import ViewRating from "../Questions/Rating/viewRating";

interface Props {
  page: SurveyPageDto;
  questionAnswerUpdated: (questionId: number, answer: number | null, response: string) => void;
  multiResponseQuestionAnswerUpdated: (questionId: number, answer: number[]) => void;
}

export default function PageViewer({
  page,
  questionAnswerUpdated,
  multiResponseQuestionAnswerUpdated,
}: Props) {
  const { t } = useTranslation("common");
  const { translationStore } = useStore();
  return (
    <>
      <div>{page.pageContent && <Segment>{parse(translationStore.pageContent(page))}</Segment>}</div>
      <div className="t4-questions">
        {page &&
          page.questions &&
          page.questions.length > 0 &&
          page.questions.map((q) => (
            <div key={q.id} className={"quest-type" + q.questionType.toString()}>
              {q.questionType === QuestionTypes.McqDropDown && (
                <ViewMcqDropDownList
                  question={q}
                  onAnswer={(value: number, responseText: string) => {
                    questionAnswerUpdated(q.id, value, responseText);
                  }}
                />
              )}
              {q.questionType === QuestionTypes.McqRadioList && (
                <ViewMcqRadioList
                  question={q}
                  onAnswer={(value: number, responseText: string) => {
                    questionAnswerUpdated(q.id, value, responseText);
                  }}
                />
              )}
              {q.questionType === QuestionTypes.Likert && (
                <div className="flip-phone-viewer">
                  <div className="flip-phone" style={{ display: "none" }}>
                    <div className="flip-text">{t("Common.MobileMessage")}</div>
                    <Image src="/assets/flip-phone.svg" size="medium" />
                  </div>

                  <ViewLikert
                    question={q}
                    onAnswer={(value: number, responseText: string) => {
                      questionAnswerUpdated(q.id, value, responseText);
                    }}
                  />
                </div>
              )}
              {q.questionType === QuestionTypes.Rating && (
                <ViewRating
                  question={q}
                  onAnswer={(value: number, responseText: string) => {
                    questionAnswerUpdated(q.id, value, responseText);
                  }}
                />
              )}
              {q.questionType === QuestionTypes.Text && (
                <ViewText
                  question={q}
                  onAnswer={(value: number | null, responseText: string) => {
                    questionAnswerUpdated(q.id, value, responseText);
                  }}
                />
              )}
              {q.questionType === QuestionTypes.NumericText && (
                <ViewNumericText
                  question={q}
                  onAnswer={(value: number | null, responseText: string) => {
                    questionAnswerUpdated(q.id, value, responseText);
                  }}
                />
              )}
              {q.questionType === QuestionTypes.McqMultipleResponse && (
                <ViewMcqMultipleResponse
                  question={q}
                  onAnswer={(value: number[]) => {
                    multiResponseQuestionAnswerUpdated(q.id, value);
                  }}
                />
              )}
              {q.questionType === QuestionTypes.Salary && (
                <ViewSalary
                  question={q}
                  onAnswer={(value: number, responseText: string) => {
                    questionAnswerUpdated(q.id, value, responseText);
                  }}
                />
              )}
            </div>
          ))}
      </div>
    </>
  );
}
