import React from "react";
import { useTranslation } from "react-i18next";
import { Image } from "semantic-ui-react";

export default function SurveyFinished() {
  const { t } = useTranslation("common");

  return (
    <>
      <div className="t4-survey-finish">
        <div>
        <Image src="/assets/t4-check-mark.svg" size="tiny" centered />
        </div>
        <h2>{t("Common.ThankYouMessage")}</h2>
        <p>{t("Common.ThankYouMessageLine1")}</p>
        <p>{t("Common.ThankYouMessageLine2")}</p>
        <p>{t("Common.ThankYouMessageLine3")}</p>
        </div>
    </>
  );
}
