import React, { useState } from "react";
import { Button, Dropdown, Flag, FlagNameValues, Input } from "semantic-ui-react";
import LanguageDto from "../../../models/surveys/languageDto";
import AddEditQuestionTranslation from "./addEditQuestionTranslation";
import QuestionDto from "../../../models/surveys/questionDto";

interface Props {
  languages: LanguageDto[];
  question: QuestionDto | null;
  onTranslationUpdated: () => void;
}

export default function QuestionTranslationSelector({
  languages,
  question,
  onTranslationUpdated,
}: Props) {
  const [filter, setFilter] = useState<string>("");
  const [translationModalOpen, setTranslationModalOpen] = useState<boolean>(false);
  const [editLanguage, setEditLanguage] = useState<LanguageDto>({} as LanguageDto);

  return (
    <>
      <Dropdown
        text="Edit Translations"
        icon={
          question &&
          languages &&
          question.translations &&
          question.translations.length === languages.length
            ? "filter"
            : "filter " + "warn-sign"
        }
        floating
        labeled
        button
        className="icon t4-trans-ddl"
      >
        <Dropdown.Menu>
          <Input
            value={filter}
            type="text"
            icon="search"
            iconPosition="left"
            className="search"
            onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
          <Dropdown.Divider />
          <Dropdown.Header content="Select a Language" />
          <Dropdown.Menu scrolling>
            {languages &&
              languages.length > 0 &&
              languages.map((language) => (
                <Dropdown.Item key={language.id}>
                  <Button
                    className="t4-lang-trans"
                    onClick={() => {
                      setEditLanguage(language);
                      setTranslationModalOpen(true);
                    }}
                    color={
                      question?.translations &&
                      question.translations.findIndex((t) => t.language === language.locale) > -1
                        ? "green"
                        : "grey"
                    }
                    icon={
                      question?.translations &&
                      question.translations.findIndex((t) => t.language === language.locale) > -1
                        ? "check"
                        : "close"
                    }
                  >
                    <i
                      className={
                        question?.translations &&
                        question.translations.findIndex((t) => t.language === language.locale) > -1
                          ? "check"
                          : "close"
                      }
                    />
                    <Flag className="t4-lang-stat" name={language.flagName as FlagNameValues} />
                    <span className="t4-lang-name">{language.name}</span>
                  </Button>
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
      <AddEditQuestionTranslation
        open={translationModalOpen}
        language={editLanguage}
        onSave={() => {
          onTranslationUpdated();
        }}
        questionId={question ? question.id : 0}
        setModalOpen={(open: boolean) => setTranslationModalOpen(open)}
      />
    </>
  );
}
