import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Header, Segment, Table } from "semantic-ui-react";
import NotificationDto from "../../../models/Settings/notificationDto";
import LoadingComponent from "../../Layout/loadingComponent";
import agent from "../../../api/agent";
import AddNotification from "./addNotification";
import ConfirmDeleteNotification from "./confirmDeleteNotification";
import { toast } from "react-toastify";

export default function MangeNotifications() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationDto[] | null>(null);
  const [selectedNotification, setSelectedNotification] = useState<NotificationDto | null>(null);
  const [showAddNotification, setShowAddNotification] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const loadNotifications = () => {
    setLoading(true);
    agent.Settings.notifications()
      .then((result) => setNotifications(result))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const selectNotification = (notification: NotificationDto) => {
    if (selectedNotification == null || !selectedNotification) {
      setSelectedNotification(notification);
      return;
    }

    if (selectedNotification && selectedNotification?.id === notification.id) {
      setSelectedNotification(null);
      return;
    }

    if (selectedNotification && selectedNotification?.id != notification.id) {
      setSelectedNotification(notification);
      return;
    }
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  const deleteNotification = () => {
    if (!selectedNotification) return;
    agent.Settings.deleteNotificationEmail(selectedNotification.userEmail)
      .then(() => {
        toast.success("Email Removed successfully");
        setShowConfirmDelete(false);
        setSelectedNotification(null);
        loadNotifications();
      })
      .catch(() => {
        toast.error("Failed to remove email");
      });
  };

  const content = (
    <Segment className="t4-mainmenu t4-header-align t4-nobanner-info">
      <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              Manage Notifications
            </Header>
          </div>
      </div>
      <div className="t4-mng-btns">
        <Button icon="add" content={"Add"} onClick={() => setShowAddNotification(true)}></Button>
        <Button icon="delete" disabled={selectedNotification == null} content={"Delete"} onClick={() => setShowConfirmDelete(true)}></Button>
      </div>
        <Table className="t4-tbl">
          <Table.Header>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell>Email</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {notifications &&
              notifications.length > 0 &&
              notifications.map((notification) => (
                <Table.Row key={notification.id}>
                  <Table.Cell>
                    <Checkbox
                      checked={selectedNotification != null && selectedNotification.id === notification.id}
                      onChange={(e) => {
                        selectNotification(notification);
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>{notification.userEmail}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
    </Segment>
  );

  return (
    <>
      {showAddNotification && (
        <AddNotification
          onCancel={() => setShowAddNotification(false)}
          onUpdate={() => {
            setShowAddNotification(false);
            loadNotifications();
          }}
        />
      )}

      {showConfirmDelete && <ConfirmDeleteNotification onCancel={() => setShowConfirmDelete(false)} onConfirm={() => deleteNotification()} />}
      {!loading && content}
      {loading && <LoadingComponent content={"Loading Notifications"} />}
    </>
  );
}
