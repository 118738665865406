import React, { useEffect, useState } from "react";
import { Grid, Segment } from "semantic-ui-react";
import agent from "../../../api/agent";
import { ChainInfoDto } from "../../../models/schools/chainInfoDto";

interface Props {
  chainCode: string;
}

export default function ChainNameControl({ chainCode }: Props) {
  const [chainInfo, setChainInfo] = useState<ChainInfoDto | null>(null);

  useEffect(() => {
    agent.Chains.info(chainCode).then((result) => {
      setChainInfo(result);
    });
  }, [chainCode]);

  return (
    <>
      <Grid className="t4-banner-info">
        <Grid.Row>
          <Grid.Column width={4}></Grid.Column>
          <Grid.Column width={8}>
            <h1 className="t4-school-name">{chainInfo == null ? "" : chainInfo.name}</h1>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <span>Licenses: </span>
              {chainInfo != null && (
                <span className="t4-banner-numbers">{`${chainInfo.createdSchools} / ${chainInfo.licensedSchools}`}</span>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
