import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Segment, Grid, Image } from "semantic-ui-react";

export default function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div className="t4-home">
        <Segment className="home-segment nostyle">
          <div className="t4-parallelogram"></div>
          <Grid columns={3} stackable textAlign="center">
            <Grid.Row>
              <Grid.Column width={8}>
                <Image
                  className="t4-login-logo"
                  src="/assets/BSTW-Logo Horizontal.png"
                  size="medium"
                  floated="left"
                />
                <Image
                  className="t4-login-img"
                  src="/assets/BSTW-decoupage.png"
                  size="huge"
                  floated="left"
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Segment className="t4-login-segment">
                  <div className="survey-name">
                    <p className="t4-survey-main-title">School Portal</p>
                  </div>
                  <p className="t4-txt">
                    Welcome to the Best School to Work portal. Here you will be able to manage
                    administration of the survey for your staff, review progress, and access your school
                    report and resources.
                  </p>
                  <Button
                    className="t4-btn-active"
                    content={"Login"}
                    onClick={() => {
                      navigate("/login");
                    }}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width={2}>
                <Image
                  className="t4-login-logo-bottom"
                  src="/assets/Logo_T4_Transparent.png"
                  size="medium"
                  floated="right"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    </>
  );
}
