import React, { useEffect, useState } from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { QuestionTypes } from "../../../models/enums";
import { Button, List, Message, Segment } from "semantic-ui-react";
import CustomTextAreaInput from "../../../Common/customTextAreaInput";
import { useSearchParams } from "react-router-dom";

interface Props {
  editQuestion: QuestionDto | null;
  onSave: (question: QuestionDto) => void;
  onCancel: () => void;
  saving: boolean;
}

export default function AddEditTextQuestion({ onSave, onCancel, editQuestion, saving }: Props) {
  const [question, setQuestion] = useState<QuestionDto>({} as QuestionDto);
  let [searchParams, setSearchParams] = useSearchParams();
  const[showStartTranslationMessage, setShowStartTranslationMessage]=useState<boolean>(false);

  const validationSchema = Yup.object({
    body: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (!editQuestion || editQuestion == null) {
      setQuestion({
        questionType: QuestionTypes.Text,
        questionOrder: 0,
        title: "New Question",
        body: "",
        choices: null,
        code: "",
        id: 0,
        foundationId: null,
        isAnswered: false,
      } as QuestionDto);
    } else{ 
      setQuestion({ ...editQuestion });
      const showTranslationMessage = searchParams.get('translation');
      if(showTranslationMessage)
        setShowStartTranslationMessage(true);
    }
  }, [editQuestion]);

  const submitForm = (values: QuestionDto) => {
    onSave({ ...values });
  };

  return (
    <>
      <Segment className="t4-mainmenu t4-settings-txtq">
      {showStartTranslationMessage && <Message className="t4-edit-trans-settings" content="Select ‘Edit Translations’ now to add the translated text"/>}
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={question}
          onSubmit={(values) => submitForm(values)}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <List className="t4-add-txt">
                <List.Item className="t4-add-q">
                  <CustomTextAreaInput
                    disabled={showStartTranslationMessage}
                    label={"Question *"}
                    placeholder={"Question"}
                    name="body"
                    rows={5}
                  />
                </List.Item>
              </List>
              <div className="t4-actions t4-save-btns">
                {!showStartTranslationMessage && <Button
                  floated="right"
                  type="submit"
                  loading={saving}
                  color="blue"
                  content="Save"
                  className="t4-btn-active"
                />}
                <Button
                  className="t4-btn-non"
                  floated="right"
                  disabled={saving}
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Close
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
}
