import React from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { Radio, Segment } from "semantic-ui-react";
import parse from "html-react-parser";
import { useStore } from "../../../Stores/store";

interface Props {
  question: QuestionDto;
  onAnswer: (value: number, response: string) => void;
}

export default function ViewMcqRadioList({ question, onAnswer }: Props) {
  const { translationStore } = useStore();
  return (
    <>
      <Segment className={question.isAnswered === false ? "t4-mcq required" : "t4-mcq"}>
        <div>{parse(translationStore.questionBody(question))}</div>
        <div className="t4-mcq-radio">
          {question.choices &&
            question.choices.length > 0 &&
            question.choices.map((choice) => (
              <Radio
                key={choice.id}
                className={choice.id.toString()}
                label={parse(translationStore.choiceBody(choice))}
                checked={question.userAnswer === choice.id}
                onChange={() => {
                  onAnswer(choice.id, choice.body);
                }}
              />
            ))}
        </div>
        {/*{question.isAnswered === false && <Message className="t4-error" color={"red"}>Required</Message>}*/}
      </Segment>
    </>
  );
}
