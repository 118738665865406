import React, { useState } from "react";
import { Button, Checkbox, Modal } from "semantic-ui-react";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

interface Props {
  schoolCode: string | undefined;
  schoolSurveyId: number | undefined;
  onClose: (refresh: boolean) => void;
}

export default function SendInvitationsConfirmation({ schoolCode, schoolSurveyId, onClose }: Props) {
  const [schoolDetailsConfirmed, setSchoolDetailsConfirmed] = useState<boolean>(false);
  const [staffMembersConfirmed, setStaffMembersConfirmed] = useState<boolean>(false);
  const [languageConfigured, setLanguageConfigured] = useState<boolean>(false);
  const [extraQuestionsConfigured, setExtraQuestionsConfigured] = useState<boolean>(false);
  const [inviting, setInviting] = useState<boolean>(false);
  const [invitationsSent, setInvitationsSent] = useState<boolean>(false);

  const inviteUser = () => {
    if (!schoolCode) return;
    setInviting(true);
    agent.Schools.inviteAllMembers(schoolCode, "en")
      .then((result) => {
        setInvitationsSent(true);
      })
      .catch((error) => {
        toast.error("Failed to send out all invitations, please try again.");
      })
      .finally(() => {
        setInviting(false);
      });
  };

  return (
    <>
      <Modal open={true}>
        <Modal.Header>Confirmation required</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {!inviting && !invitationsSent && (
              <div className="t4-confirm-popup">
                <Checkbox
                  className="t4-confirm-1"
                  onChange={(e, d) => {
                    if (d.checked === true) setSchoolDetailsConfirmed(true);
                    else setSchoolDetailsConfirmed(false);
                  }}
                  label={
                    "I can confirm the school details entered on the school details page of the portal are complete"
                  }
                ></Checkbox>
                <Checkbox
                className="t4-confirm-1"
                  onChange={(e, d) => {
                    if (d.checked === true) setStaffMembersConfirmed(true);
                    else setStaffMembersConfirmed(false);
                  }}
                  label={
                    "I can confirm that details of all of the teachers in our school are entered on the staff profile page of the portal"
                  }
                ></Checkbox>
                <Checkbox
                className="t4-confirm-1"
                  onChange={(e, d) => {
                    if (d.checked === true) setLanguageConfigured(true);
                    else setLanguageConfigured(false);
                  }}
                  label={"I can confirm that the language options have been configured in settings."}
                ></Checkbox>
                <Checkbox
                  onChange={(e, d) => {
                    if (d.checked === true) setExtraQuestionsConfigured(true);
                    else setExtraQuestionsConfigured(false);
                  }}
                  label={
                    "I can confirm that any additional survey questions needed, have been configured in settings."
                  }
                ></Checkbox>
              </div>
            )}
            {inviting && <p>Sending Invitations. Please wait for confirmation....</p>}
            {invitationsSent && (
              <div>
                <p>
                  All emails have been sent. Any rejected emails will be displayed in ‘Manage
                  Invitations’ within the next 10 minutes.
                </p>
              </div>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={inviting}
            onClick={() => {
              onClose(true);
            }}
          >
            Close
          </Button>
          {!invitationsSent && (
            <Button
              className="t4-btn t4-pop-btn"
              content="Confirm"
              loading={inviting}
              disabled={
                !schoolDetailsConfirmed ||
                !staffMembersConfirmed ||
                !languageConfigured ||
                !extraQuestionsConfigured
              }
              onClick={() => {
                inviteUser();
              }}
            />
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}
