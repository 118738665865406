import LookupDto from "./common/lookupDto";
import PillarScoringDto from "./scoring/pillarScoringDto";

export const systemLanguages = [
    { id: 1, flagName: "uk", locale: "en", name: "English" },
    { id: 2, flagName: "uae", locale: "ar", name: "العربية" },
    { id: 3, flagName: "es", locale: "es", name: "Español" },
    { id: 4, flagName: "se", locale: "se", name: "Svenska" },
    { id: 5, flagName: "pt", locale: "pt", name: "Português" },
];

export const gridPageSize: number = 10;

export const SchoolLocationsArray: LookupDto[] = [
    { value: 1, text: "City / urban / metropolitan" },
    { value: 2, text: "Town / semi dense / suburbs" },
    { value: 3, text: "Rural" }
];

export const SchoolTypesArray: LookupDto[] = [
    { value: 1, text: "Standard Government School" },
    { value: 2, text: "Government Faith School" },
    { value: 3, text: "Government With Selective Entrance" },
    { value: 4, text: "Government Alternative Structure" },
    { value: 5, text: "Private Independent" },
    { value: 6, text: "Private Independent Faith School" },
    { value: 7, text: "Charity School" }
];

export const SchoolAgeGroupsArray: LookupDto[] = [
    { value: 1, text: "Kindergarten" },
    { value: 2, text: "Primary" },
    { value: 3, text: "Secondary" }
];

export const SchoolGendersArray: LookupDto[] = [
    { value: 1, text: "Boys" },
    { value: 2, text: "Girls" },
    { value: 3, text: "Co-educational" }
];

export const DefaultScoringMatrix: PillarScoringDto[] = [
    {
        "pillarId": 1,
        "pillarName": "Leadership",
        "weight": 37,
        "foundations": [
            {
                "foundationId": 1,
                "foundationName": "Setting Vision and Goals",
                "weight": 6,
                "questions": [
                    {
                        "questionId": 7,
                        "foundationId": 1,
                        "questionBody": "The leadership team at my school have a clearly defined vision for the school.",
                        "stateSchoolWeight": 50,
                        "privateSchoolWeight": 50
                    },
                    {
                        "questionId": 8,
                        "foundationId": 1,
                        "questionBody": "The leadership team at my school have a clearly defined plan on how to achieve that vision.",
                        "stateSchoolWeight": 50,
                        "privateSchoolWeight": 50
                    }
                ]
            },
            {
                "foundationId": 2,
                "foundationName": "Developing Staff Engagement",
                "weight": 5,
                "questions": [
                    {
                        "questionId": 9,
                        "foundationId": 2,
                        "questionBody": "The school leadership team is effective in communicating that vision to staff, students and parents/carers.",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    },
                    {
                        "questionId": 10,
                        "foundationId": 2,
                        "questionBody": "The school leadership team is effective in ensuring all staff are engaged and feel like they are contributing to the vision.",
                        "stateSchoolWeight": 20,
                        "privateSchoolWeight": 20
                    },
                    {
                        "questionId": 14,
                        "foundationId": 2,
                        "questionBody": "The school leadership team is approachable and easy to communicate with.",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    },
                    {
                        "questionId": 17,
                        "foundationId": 2,
                        "questionBody": "The leadership team at my school always invite and listen to my constructive feedback.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 18,
                        "foundationId": 2,
                        "questionBody": "There is an \"open door\" attitude where all school leaders are always willing to listen to new ideas.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    }
                ]
            },
            {
                "foundationId": 3,
                "foundationName": "Modelling Positive Behaviours and Developing Trust",
                "weight": 5,
                "questions": [
                    {
                        "questionId": 11,
                        "foundationId": 3,
                        "questionBody": "The leadership team at my school consistently model positive behaviours in their daily interactions with teachers, students and parents/carers.",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    },
                    {
                        "questionId": 12,
                        "foundationId": 3,
                        "questionBody": "A high level of trust exists between school leadership and staff. ",
                        "stateSchoolWeight": 40,
                        "privateSchoolWeight": 40
                    },
                    {
                        "questionId": 13,
                        "foundationId": 3,
                        "questionBody": "School leaders support and trust their staff to teach in the way that is best for their students. ",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    }
                ]
            },
            {
                "foundationId": 4,
                "foundationName": "Supportive Environment",
                "weight": 5,
                "questions": [
                    {
                        "questionId": 15,
                        "foundationId": 4,
                        "questionBody": "When I face challenges in my role, my school leaders are very supportive.",
                        "stateSchoolWeight": 35,
                        "privateSchoolWeight": 35
                    },
                    {
                        "questionId": 16,
                        "foundationId": 4,
                        "questionBody": "The leadership team at my school show an interest in me as an individual, not just as an employee.",
                        "stateSchoolWeight": 35,
                        "privateSchoolWeight": 35
                    },
                    {
                        "questionId": 19,
                        "foundationId": 4,
                        "questionBody": "Overall, the leaders at my school support me to become a better teacher for my students.",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    }
                ]
            },
            {
                "foundationId": 5,
                "foundationName": "Feedback and Coaching",
                "weight": 5,
                "questions": [
                    {
                        "questionId": 32,
                        "foundationId": 5,
                        "questionBody": "How knowledgeable are the school leaders of your classroom practices and activities? ",
                        "stateSchoolWeight": 20,
                        "privateSchoolWeight": 20
                    },
                    {
                        "questionId": 33,
                        "foundationId": 5,
                        "questionBody": "How frequently is your work assessed by school leadership?",
                        "stateSchoolWeight": 20,
                        "privateSchoolWeight": 20
                    },
                    {
                        "questionId": 34,
                        "foundationId": 5,
                        "questionBody": "How effective and fair is that assessment process?",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    },
                    {
                        "questionId": 35,
                        "foundationId": 5,
                        "questionBody": "How impactful is the assessment and feedback process in improving your skills and abilities? ",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    }
                ]
            },
            {
                "foundationId": 6,
                "foundationName": "Professional Development",
                "weight": 5,
                "questions": [
                    {
                        "questionId": 48,
                        "foundationId": 6,
                        "questionBody": "Overall, how supportive has the school been of your professional development? ",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    },
                    {
                        "questionId": 49,
                        "foundationId": 6,
                        "questionBody": "Overall, how impactful is the formal professional development that you undertake at your school (organised by the school)? ",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    },
                    {
                        "questionId": 50,
                        "foundationId": 6,
                        "questionBody": "Overall, how relevant are the topics of your professional development with regard to your requirements? ",
                        "stateSchoolWeight": 20,
                        "privateSchoolWeight": 20
                    },
                    {
                        "questionId": 51,
                        "foundationId": 6,
                        "questionBody": "How much influence do you have in choosing your professional development opportunities?",
                        "stateSchoolWeight": 20,
                        "privateSchoolWeight": 20
                    }
                ]
            },
            {
                "foundationId": 7,
                "foundationName": "Innovation",
                "weight": 6,
                "questions": [
                    {
                        "questionId": 29,
                        "foundationId": 7,
                        "questionBody": "We are encouraged to experiment with new ideas even if they sometimes result in failure.",
                        "stateSchoolWeight": 45,
                        "privateSchoolWeight": 45
                    },
                    {
                        "questionId": 30,
                        "foundationId": 7,
                        "questionBody": "We are encouraged to join professional learning communities.",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    },
                    {
                        "questionId": 31,
                        "foundationId": 7,
                        "questionBody": "We are encouraged to use the latest pedagogical research in our practice.",
                        "stateSchoolWeight": 40,
                        "privateSchoolWeight": 40
                    }
                ]
            }
        ]
    },
    {
        "pillarId": 2,
        "pillarName": "Collaboration",
        "weight": 13,
        "foundations": [
            {
                "foundationId": 8,
                "foundationName": "Staff Collaboration",
                "weight": 8,
                "questions": [
                    {
                        "questionId": 20,
                        "foundationId": 8,
                        "questionBody": "There are many opportunities to professionally collaborate with colleagues in my school.",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    },
                    {
                        "questionId": 21,
                        "foundationId": 8,
                        "questionBody": "Teachers are actively encouraged to share good practice in my school.",
                        "stateSchoolWeight": 20,
                        "privateSchoolWeight": 20
                    },
                    {
                        "questionId": 22,
                        "foundationId": 8,
                        "questionBody": "I have learnt a lot from a variety of colleagues since working at this school.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 23,
                        "foundationId": 8,
                        "questionBody": "When I face challenges at work I can rely on my colleagues for support.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 24,
                        "foundationId": 8,
                        "questionBody": "The school facilitates opportunities for professional collaboration among colleagues.",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    }
                ]
            },
            {
                "foundationId": 9,
                "foundationName": "Community Collaboration",
                "weight": 5,
                "questions": [
                    {
                        "questionId": 25,
                        "foundationId": 9,
                        "questionBody": "The school actively encourages parental/carer engagement in their students learning.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 26,
                        "foundationId": 9,
                        "questionBody": "The school has strong links with the local community.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 27,
                        "foundationId": 9,
                        "questionBody": "The school is proactive in undertaking activities that develop relationships with the local community.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 28,
                        "foundationId": 9,
                        "questionBody": "The school is proactive in developing opportunities for students specifically to engage with the local community.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    }
                ]
            }
        ]
    },
    {
        "pillarId": 3,
        "pillarName": "School Environment",
        "weight": 31,
        "foundations": [
            {
                "foundationId": 10,
                "foundationName": "Positive Working Environment",
                "weight": 10,
                "questions": [
                    {
                        "questionId": 40,
                        "foundationId": 10,
                        "questionBody": "My school provides an environment in which it is easy to make friends with colleagues.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 41,
                        "foundationId": 10,
                        "questionBody": "Teachers/staff are treated with respect by students.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 42,
                        "foundationId": 10,
                        "questionBody": "Teachers and staff respect each other.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 43,
                        "foundationId": 10,
                        "questionBody": "Teachers/staff are treated with respect by parents/carers.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    }
                ]
            },
            {
                "foundationId": 11,
                "foundationName": "Inclusivity",
                "weight": 5,
                "questions": [
                    {
                        "questionId": 44,
                        "foundationId": 11,
                        "questionBody": "Overall, the school develops tailored strategies to support student motivation and behaviour.",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    },
                    {
                        "questionId": 45,
                        "foundationId": 11,
                        "questionBody": "The school is inclusive in its approach to all staff.",
                        "stateSchoolWeight": 40,
                        "privateSchoolWeight": 40
                    },
                    {
                        "questionId": 46,
                        "foundationId": 11,
                        "questionBody": "The school is inclusive in its approach to all students.",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    }
                ]
            },
            {
                "foundationId": 12,
                "foundationName": "Staff Belonging",
                "weight": 11,
                "questions": [
                    {
                        "questionId": 36,
                        "foundationId": 12,
                        "questionBody": "Are you proud to tell others you work at your school?",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 37,
                        "foundationId": 12,
                        "questionBody": "Do you enjoy working at your school? ",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 38,
                        "foundationId": 12,
                        "questionBody": "How would you describe the environment in your staff room? ",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    },
                    {
                        "questionId": 39,
                        "foundationId": 12,
                        "questionBody": "On a scale of 1-10, how likely would you be to recommend your school if a friend or family member wanted to join as a member of staff? (1 = not at all likely, 10 extremely likely). \r\n",
                        "stateSchoolWeight": 35,
                        "privateSchoolWeight": 35
                    }
                ]
            },
            {
                "foundationId": 13,
                "foundationName": "Resources",
                "weight": 5,
                "questions": [
                    {
                        "questionId": 67,
                        "foundationId": 13,
                        "questionBody": "To what extent are your school facilities fit for purpose? ",
                        "stateSchoolWeight": 50,
                        "privateSchoolWeight": 50
                    },
                    {
                        "questionId": 68,
                        "foundationId": 13,
                        "questionBody": "To what extent do you feel you are provided with adequate resources to do your job effectively? ",
                        "stateSchoolWeight": 50,
                        "privateSchoolWeight": 50
                    }
                ]
            }
        ]
    },
    {
        "pillarId": 4,
        "pillarName": "Professional Wellness",
        "weight": 19,
        "foundations": [
            {
                "foundationId": 14,
                "foundationName": "Staff Wellbeing",
                "weight": 12,
                "questions": [
                    {
                        "questionId": 62,
                        "foundationId": 14,
                        "questionBody": "This is a safe place to work.",
                        "stateSchoolWeight": 10,
                        "privateSchoolWeight": 10
                    },
                    {
                        "questionId": 63,
                        "foundationId": 14,
                        "questionBody": "This is an emotionally healthy place to work.",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    },
                    {
                        "questionId": 64,
                        "foundationId": 14,
                        "questionBody": "We are encouraged to maintain a healthy work/life balance by school leadership.",
                        "stateSchoolWeight": 10,
                        "privateSchoolWeight": 10
                    },
                    {
                        "questionId": 65,
                        "foundationId": 14,
                        "questionBody": "School leaders genuinely care about staff wellbeing and workload.",
                        "stateSchoolWeight": 25,
                        "privateSchoolWeight": 25
                    },
                    {
                        "questionId": 73,
                        "foundationId": 14,
                        "questionBody": "School leaders proactively do their best to manage staff workloads",
                        "stateSchoolWeight": 20,
                        "privateSchoolWeight": 20
                    },
                    {
                        "questionId": 74,
                        "foundationId": 14,
                        "questionBody": "School leaders allocate workload fairly amongst staff",
                        "stateSchoolWeight": 10,
                        "privateSchoolWeight": 10
                    },
                    {
                        "questionId": 75,
                        "foundationId": 14,
                        "questionBody": "School leaders are considerate of workload when allocating tasks",
                        "stateSchoolWeight": 10,
                        "privateSchoolWeight": 10
                    }
                ]
            },
            {
                "foundationId": 15,
                "foundationName": "Contracts and Remuneration",
                "weight": 7,
                "questions": [
                    {
                        "questionId": 55,
                        "foundationId": 15,
                        "questionBody": "Overall how satisfied are you with your remuneration package? ",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 10
                    },
                    {
                        "questionId": 56,
                        "foundationId": 15,
                        "questionBody": "Overall how well do you feel your basic wage compares to comparable roles in other schools in your area?",
                        "stateSchoolWeight": null,
                        "privateSchoolWeight": 10
                    },
                    {
                        "questionId": 57,
                        "foundationId": 15,
                        "questionBody": "Overall how do you feel the benefits package at your school compares to comparable roles in other schools? ",
                        "stateSchoolWeight": null,
                        "privateSchoolWeight": 10
                    },
                    {
                        "questionId": 58,
                        "foundationId": 15,
                        "questionBody": "My contract of employment is robust and gives me confidence in my employment security.",
                        "stateSchoolWeight": 30,
                        "privateSchoolWeight": 30
                    },
                    {
                        "questionId": 59,
                        "foundationId": 15,
                        "questionBody": "Policies and expectations regarding staff behaviour are fully documented. ",
                        "stateSchoolWeight": 10,
                        "privateSchoolWeight": 10
                    },
                    {
                        "questionId": 60,
                        "foundationId": 15,
                        "questionBody": "My school has clearly documented policies and processes in the case of grievances. ",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    },
                    {
                        "questionId": 61,
                        "foundationId": 15,
                        "questionBody": "If I had a grievance, the grievance procedure would be a fair one.",
                        "stateSchoolWeight": 15,
                        "privateSchoolWeight": 15
                    }
                ]
            }
        ]
    }
];
