import React, { useEffect, useState } from "react";
import { Button, Dropdown, Header, Icon, Input, Modal, Segment } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import ChainsList from "./chainsList";
import agent from "../../../api/agent";
import SchoolChainDto from "../../../models/schools/schoolChainDto";
import { toast } from "react-toastify";
import { PaginatedResult } from "../../../models/common/pagination";
import { gridPageSize } from "../../../models/constants";
import RenewLicense from "./renewLicense";
import LoadingComponent from "../../Layout/loadingComponent";
import ConfirmDeleteChainPopup from "./confirmDeleteChainPopup";
import ChainsPagingParamsDto from "../../../models/chains/chainsPagingParamsDto";
import { SortBy } from "../../../models/enums";

export default function ManageChains() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [chains, setChains] = useState<SchoolChainDto[]>([]);
  const [selectedChain, setSelectedChain] = useState<SchoolChainDto | null>(null);
  const [openRenewLicense, setOpenRenewLicense] = useState<boolean>(false);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [pagingParams, setPagingParams] = useState<ChainsPagingParamsDto>({
    pageNumber: 1,
    pageSize: gridPageSize,
    filter: "",
    licenseStatusFilter: [],
    orderBy: SortBy.NameAsc,
  });

  const loadChains = (params: ChainsPagingParamsDto) => {
    setLoading(true);
    agent.Chains.list(params)
      .then((response) => {
        let result = response as unknown as PaginatedResult<SchoolChainDto[]>;
        setTotalItemCount(result.pagination.totalItems);
        setChains(result.data);
      })
      .catch(() => {
        toast.error("Failed to load school chains");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchChains = (filter: string) => {
    let params: ChainsPagingParamsDto = { ...pagingParams, filter: filter, pageNumber: 1 };
    setPagingParams(params);
    loadChains(params);
  };

  useEffect(() => {
    loadChains(pagingParams);
  }, []);

  const renewLicensePopup = (
    <>
      {showDeleteConfirmation && (
        <ConfirmDeleteChainPopup
          chainCode={selectedChain == null ? null : selectedChain.code}
          onClose={(refresh: boolean) => {
            setShowDeleteConfirmation(false);
            if (refresh) {
              setSelectedChain(null);
              let params: ChainsPagingParamsDto = {
                filter: "",
                orderBy: null,
                pageNumber: 1,
                pageSize: gridPageSize,
                licenseStatusFilter: [],
              };
              setPagingParams(params);
              loadChains(params);
            }
          }}
        />
      )}
      <Modal open={openRenewLicense}>
        <Modal.Header>Renew School Chain License</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <RenewLicense
              chainCode={selectedChain == null ? null : selectedChain.code}
              onCancel={() => setOpenRenewLicense(false)}
              onUpdate={() => {
                setOpenRenewLicense(false);
                loadChains(pagingParams);
              }}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const content = (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-header-collection">
          <Header as="h1" className="t4-header-left" textAlign="left">
            Manage Chains
          </Header>
        </div>
        <div className="t4-mng-btns">
          <Button
            icon="add"
            content={"Add New"}
            onClick={() => {
              navigate("/dashboard/newchain");
            }}
          ></Button>
          <Button
            icon="edit"
            content={"Edit"}
            disabled={selectedChain == null}
            onClick={() => {
              if (selectedChain == null) return;
              navigate(`/dashboard/editchain/${selectedChain.code}`);
            }}
          ></Button>
          <Button
            icon="delete"
            content={"Delete"}
            disabled={selectedChain == null}
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          ></Button>
          <Input
            onChange={(e) => searchChains(e.target.value)}
            value={pagingParams.filter}
            autoComplete="new-password"
            placeholder={"Search..."}
            icon={
              <Icon
                name="delete"
                link
                onClick={() => {
                  searchChains("");
                }}
              />
            }
          />
        </div>

        {chains && chains.length > 0 && (
          <>
          <span className="t4-sortby"><Icon name="sort amount down"/>
            {"Sort by : "}</span>
            <Dropdown className="t4-sorted"
              value={pagingParams.orderBy as number}
              options={[
                { text: "Name Ascending", value: SortBy.NameAsc },
                { text: "Name Descending", value: SortBy.NameDsc },
                { text: "Contract Start Date Ascending", value: SortBy.ContractStartDateAsc },
                { text: "Contract Start Date Descending", value: SortBy.ContractStartDateDsc },
                { text: "Contract End Date Ascending", value: SortBy.ContractEndDateAsc },
                { text: "Contract End Date Descending", value: SortBy.ContractEndDateDsc },
              ]}
              onChange={(e, d) => {
                setLoading(true);
                let params = {
                  ...pagingParams,
                  pageNumber: 1,
                  pageSize: gridPageSize,
                  orderBy: d.value as SortBy,
                };
                setPagingParams(params);
                loadChains(params);
              }}
            />
            <ChainsList
              chains={chains}
              selectedChain={selectedChain}
              onRenewChainLicense={(chain: SchoolChainDto) => {
                setSelectedChain(chain);
                setOpenRenewLicense(true);
              }}
              totalItemCount={totalItemCount}
              currentPage={pagingParams.pageNumber}
              onPageChange={(pageNumber) => {
                let param = { ...pagingParams, pageNumber: pageNumber };
                setPagingParams(param);
                loadChains(param);
              }}
              onChainSelected={(chain) => setSelectedChain(chain)}
              onFilter={() => {
                loadChains(pagingParams);
              }}
              onLicenseFilterChanged={(items) => {
                let params = { ...pagingParams, licenseStatusFilter: [...items] };
                setPagingParams(params);
              }}
              selectedStatusFilter={pagingParams.licenseStatusFilter ?? []}
            />
          </>
        )}
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={""} />}
      {renewLicensePopup}
    </>
  );
}
