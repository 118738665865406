import React, { useEffect, useState } from "react";
import agent from "../../../api/agent";
import PillarScoringDto from "../../../models/scoring/pillarScoringDto";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button, Container, Segment, Table, TableCell, TableHeader } from "semantic-ui-react";
import LoadingComponent from "../../Layout/loadingComponent";
import { useNavigate } from "react-router-dom";
import { DefaultScoringMatrix } from "../../../models/constants";

export default function ViewScoringMatrix() {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [scoring, setScoring] = useState<PillarScoringDto[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    agent.Questions.scoring()
      .then((result) => {
        setScoring(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const content = (
    <>
      <ul className="t4-scoring-wrapper">
        {scoring &&
          scoring.map((pillar) => (
            <li key={pillar.pillarId}>
              <h2>
                {pillar.pillarName} - {pillar.weight}
              </h2>
              <ul className="t4-pillars">
                {pillar.foundations &&
                  pillar.foundations.map((foundation) => (
                    <li className="t4-foundation" key={foundation.foundationId}>
                      <div className="t4-foundation-header">
                      <h3>
                        {foundation.foundationName} - {foundation.weight}
                      </h3>
                      <Button
                        icon="edit"
                        content={"Edit Questions' Scores"}
                        onClick={() => {
                          navigate(`/dashboard/editQuestionsWeightings/${foundation.foundationId}`);
                        }}
                      ></Button>
                      </div>
                      <Table className="t4-tbl">
                        <TableHeader>
                          <TableCell>Question</TableCell>
                          <TableCell>State School Weight</TableCell>
                          <TableCell>Private School Weight</TableCell>
                        </TableHeader>
                        <Table.Body>
                          {foundation.questions &&
                            foundation.questions.map((question) => (
                              <Table.Row key={question.questionId}>
                                <TableCell>{question.questionBody}</TableCell>
                                <TableCell>{question.stateSchoolWeight}</TableCell>
                                <TableCell>{question.privateSchoolWeight}</TableCell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </li>
                  ))}
              </ul>
            </li>
          ))}
      </ul>
    </>
  );

  return (
    <>
      <Container className="t4-survey-wrapper">
        <Segment>
          <h1>Scoring Matrix</h1>
          <div className="t4-form-btns t4-sticky-btns">
            <Button
              icon="edit"
              content={"Edit Pillars and Foundations"}
              onClick={() => {
                navigate(`/dashboard/editpillarsweightings`);
              }}
            ></Button>
          </div>
          {loading && <LoadingComponent content={""} />}
          {!loading && content}
        </Segment>
      </Container>
    </>
  );
}
