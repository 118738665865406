import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

interface Props {
  content: any;
}

export default function LoadingComponent({ content }: Props) {
  return (
    <>
    <Segment className="t4-loader">
      <Dimmer active inverted>
      <Loader className="loaderr t4-loading">{content}</Loader>
      </Dimmer>
    </Segment>
   
    </>
  );
}
