export enum QuestionTypes {
  McqDropDown = 1,
  Likert = 2,
  Rating = 3,
  Text = 4,
  NumericText = 5,
  Salary = 6,
  McqRadioList = 7,
  McqMultipleResponse = 8,
}

export enum SchoolAgeGroups {
  Kindergarten = 1,
  Primary = 2,
  Secondary = 3,
  PreKindergarten = 4,
}

export enum SchoolLocations {
  City_Urban_Metropolitan = 1,
  Town_SemiDense_Suburbs = 2,
  Rural = 3,
}

export enum SchoolTypes {
  StandardGovernmentSchool = 1,
  GovernmentFaithSchool = 2,
  GovernmentWithSelectiveEntrance = 3,
  GovernmentAlternativeStructure = 4,
  PrivateIndependent = 5,
  PrivateIndependentFaithSchool = 6,
  CharitySchool = 7,
}

export enum SchoolGenders {
  Boys = 1,
  Girls = 2,
  Coeducational = 3,
}

export enum LicenseTypes {
  School = 1,
  Chain = 2,
}

export enum SurveyStatuses {
  setup = 1,
  SurveyRunning = 2,
  PendingBIApproval = 3,
  BiLive = 4,
}

export enum DocumentTypes {
  Word = 1,
  Pdf = 2,
}

export enum ImportStatuses {
  Started = 1,
  Running = 2,
  Succes = 3,
  Failed = 4,
}

export enum StudentBoardingFacility {
  Yes = 1,
  No = 2,
}

export enum SortBy {
  NameAsc = 1,
  NameDsc = 2,
  ContractStartDateAsc = 3,
  ContractStartDateDsc = 4,
  ContractEndDateAsc = 5,
  ContractEndDateDsc = 6,
  LastLoginAsc = 7,
  LastLoginDsc = 8,
  InviteDateAsc = 9,
  InviteDateDesc = 10,
}

export enum ReportStatuses {
  Unpublished = 0,
  Published = 1,
}
