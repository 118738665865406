import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import UserStore from "./userStore";
import TranslationStore from "./translationStore";
import LookupStore from "./lookupStore";

export const store = {
    //schoolStore: new SchoolStore(),
    userStore: new UserStore(),
    commonStore: new CommonStore(),
    modalStore: new ModalStore(),
    translationStore: new TranslationStore(),
    lookupStore: new LookupStore()
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}