import React, { useEffect, useState } from "react";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import QuestionDto from "../../../models/surveys/questionDto";
import { Segment, Container } from "semantic-ui-react";
import QuestionList from "./questionList";
import { useStore } from "../../../Stores/store";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../Layout/loadingComponent";

export default function ManageQuestions() {
  const { t } = useTranslation("common");
  const { userStore } = useStore();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<QuestionDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    if (!userStore.isLoggedIn) {
      userStore.logout();
      navigate("/");
      return;
    }
    agent.Questions.list()
      .then((result) => {
        setQuestions(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const content = (
    <Segment>{questions && questions.length > 0 && <QuestionList questions={questions} />}</Segment>
  );

  return (
    <>
      <Container className="t4-survey-wrapper">
        <Segment>
          Manage Questions
          {loading && <LoadingComponent content={""} />}
          {!loading && content}
        </Segment>
      </Container>
    </>
  );
}
