import React, { useEffect, useState } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomTextInput from "../../../Common/customTextInput";
import agent from "../../../api/agent";

interface Props {
  schoolCode: string;
  onClose: (refresh: boolean) => void;
  member: StaffMemberDto | null;
}

export default function EditStaffEmail({ schoolCode, onClose, member }: Props) {
  const [isSaving, setIsSaving] = useState(false);
  const [user, setUser] = useState<StaffMemberDto>({
    email: "",
    firstName: "",
    lastName: "",
  } as StaffMemberDto);

  useEffect(() => {
    if (!member || member == null) return;

    //edit values
    setUser({ ...member });
  }, [member]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .typeError("Invalid Email Address")
      .required("Required")
      .email("Invalid Email Address"),
  });

  const updateUser = (values: StaffMemberDto) => {
    setIsSaving(true);
    agent.Schools.updateMemberAndInvite(values, schoolCode)
      .then((result) => {
        toast.success("Staff profile updated successfully");
        onClose(true);
      })
      .catch((error) => {
        toast.error("Failed to update staff email");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const submitForm = (values: StaffMemberDto) => {
    updateUser(values);
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={user}
        onSubmit={(values) => submitForm(values)}
      >
        {({ handleSubmit, validateForm, values }) => (
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <CustomTextInput
                    labeltext={"First Name *"}
                    placeholder={"First Name"}
                    name="firstName"
                    disabled={true}
                  />
                  <CustomTextInput
                    labeltext={"Last Name *"}
                    placeholder={"Last Name"}
                    name="lastName"
                    disabled={true}
                  />
                  <CustomTextInput
                    labeltext="Email Address *"
                    placeholder="Email Address"
                    name="email"
                    disabled={false}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="t4-actions t4-popup-btns">
              <Button
                disabled={isSaving}
                onClick={() => {
                  onClose(true);
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                color="blue"
                content="Save & Invite"
                loading={isSaving}
                className="t4-btn-active"
                onClick={(e) => {
                  validateForm().then((errors) => {
                    if (errors.firstName == null && errors.lastName == null && errors.email == null) {
                      setIsSaving(true);
                    }
                  });
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
