import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Icon, Segment, Select, Image, Label } from "semantic-ui-react";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import agent from "../../../api/agent";
import { SurveyStatuses } from "../../../models/enums";
import { toast } from "react-toastify";
import SchoolReportViewer from "./schoolReportViewer";
import { useStore } from "../../../Stores/store";
import LoadingComponent from "../../Layout/loadingComponent";
import ReportPublishSatus from "./reportPublishSatus";

export default function SchoolReports() {
  let params = useParams();
  const { userStore } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSurvey, setSelectedSurvey] = useState<SchoolSurveyDto>();
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    if (!params.schoolCode) return;

    setLoading(true);

    userStore.getUser().then((result) => {
      if (!params.schoolCode || !params.surveyCode) return;
      agent.Schools.survey(params.schoolCode, params.surveyCode)
        .then((result) => {
          setSelectedSurvey(result);
        })
        .catch((error) => {
          toast.error("Failed to load surveys");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [params.schoolCode]);

  const downloadSchoolData = () => {
    if (!selectedSurvey || !params.schoolCode) return;
    setDownloading(true);
    agent.Schools.downloadSurveyResponses(params.schoolCode, selectedSurvey.id).finally(() => {
      setDownloading(false);
    });
  };

  const content = (
    <Segment className="t4-mainmenu t4-reports">
      <div className="t4-reports-actiosn">
        <div className="t4-report-status">{params.schoolCode && selectedSurvey && <ReportPublishSatus schoolCode={params.schoolCode} survey={selectedSurvey} />}</div>

        <div className="">
          {/* T4 Admin can download report anytime */}
          {userStore.isSystemAdmin && selectedSurvey && selectedSurvey != null && params.schoolCode && (
            <Button
              className="t4-btn t4-create t4-report-dl"
              floated="right"
              basic
              loading={downloading}
              onClick={() => {
                downloadSchoolData();
              }}
            >
              Download survey data <Icon name="download" />
            </Button>
          )}

          {/* School Admin and Chain admin download report when published */}
          {!userStore.isSystemAdmin && selectedSurvey && selectedSurvey != null && params.schoolCode && selectedSurvey.status === SurveyStatuses.BiLive && (
            <Button
              className="t4-btn t4-create t4-report-dl"
              floated="right"
              basic
              loading={downloading}
              onClick={() => {
                downloadSchoolData();
              }}
            >
              Download survey data <Icon name="download" />
            </Button>
          )}
        </div>
        <div className="t4-action-ddl">{selectedSurvey && <Label className="t4-select-ddl" content={selectedSurvey.name} />}</div>
      </div>
      {/* School Admin and Chain admin see a message when report is not published */}
      {!userStore.isSystemAdmin && selectedSurvey && selectedSurvey != null && selectedSurvey.status !== SurveyStatuses.BiLive && (
        <Segment className="t4-reports-msg">
          <Image src="/assets/t4-reports-msg.svg" size="small" centered />
          <h3 className="t4-error-msg">When your report has been approved it will be published here.</h3>
        </Segment>
      )}

      {/* School Admin and Chain admin see report when published */}
      {!userStore.isSystemAdmin && selectedSurvey && selectedSurvey != null && params.schoolCode && selectedSurvey.status === SurveyStatuses.BiLive && (
        <SchoolReportViewer schoolSurveyId={selectedSurvey?.id} schoolCode={params.schoolCode} />
      )}

      {/* T4 Admin can see report anytime */}
      {userStore.isSystemAdmin && selectedSurvey && selectedSurvey != null && params.schoolCode && (
        <SchoolReportViewer schoolSurveyId={selectedSurvey?.id} schoolCode={params.schoolCode} />
      )}
    </Segment>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={""} />}
    </>
  );
}
