import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Stores/store";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";
import { Button, Card, Segment } from "semantic-ui-react";
import SurveyReportSummary from "./surveyReportSummary";
import SchoolNameControl from "../schoolNameControl";
import { SurveyStatuses } from "../../../models/enums";

export default function SchoolSurveysReports() {
  let params = useParams();
  const navigate = useNavigate();
  const { userStore } = useStore();
  const [surveys, setSurveys] = useState<SchoolSurveyDto[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!params.schoolCode) return;

    setLoading(true);

    userStore.getUser().then((result) => {
      if (!params.schoolCode) return;
      agent.Schools.surveys(params.schoolCode)
        .then((result) => {
          setSurveys(result);
        })
        .catch((error) => {
          toast.error("Failed to load surveys");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [params.schoolCode]);

  const content = (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      <Segment className="t4-mainmenu t4-header-align">
        <div className="t4-headers">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
            }}
          ></Button>
          <h1>Reports</h1>
        </div>
        <div className="t4-spacing">
          <Card.Group>
            {surveys &&
              surveys.length > 0 &&
              surveys.map((survey) => (
                <div key={survey.id}>
                  {userStore.isSystemAdmin && <SurveyReportSummary key={survey.id} survey={survey} schoolCode={params.schoolCode ? params.schoolCode : ""} />}
                  {!userStore.isSystemAdmin && survey.status === SurveyStatuses.BiLive && (
                    <SurveyReportSummary key={survey.id} survey={survey} schoolCode={params.schoolCode ? params.schoolCode : ""} />
                  )}
                </div>
              ))}
          </Card.Group>
        </div>
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={""} />}
    </>
  );
}
