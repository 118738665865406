import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../Stores/store";
import { Button, Dropdown, Flag, FlagNameValues } from "semantic-ui-react";
import agent from "../../api/agent";
import LanguageDto from "../../models/surveys/languageDto";

export default function LanguageSwitcher() {
  const { translationStore, commonStore } = useStore();
  const { i18n } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentLanguageFlag, setCurrentLanguageFlag] = useState<FlagNameValues>("uk");
  const [languages, setLanguages] = useState<LanguageDto[]>([]);

  useEffect(() => {
    if (translationStore.currentInvitationCode == null) {
      agent.Translations.languages().then((result) => setLanguages(result));
    } else {
      //load school languages only
      agent.Invitations.languages(translationStore.currentInvitationCode).then((result) => {
        setLanguages(result);
      });

      //Load school default language
      agent.Invitations.defaultLanguage(translationStore.currentInvitationCode).then((result) => {
        if (result && result != null) {
          setCurrentLanguageFlag(result.flagName as FlagNameValues);
          changeLanguage(result.locale);
        }
      });
    }

    let currentLanguage = languages.find((l) => l.locale === translationStore.lang);
    if (currentLanguage) setCurrentLanguageFlag(currentLanguage.flagName as FlagNameValues);
  }, [translationStore.currentInvitationCode]);

  const changeLanguage = (language: string) => {
    if (commonStore.currentInvitationCode) {
      setLoading(true);
      agent.Translations.surveyTranslations(commonStore.currentInvitationCode, language)
        .then((result) => {
          translationStore.setSurveyTranslation(language, result);
          translationStore.setLanguage(language);
          let currentLanguage = languages.find((l) => l.locale === language);
          if (currentLanguage) setCurrentLanguageFlag(currentLanguage.flagName as FlagNameValues);
          i18n.changeLanguage(language);
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      translationStore.setLanguage(language);
      let currentLanguage = languages.find((l) => l.locale === language);
      if (currentLanguage) setCurrentLanguageFlag(currentLanguage.flagName as FlagNameValues);
      i18n.changeLanguage(language);
    }
  };

  return (
    <>
      <div className="top-nav-lang-ddl">
        <Dropdown
          loading={loading}
          className="language-btn-dd"
          button
          floating
          labeled
          icon={<Flag className="langflag" name={currentLanguageFlag} />}
          text="Languages"
        >
          <Dropdown.Menu className="lang-dd">
            {languages &&
              languages.length > 0 &&
              languages.map((language) => (
                <Dropdown.Item
                  key={language.id}
                  onClick={() => {
                    changeLanguage(language.locale);
                  }}
                >
                  <Button>
                    <Flag name={language.flagName as FlagNameValues} /> {language.name}
                  </Button>
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
