import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SchoolDto from "../../../models/schools/schoolDto";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import agent from "../../../api/agent";
import { Formik, FormikErrors } from "formik";
import { Button, Form, Segment, Message, Icon } from "semantic-ui-react";
import CustomTextInput from "../../../Common/customTextInput";
import LoadingComponent from "../../Layout/loadingComponent";
import CustomSelectInput from "../../../Common/customSelectInput";
import LookupDto from "../../../models/common/lookupDto";
import {
  SchoolAgeGroupsArray,
  SchoolGendersArray,
  SchoolLocationsArray,
  SchoolTypesArray,
} from "../../../models/constants";
import CustomMultiSelectInput from "../../../Common/customMultiSelectInput";
import SchoolNameControl from "../schoolNameControl";
import { SchoolTypes } from "../../../models/enums";

export default function AddEditSchool() {
  let params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [isSaving, setIsSaving] = useState(false);
  const [schoolType, setSchoolType] = useState<SchoolTypes | null>(null);
  const [school, setSchool] = useState<SchoolDto>({} as SchoolDto);
  const [loading, setLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<LookupDto[]>([]);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    if (!params.schoolCode || params.schoolCode == null) return;

    agent.Lookups.countries()
      .then((result) => {
        setCountries(result);
      })
      .catch((error) => {
        toast.error("Failed to load countries");
      });

    agent.Schools.profile(params.schoolCode)
      .then((result) => {
        setSchool(result);
        setSchoolType(result.schoolType);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const privateSchoolValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    approximateStudentCount: Yup.number().typeError("Invalid numeric value").required("Required"),
    schoolType: Yup.number().min(1).required("Required"),
    countryId: Yup.number().min(1).required("Required"),
    studentBoardingFacilities: Yup.number().required("Required"),
    averageAnnualStudentFees: Yup.number().typeError("Invalid numeric value").required("Required"),
    nearestTown: Yup.string().required("Required"),
    nonTeachingStaffCount: Yup.number().typeError("Invalid numeric value").required("Required"),
    teachingStaffCount: Yup.number().typeError("Invalid numeric value").required("Required"),
    staffCount: Yup.number().typeError("Invalid numeric value").required("Required"),
    location: Yup.number().min(1).required("Required"),
    genders: Yup.number().min(1, "Required").required("Required"),
    ageGroups: Yup.array().min(1, "Required").required("Required"),
  });

  const governmentSchoolValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    countryId: Yup.number().min(1).required("Required"),
    approximateStudentCount: Yup.number().typeError("Invalid numeric value").required("Required"),
    schoolType: Yup.number().min(1).required("Required"),
    nearestTown: Yup.string().required("Required"),
    nonTeachingStaffCount: Yup.number().typeError("Invalid numeric value").required("Required"),
    teachingStaffCount: Yup.number().typeError("Invalid numeric value").required("Required"),
    staffCount: Yup.number().typeError("Invalid numeric value").required("Required"),
    location: Yup.number().min(1).required("Required"),
    genders: Yup.number().min(1, "Required").required("Required"),
    ageGroups: Yup.array().min(1, "Required").required("Required"),
  });

  const updateSchool = (values: SchoolDto) => {
    if (!params.schoolCode) return;

    if (
      values.schoolType !== SchoolTypes.PrivateIndependent &&
      values.schoolType !== SchoolTypes.PrivateIndependentFaithSchool
    )
      values.averageAnnualStudentFees = null;

    if (!values.averageAnnualStudentFees) values.averageAnnualStudentFees = null;

    agent.Schools.updateProfile(params.schoolCode, values)
      .then((result) => {
        toast.success("School updated successfully");
        navigate(`/dashboard/schoolprofile/${params.schoolCode}`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const submitForm = (values: SchoolDto) => {
    setIsSaving(true);
    setSchool(values);
    updateSchool(values);
  };

  const content = (
    <Formik
      validationSchema={
        schoolType === SchoolTypes.PrivateIndependent ||
        schoolType === SchoolTypes.PrivateIndependentFaithSchool
          ? privateSchoolValidationSchema
          : governmentSchoolValidationSchema
      }
      enableReinitialize
      initialValues={school}
      onSubmit={(values) => submitForm(values)}
    >
      {({ handleSubmit, values, validateForm }) => (
        <Form className="t4-editschool" onSubmit={handleSubmit}>
          <div className="t4-btn-page-content">
            <div className="t4-headers">
              <h2>Edit Profile Information</h2>
            </div>
            <div className="t4-edit-data">
              <div className="t4-school-info-section">
                <h3 className="t4-school-info-sub">Profile Information</h3>
                <CustomTextInput
                  labeltext={"Full School Name *"}
                  placeholder={"Full School Name"}
                  name="name"
                  disabled={false}
                />

                <CustomSelectInput
                  label={"Country"}
                  name="countryId"
                  disabled={false}
                  placeholder="Country"
                  options={countries}
                />

                <CustomTextInput
                  labeltext={"Nearest Town/City *"}
                  placeholder={"Nearest Town/City"}
                  name="nearestTown"
                  disabled={false}
                />

                <CustomSelectInput
                  label={"Location *"}
                  name="location"
                  disabled={false}
                  placeholder="Location"
                  options={SchoolLocationsArray}
                />

                <CustomSelectInput
                  label={"School Type *"}
                  name="schoolType"
                  disabled={false}
                  placeholder="School Type"
                  options={SchoolTypesArray}
                  onChange={(item) => setSchoolType(item)}
                />
              </div>
              <div className="t4-school-info-section">
                <h3 className="t4-school-info-sub">School Details</h3>
                <CustomTextInput
                  labeltext={"Approximately how many students attend your school? *"}
                  placeholder={""}
                  name="approximateStudentCount"
                  disabled={false}
                />

                <CustomMultiSelectInput
                  label={"What age groups does your school cater for (Please select all that apply)? *"}
                  name="ageGroups"
                  placeholder=""
                  options={SchoolAgeGroupsArray}
                />

                <CustomSelectInput
                  label={"What gender does your school cater for  *"}
                  name="genders"
                  disabled={false}
                  placeholder=""
                  options={SchoolGendersArray}
                />

                <CustomTextInput
                  labeltext={
                    "How many staff are employed at your school (both teachers and other administrative/support staff)?  *"
                  }
                  placeholder={""}
                  name="staffCount"
                  disabled={false}
                />

                <CustomTextInput
                  labeltext={"How many Teaching staff are employed at your school? *"}
                  placeholder={""}
                  name="teachingStaffCount"
                  disabled={false}
                />

                {(values.schoolType === SchoolTypes.PrivateIndependent ||
                  values.schoolType === SchoolTypes.PrivateIndependentFaithSchool) && (
                  <>
                    {/*<CustomCheckboxInput
                    label={"Does your school offer boarding facilities to students? "}
                    name="studentBoardingFacilities"
                    disabled={false}
                    type="checkbox"
                  />*/}
                    <CustomSelectInput
                      name="studentBoardingFacilities"
                      label={"Does your school offer boarding facilities to students? "}
                      disabled={false}
                      placeholder=""
                      options={[
                        { value: 2, text: "No" },
                        { value: 1, text: "Yes" },
                      ]}
                    />
                  </>
                )}

                {(values.schoolType === SchoolTypes.PrivateIndependent ||
                  values.schoolType === SchoolTypes.PrivateIndependentFaithSchool) && (
                  <CustomTextInput
                    labeltext={
                      "What are your average annual fees per student in local currency (for day attendance only, if boarding is offered)?"
                    }
                    placeholder={""}
                    name="averageAnnualStudentFees"
                    disabled={false}
                  />
                )}

                <CustomTextInput
                  labeltext={
                    "How many non teaching staff (i.e. administrative/support staff)are employed at your school (both full time or part time)? *"
                  }
                  placeholder={""}
                  name="nonTeachingStaffCount"
                  disabled={false}
                />

                <CustomTextInput
                  labeltext={"School Website (if available)"}
                  placeholder={""}
                  name="schoolWebsite"
                  disabled={false}
                />

                {values.isMemberOfSchoolChain && (
                  <>
                    <CustomTextInput
                      labeltext={"What is the name of the parent group?"}
                      placeholder={""}
                      name="chainName"
                      disabled={true}
                    />
                    <CustomTextInput
                      labeltext={"Parent group website (if available)"}
                      placeholder={""}
                      name="chainWebsite"
                      disabled={true}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="t4-form-bot-btns">
              {showErrorMessage && (
                <div id="divValidationMessages">
                  <Message icon negative className="t4-error-msg small">
                    <Icon name="exclamation triangle" />
                    <Message.Content>
                      Warning: An entry is required. Please fill in and try again.
                    </Message.Content>
                  </Message>
                </div>
              )}

              <Button
                floated="right"
                type="submit"
                color="blue"
                content="Save"
                loading={isSaving}
                className="t4-btn-active"
                onClick={() => {
                  setShowErrorMessage(false);
                  validateForm().then((errors: FormikErrors<SchoolDto>) => {
                    if (
                      errors.name != null ||
                      errors.approximateStudentCount != null ||
                      errors.schoolType != null ||
                      errors.averageAnnualStudentFees != null ||
                      errors.nearestTown != null ||
                      errors.nonTeachingStaffCount != null ||
                      errors.teachingStaffCount != null ||
                      errors.staffCount != null ||
                      errors.genders != null ||
                      errors.ageGroups != null ||
                      errors.countryId != null
                    )
                      setShowErrorMessage(true);
                  });
                }}
              />
              <Button
                className="t4-btn-non"
                floated="right"
                disabled={isSaving}
                onClick={() => {
                  navigate(`/dashboard/schoolprofile/${params.schoolCode}`);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      <Segment className="t4-mainmenu t4-btn-pages">
        {!loading && content}
        {loading && <LoadingComponent content={""} />}
      </Segment>
    </>
  );
}
