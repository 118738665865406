import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { SchoolLicenseSummaryDto } from "../../../models/schools/schoolLicenseSummaryDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import ChainSchoolDto from "../../../models/schools/chainSchoolDto";

interface Props {
  school: SchoolLicenseSummaryDto | ChainSchoolDto | null;
  onClose: (refresh: boolean) => void;
}

export default function ConfirmDeleteSchool({ school, onClose }: Props) {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const deleteSchool = () => {
    if (!school || !school.code) return;
    setIsDeleting(true);
    agent.Schools.delete(school?.code)
      .then(() => {
        toast.success("School delete successfully");
        onClose(true);
      })
      .catch(() => {
        toast.error("Failed to delete school");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <>
      <Modal open={true}>
        <Modal.Header>Please Confirm</Modal.Header>
        <Modal.Content>
          <Modal.Description>Are you sure you want to delete the selected School?</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={isDeleting}
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="t4-btn-active"
            color="blue"
            content="Confirm"
            loading={isDeleting}
            onClick={() => {
              deleteSchool();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}
