import React, { useEffect, useState } from "react";
import { Header, Loader, Table } from "semantic-ui-react";
import LoadingComponent from "../../Layout/loadingComponent";
import SchoolChainStatsDto from "../../../models/chains/schoolChainStatsDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

interface Props {
  chainCode: string;
}

export default function ChainSchoolsStats({ chainCode }: Props) {
  const [stats, setStats] = useState<SchoolChainStatsDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadStats = () => {
    setLoading(true);
    agent.Chains.schoolsStats(chainCode)
      .then((result) => setStats(result))
      .catch((error) => toast.error("Failed to load schools stats"))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadStats();
  }, [chainCode]);

  const content = (
    <Table.Body>
      {stats &&
        stats != null &&
        stats.length > 0 &&
        stats.map((school) => (
          <Table.Row key={school.schoolId}>
            <Table.Cell>
              <NavLink to={"/dashboard/schoolDetails/" + school.schoolCode}>{school.schoolName}</NavLink>
            </Table.Cell>
            <Table.Cell>
              {school.sentInvitations}/{school.totalStaff}
            </Table.Cell>
            <Table.Cell>
              {school.filledInvitations}/{school.totalInvitations}
            </Table.Cell>
            <Table.Cell>{school.responsePercentage}%/70%</Table.Cell>
          </Table.Row>
        ))}
    </Table.Body>
  );

  return (
    <>
      <Header as="h1" floated="left">
        Dashboard
      </Header>
      <Table className="t4-tbl t4-tbl-dash">
        <Table.Header>
          <Table.Row>
            <Table.Cell>School Name</Table.Cell>
            <Table.Cell>Sent / Staff</Table.Cell>
            <Table.Cell>Responses / Invitations</Table.Cell>
            <Table.Cell>Responses / Min. requirement</Table.Cell>
          </Table.Row>
        </Table.Header>
        {!loading && content}
      </Table>
      {loading && <LoadingComponent content={""} />}
    </>
  );
}
