import { useField } from "formik";
import React from "react";
import { Form } from "semantic-ui-react";

interface Props {
  name: string;
  type: string;
  label?: string;
  disabled: boolean;
}

export default function CustomCheckboxInput(props: Props) {
  const [field, meta, helpers] = useField({ name: props.name, type: "checkbox" });
  return (
    <>
      <Form.Field error={meta.touched && !!meta.error}>
        <label>
          <input {...field} type="checkbox" disabled={props.disabled} />
          <span className="text-sm">{props.label}</span>
        </label>
      </Form.Field>
    </>
  );
}
