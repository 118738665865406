import React, { useEffect, useState } from "react";
import { useStore } from "../../../Stores/store";
import LoadingComponent from "../../Layout/loadingComponent";
import { Segment, Image, Radio } from "semantic-ui-react";
import ChainReportViewer from "./chainReportViewer";
import { useNavigate, useParams } from "react-router-dom";
import chainReportPublishInfoDto from "../../../models/chains/chainReportPublishInfoDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import ChainReportPublishSatus from "./chainReportPublishSatus";
import ChainSchoolsPublishStatus from "./chainSchoolsPublishStatus";
import SchoolChainDto from "../../../models/schools/schoolChainDto";
import SchoolChainSurveyDto from "../../../models/chains/schoolChainSurveyDto";
import { ReportStatuses } from "../../../models/enums";

export default function ChainSurveyReport() {
  let params = useParams();
  const { userStore } = useStore();
  const [survey, setSurvey] = useState<SchoolChainSurveyDto | null>(null);
  //const [chainInfo, setChainInfo] = useState<chainReportPublishInfoDto>();
  const [unPublishedSchoolsReportsCount, setUnPublishedSchoolsReportsCount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    userStore
      .getUser()
      .then((result) => {
        loadSurveyInfo();
        //loadChainInfo();
      })
      .catch((error) => {
        navigate(`/`);
      });
  }, []);

  /*const loadChainInfo = () => {
    if (!params.chainCode) return;
    agent.Chains.reportPublishInfo(params.chainCode)
      .then((result) => {
        //setChainInfo(result);
        //loadSchoolsReportsInfo();
      })
      .catch((error) => toast.error("Failed to load chain info"))
      .finally(() => setLoading(false));
  };

  const loadSchoolsReportsInfo = () => {
    if (!params.chainCode) return;
    agent.Chains.unPublishedSchoolReportsCount(params.chainCode)
      .then((result) => {
        setUnPublishedSchoolsReportsCount(result);
      })
      .catch((error) => toast.error("Failed to load schools' reports info"))
      .finally(() => setLoading(false));
  };*/

  const loadSurveyInfo = () => {
    if (!params.chainCode || !params.surveyCode) return;
    agent.Chains.surveyDetails(params.chainCode, params.surveyCode)
      .then((result) => {
        setSurvey(result);
      })
      .catch((error) => toast.error("Failed to surey info"))
      .finally(() => setLoading(false));
  };

  const toggleChainReportPublishStatus = () => {
    if (!survey || !params.surveyCode || !params.chainCode) return;
    let status = survey.reportStatus == ReportStatuses.Unpublished ? ReportStatuses.Published : ReportStatuses.Unpublished;
    setSaving(true);
    agent.Chains.updateChainReportPublishStatus(params.chainCode, params.surveyCode, status)
      .then(() => {
        toast.success("Status updated successfully");
        loadSurveyInfo();
      })
      .catch(() => {
        toast.error("Failed to udpate status");
      })
      .finally(() => setSaving(false));
  };

  const content = (
    <>
      <Segment className="t4-mainmenu t4-reports">
        <div className="t4-reports-actiosn">
          <div className="t4-report-status">
            {survey && survey.name}
            {userStore.isSystemAdmin && (
              <Radio
                toggle
                label={"Chain Report Published"}
                disabled={survey == null || saving}
                checked={survey != null && survey.reportStatus == ReportStatuses.Published}
                onChange={() => {
                  toggleChainReportPublishStatus();
                }}
              />
            )}
            {/*params.chainCode && chainInfo && (
              <>
                {chainInfo && chainInfo.runningSchoolSurveysCount === 0 && (
                  <ChainSchoolsPublishStatus chainCode={params.chainCode} upPublishedSchoolReportCount={unPublishedSchoolsReportsCount} />
                )}
                <ChainReportPublishSatus chainCode={params.chainCode} chainInfo={chainInfo} />
              </>
            )*/}
          </div>

          <div className="t4-action-ddl"></div>
        </div>

        {/* Chain admin see a message when report is not published */}
        {/*!userStore.isSystemAdmin && chainInfo && chainInfo != null && !chainInfo.reportPublished && (
          <Segment className="t4-reports-msg">
            <Image src="/assets/t4-reports-msg.svg" size="small" centered />
            <h3 className="t4-error-msg">When your report has been approved it will be published here.</h3>
          </Segment>
        )*/}

        {/* School Admin and Chain admin see report when published */}
        {userStore.isChainAdmin && params.chainCode && survey && survey.reportStatus === ReportStatuses.Published && (
          <ChainReportViewer chainCode={params.chainCode} chainSurveyId={survey.id} />
        )}

        {/* T4 Admin can see report anytime */}
        {userStore.isSystemAdmin && params.chainCode && survey && <ChainReportViewer chainCode={params.chainCode} chainSurveyId={survey.id} />}
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={""} />}
    </>
  );
}
