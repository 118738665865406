import React, { useEffect, useState } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomTextInput from "../../../Common/customTextInput";
import agent from "../../../api/agent";

interface Props {
  schoolCode: string;
  onClose: (refresh: boolean) => void;
  member: StaffMemberDto | null;
}

export default function AddEditStaffProfile({ schoolCode, onClose, member }: Props) {
  const { t } = useTranslation("common");
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingNext, setIsSavingNext] = useState(false);
  const [closeAterSave, setCloseAfterSave] = useState<boolean>(true);
  const [user, setUser] = useState<StaffMemberDto>({
    email: "",
    firstName: "",
    lastName: "",
  } as StaffMemberDto);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    if (!member || member == null) {
      setUser({
        email: "",
        firstName: "",
        lastName: "",
      } as StaffMemberDto);
      return;
    }

    //edit values
    setUser({ ...member });
  }, [member]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
      .typeError("Invalid Email Address")
      .required("Required")
      .email("Invalid Email Address"),
  });

  const addNewUser = (values: StaffMemberDto) => {
    agent.Schools.addMember(values, schoolCode)
      .then((result) => {
        toast.success("Staff profile added successfully");
        setUser({} as StaffMemberDto);
        if (closeAterSave) onClose(true);
        /// Adds a new user and then reset the page to allow adding another user
        else {
          values.email = "";
          values.firstName = "";
          values.lastName = "";
        }
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error("User email already in use!");
        else handleError(error);
      })
      .finally(() => {
        setIsSaving(false);
        setIsSavingNext(false);
      });
  };

  const updateUser = (values: StaffMemberDto) => {
    setIsSaving(true);
    agent.Schools.updateMember(values, schoolCode)
      .then((result) => {
        toast.success("Staff profile updated successfully");
        onClose(true);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsSaving(false);
        setIsSavingNext(false);
      });
  };

  const submitForm = (values: StaffMemberDto) => {
    //setUser(values);
    if (!member || member.id === 0) addNewUser(values);
    else updateUser(values);
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={user}
        onSubmit={(values) => submitForm(values)}
      >
        {({ handleSubmit, validateForm, values }) => (
          <Form onSubmit={handleSubmit}>
            <Grid>
              <div className="t4-form-btns t4-sticky-btns">
                {member == null && (
                  <Button
                    icon="add user"
                    type="submit"
                    color="blue"
                    content="Add Next"
                    loading={isSavingNext}
                    disabled={isSaving}
                    onClick={() => {
                      validateForm().then((errors) => {
                        if (
                          errors.firstName == null &&
                          errors.lastName == null &&
                          errors.email == null
                        ) {
                          setCloseAfterSave(false);
                          setIsSaving(false);
                          setIsSavingNext(true);
                        }
                      });
                    }}
                  />
                )}
              </div>
              <Grid.Row>
                <Grid.Column>
                  <CustomTextInput
                    labeltext={"First Name *"}
                    placeholder={"First Name"}
                    name="firstName"
                    disabled={false}
                  />
                  <CustomTextInput
                    labeltext={"Last Name *"}
                    placeholder={"Last Name"}
                    name="lastName"
                    disabled={false}
                  />
                  <CustomTextInput
                    labeltext="Email Address *"
                    placeholder="Email Address"
                    name="email"
                    disabled={false}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="t4-actions t4-popup-btns">
              <Button
                disabled={isSaving || isSavingNext}
                onClick={() => {
                  onClose(true);
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                color="blue"
                disabled={isSavingNext}
                content="Save & Close"
                loading={isSaving}
                className="t4-btn-active"
                onClick={(e) => {
                  validateForm().then((errors) => {
                    if (errors.firstName == null && errors.lastName == null && errors.email == null) {
                      setCloseAfterSave(true);
                      setIsSaving(true);
                      setIsSavingNext(false);
                    }
                  });
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
