import { LanguageLookupDto } from "../models/common/languageLookupDto";
import LanguageDto from "../models/surveys/languageDto";

export function getLanguageLookups(languages: LanguageDto[]): LanguageLookupDto[] {
  if (!languages || languages.length === 0) return [];
  let lookups: LanguageLookupDto[] = [];
  languages.forEach((language) => {
    lookups.push({
      flag: language.flagName,
      key: language.id,
      text: language.name,
      value: language.id,
    });
  });
  return lookups;
}
