import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import SchoolChainDashboard from "./schoolChainDashboard";
import ManageSchools from "../Schools/manageSchools";
import SchoolAccess from "./schoolAccess";
import ChainSettings from "./chainSettings";
import ChainAdmins from "../ProjectCoordinators/chainAdmins";
import ChainNameControl from "./chainNameControl";
import AddChainSchool from "../Schools/addChainSchool";
import ImportSchools from "../Schools/importSchools";
import ChainReports from "../Reports/chainSurveyReport";
import ChainExtraDocuments from "./chainExtraDocuments";
import ManageChainSurveys from "../SurveyManagement/manageChainSurveys";
import ManageChainSchoolSurveys from "../SurveyManagement/manageChainSchoolSurveys";
import ChainReportsList from "../Reports/chainReportsList";

export default function SchoolChainDashboardHost() {
  const { chainCode } = useParams();
  return (
    <>
      {chainCode && (
        <>
          <ChainNameControl chainCode={chainCode} />
          <Routes>
            <Route path="/" element={<SchoolChainDashboard chainCode={chainCode} />} />
            <Route path="/manageschools" element={<ManageSchools chainCode={chainCode} />} />
            <Route path="/newschool" element={<AddChainSchool chainCode={chainCode} />} />
            <Route path="/schoolAccess" element={<SchoolAccess chainCode={chainCode} />} />
            <Route path="/settings" element={<ChainSettings chainCode={chainCode} />} />
            <Route path="/admins" element={<ChainAdmins chainCode={chainCode} />} />
            <Route path="/importSchools" element={<ImportSchools chainCode={chainCode} />} />
            <Route path="/extradocuments" element={<ChainExtraDocuments chainCode={chainCode} />} />
            <Route path="/managechainsurveys" element={<ManageChainSurveys chainCode={chainCode} />} />
            <Route path="/managechainschoolsurveys/:surveyCode" element={<ManageChainSchoolSurveys chainCode={chainCode} />} />
            <Route path="/reports" element={<ChainReportsList chainCode={chainCode} />} />
          </Routes>
        </>
      )}
    </>
  );
}
