import React, { useEffect, useState } from "react";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import LoadingComponent from "../../Layout/loadingComponent";
import SchoolMembersList from "../Members/membersList";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal, Segment } from "semantic-ui-react";
import SchoolNameControl from "../schoolNameControl";
import EditStaffEmail from "./editStaffEmail";

export default function InvalidEmails() {
  let params = useParams();
  const navigate = useNavigate();
  const [invalidUsers, setInvalidUsers] = useState<StaffMemberDto[]>([]);
  const [currentUser, setCurrentUser] = useState<StaffMemberDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  useEffect(() => {
    loadInvalidEmails();
  }, []);

  const loadInvalidEmails = () => {
    if (!params.schoolCode) return;
    setLoading(true);
    agent.Schools.invalidEmailStaff(params.schoolCode)
      .then((result) => {
        setInvalidUsers(result);
      })
      .catch((error) => {
        toast.error("Failed to load invalid members");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshInvalidEmails = () => {
    if (!params.schoolCode) return;
    setLoading(true);
    agent.Schools.invalidEmailInvitationsCount(params.schoolCode)
      .then((result) => {
        loadInvalidEmails();
      })
      .catch((error) => {
        toast.error("Failed to load invalid members");
      });
  };

  const editPopup = (
    <>
      <Modal open={dialogOpened}>
        <Modal.Header>Edit Profile Information</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <EditStaffEmail
              schoolCode={params.schoolCode!}
              member={currentUser}
              onClose={(refresh: boolean) => {
                setDialogOpened(false);
                if (refresh) refreshInvalidEmails();
              }}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const content = (
    <>
      {editPopup}
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
            }}
          ></Button>
          <h1>Invalid Emails</h1>
        </div>

        <div>
          <div className="t4-mng-btns">
            <Button
              icon="edit"
              disabled={currentUser == null}
              content={"Edit"}
              onClick={() => {
                setDialogOpened(true);
              }}
            ></Button>
          </div>
          {invalidUsers && invalidUsers.length > 0 && (
            <SchoolMembersList
              members={invalidUsers}
              selectedUsers={[currentUser] as StaffMemberDto[]}
              onUserSelected={(user: StaffMemberDto | null) => {
                setCurrentUser(user);
              }}
            />
          )}
        </div>
      </Segment>
    </>
  );
  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      {loading && <LoadingComponent content={"Loading..."} />}
      {!loading && content}
    </>
  );
}
