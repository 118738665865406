import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useStore } from "../../../Stores/store";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";
import { Button, Checkbox, Header, Label, Modal, Radio, Segment, Table, TableCell, TableHeader } from "semantic-ui-react";
import SchoolChainSurveyDto from "../../../models/chains/schoolChainSurveyDto";
import AddEditChainSurvey from "./addEditChainSurvey";
import agent from "../../../api/agent";
import { ReportStatuses } from "../../../models/enums";
import { ChainInfoDto } from "../../../models/schools/chainInfoDto";

interface Props {
  chainCode: string;
}
export default function ManageChainSurveys({ chainCode }: Props) {
  const navigate = useNavigate();
  const { lookupStore } = useStore();
  const { t } = useTranslation("common");
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<SchoolChainSurveyDto[]>([]);
  const [createdSurveysCount, setCreatedSurveysCount] = useState<number | null>(null);
  const [selectedSurvey, setSelectedSurvey] = useState<SchoolChainSurveyDto | null>(null);
  const [openEditSurvey, setOpenEditSurvey] = useState<boolean>(false);
  const [editSurveyMode, setEditSurveyMode] = useState<boolean>(false);
  const [chain, setChain] = useState<ChainInfoDto>();

  const handleError = () => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loadSurveys = () => {
    if (!chainCode) return;

    setLoading(true);
    agent.Chains.surveys(chainCode)
      .then((result) => {
        setSurveys(result);
        if (result == null) setCreatedSurveysCount(0);
        else setCreatedSurveysCount(result.length);
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        setLoading(false);
      });

    /*agent.Schools.profile(params.schoolCode)
      .then((result) => {
        setCurrentSchool(result);
      })
      .catch(() => {
        toast.error("Faild to load school profile");
      });

    agent.Schools.activeSchoolsurvey(params.schoolCode)
      .then((result) => {
        setActiveSchoolSurvey(result);
      })
      .catch(() => {
        toast.error("Faild to load actvive school wave");
      });*/
  };

  useEffect(() => {
    loadSurveys();
    agent.Chains.info(chainCode)
      .then((result) => setChain(result))
      .catch(() => toast.error("Failed to load chain info"));
    loadSurveys();
  }, []);

  const toggleChainReportPublishStatus = (survey: SchoolChainSurveyDto) => {
    let status = survey.reportStatus == ReportStatuses.Unpublished ? ReportStatuses.Published : ReportStatuses.Unpublished;
    setSaving(true);
    agent.Chains.updateChainReportPublishStatus(chainCode, survey.code, status)
      .then(() => {
        toast.success("Status updated successfully");
        loadSurveys();
      })
      .catch(() => {
        toast.error("Failed to udpate status");
      })
      .finally(() => setSaving(false));
  };

  const editSurvey = (
    <>
      <Modal open={openEditSurvey}>
        <Modal.Header>{editSurveyMode ? "Edit Wave" : "New Wave"}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {chainCode && (
              <AddEditChainSurvey
                chainCode={chainCode}
                survey={selectedSurvey}
                surveyNumber={!surveys ? 1 : surveys.length + 1}
                onCancel={() => {
                  setOpenEditSurvey(false);
                }}
                onUpdate={() => {
                  setOpenEditSurvey(false);
                  loadSurveys();
                }}
              />
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const content = (
    <>
      {editSurvey}
      <Segment className="t4-mainmenu t4-header-align">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/chain/${chainCode}`);
              }}
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              Manage Waves
            </Header>
          </div>
        </div>
        <div className="t4-mng-btns">
          <Button
            icon="add"
            content={"New"}
            onClick={() => {
              setEditSurveyMode(false);
              setOpenEditSurvey(true);
            }}
          ></Button>
          <Button
            icon="edit"
            content={"Edit"}
            disabled={selectedSurvey == null}
            onClick={() => {
              setEditSurveyMode(true);
              setOpenEditSurvey(true);
            }}
          ></Button>
        </div>
        <div id="divSurveyCounters">
          <Label color="blue" horizontal>
            {createdSurveysCount == null ? "-" : createdSurveysCount} / {chain?.numberOfSurveysPerYear}
          </Label>
        </div>
          <Table className="t4-tbl t4-tbl-long">
            <Table.Header>
              <Table.Row>
              <TableCell></TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Started On</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Chain Report Status</TableCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {surveys &&
                surveys.length > 0 &&
                surveys.map((survey) => (
                  <Table.Row key={survey.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedSurvey != null && selectedSurvey.id === survey.id}
                        onChange={(e) => {
                          if (selectedSurvey == null) setSelectedSurvey(survey);
                          else if (selectedSurvey.id === survey.id) setSelectedSurvey(null);
                          else if (selectedSurvey.id != survey.id) setSelectedSurvey(survey);
                        }}
                      />
                    </TableCell>
                    <TableCell>{survey.id}</TableCell>
                    <TableCell>
                      <NavLink to={`/dashboard/chain/${chainCode}/managechainschoolsurveys/${survey.code}`}>{survey.name}</NavLink>
                    </TableCell>
                    <TableCell>{survey.createdOn}</TableCell>
                    <TableCell> </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Radio
                        toggle
                        disabled={saving}
                        checked={survey.reportStatus == ReportStatuses.Published}
                        onChange={() => {
                          toggleChainReportPublishStatus(survey);
                        }}
                      />
                    </TableCell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={"Loading Surveys"} />}
    </>
  );
}
