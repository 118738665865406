import React, { useState } from "react";
import { Button, Container, Icon, Message, Segment } from "semantic-ui-react";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import { toast } from "react-toastify";
import Papa from "papaparse";
import StaffMemberValidationDto from "../../../models/schools/staffMemberValidationDto";
import agent from "../../../api/agent";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SchoolNameControl from "../schoolNameControl";
import ImportMembersProgress from "../../Layout/importProgress";

export default function ImportMembers() {
  const { t } = useTranslation("common");
  let params = useParams();
  const navigate = useNavigate();
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [templateDownloaded, setTemplateDownloaded] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [importMembers, setImportMembers] = useState<StaffMemberDto[] | null>(null);
  const [currentImportLogId, setCurrentImportLogId] = useState<string | null>(null);
  const [validationResults, setValidationResults] = useState<StaffMemberValidationDto[]>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [file, setFile] = useState();

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const downloadTemplate = () => {
    setTemplateDownloaded(true);
    var link = document.createElement("a");
    link.download = "stafftemplate.csv";
    link.href = "/csvTemplates/stafftemplate.csv";
    link.click();
  };

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    setValidationResults([]);
    setIsValid(false);
    setIsValidating(true);
    setIsImporting(false);
    if (file) {
      Papa.parse(file, {
        complete: function (results: any) {
          let data = parseStaff(results.data);
          if (data == null || data.length === 0) {
            toast.warn("file is empty !");
            setIsValidating(false);
            return;
          }

          setImportMembers(data);
          setValidationResults([]);

          if (!params.schoolCode) return;
          agent.Schools.validateMembers(params.schoolCode, data)
            .then((result) => {
              setValidationResults(result);
              if (!result || result == null || result.length === 0) setIsValid(true);
            })
            .catch((error) => handleError(error))
            .finally(() => {
              setIsValidating(false);
            });
        },
      });
    }
  };

  const parseStaff = (data: any): StaffMemberDto[] => {
    let result: StaffMemberDto[] = [];
    if (data == null || data.length <= 1) return result;

    let invalidRowCount = 0;
    data.forEach((element: any, index: number) => {
      if (index === 0) return;
      if (element.length === 1) return;
      if (element.length < 3) {
        invalidRowCount++;
        return;
      }
      let member: StaffMemberDto = {
        firstName: element[0],
        lastName: element[1],
        email: element[2],
        id: 0,
        isSchoolAdmin: false,
        jobRole: "Staff",
        lastLogin: null,
        notes: null,
        phone: null,
        password: null,
        lastLoginDateString: null,
        displayName: "",
      };
      result.push(member);
    });

    if (invalidRowCount > 0)
      toast.warn(`${invalidRowCount} rows were not loaded due to missing fields.`);

    return result;
  };

  const startImport = () => {
    if (importMembers == null || !params.schoolCode) return;
    setIsImporting(true);

    agent.Schools.newImport(params.schoolCode, importMembers.length).then((result) => {
      if (importMembers == null || !params.schoolCode || !result) return;
      setCurrentImportLogId(result);
      agent.Schools.importMembers(params.schoolCode, result, importMembers)
        .then((result) => {
          toast.success("Import finished successfully");
          navigate(`/dashboard/schoolMembers/${params.schoolCode}`);
        })
        .catch((error) => handleError(error))
        .finally(() => {
          setIsImporting(false);
        });
    });
  };

  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
            }}
          ></Button>
          <h2>Import Staff Profiles</h2>
        </div>

        <Container>
          <div className="t4-import-step">
            <ul className="t4-StepProgress">
              <li
                className={`t4-StepProgress-item ${
                  file != null || templateDownloaded ? "is-done" : ""
                } `}
              >
                <strong>Download Template.</strong>
                <p className="t4-substep">
                  Add First Names to column 1. <br />
                  Add Last Names to column 2.
                  <br />
                  Add Emails to column 1. <br />
                  Do not change column headers.
                  <br />
                  Save the template.
                  <br />
                  <Button
                    className="t4-btn basic ui button dl-btn t4-import-btn"
                    compact
                    onClick={downloadTemplate}
                  >
                    Download Template
                  </Button>
                </p>
              </li>
              <li className={`t4-StepProgress-item ${file == null ? "" : "is-done"} `}>
                <strong>Select the template below.</strong>
                <div>
                  <div className="t4-upload">
                    <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} />{" "}
                  </div>
                  <div className="react-file-reader-button"></div>
                </div>
              </li>
              <li className={`t4-StepProgress-item ${isValid ? "is-done" : ""} `}>
                <strong>Select Validate.</strong>
                <Button
                  className="t4-btn-push t4-btn basic"
                  loading={isValidating}
                  disabled={file == null || isImporting}
                  onClick={(e) => {
                    handleOnSubmit(e);
                  }}
                >
                  Validate
                </Button>
                <br />
                <div id="divValidationMessages">
                  {validationResults && validationResults.length > 0 && (
                    <Message icon negative className="t4-error-msg">
                      <Icon name="exclamation triangle" />
                      <Message.Content>
                        <Message.Header>
                          {" "}
                          <h3>Errors</h3>
                        </Message.Header>
                        <p>
                          {validationResults &&
                            validationResults.length > 0 &&
                            validationResults.map((v, index) => (
                              <p key={index}>
                                {v.email}
                                {": "}
                                {v.error}
                              </p>
                            ))}
                        </p>
                      </Message.Content>
                    </Message>
                  )}
                </div>
              </li>
              <li className={`t4-StepProgress-item ${isImporting ? "is-done" : ""} `}>
                <strong>Start Importing</strong>
                <div>
                  <Button
                    className="t4-btn-push t4-btn basic"
                    loading={isImporting}
                    content={"Start Import"}
                    onClick={() => {
                      startImport();
                    }}
                    disabled={(validationResults != null && validationResults.length > 0) || !isValid}
                  />
                </div>
              </li>
            </ul>
          </div>
        </Container>
        {isImporting && <ImportMembersProgress logCode={currentImportLogId} />}
      </Segment>
    </>
  );
}
