import React, { useEffect, useState } from "react";
import PillarScoringDto from "../../../models/scoring/pillarScoringDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";
import { Button, Grid, Input, Segment, Table, TableCell, TableHeader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { DefaultScoringMatrix } from "../../../models/constants";

export default function EditPillarsWeightings() {
  const navigate = useNavigate();
  const [scoring, setScoring] = useState<PillarScoringDto[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  const loadScoringMatrix = () => {
    setLoading(true);
    agent.Questions.scoring()
      .then((result) => {
        setScoring(result);
      })
      .catch((error) => {
        toast.error("Failed to retrieve scoring matrix !");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadScoringMatrix();
  }, []);

  const updateFoundationWeight = (pillarId: number, foundationId: number, weight: number) => {
    if (!scoring) return;

    let newScore: PillarScoringDto[] = [...scoring];

    newScore.forEach((pillar) => {
      if (pillar.pillarId === pillarId) {
        //locate pillar
        if (pillar && pillar.foundations && pillar.foundations.length > 0) {
          //locate foundation
          pillar.foundations.forEach((foundation) => {
            if (foundation.foundationId === foundationId) {
              foundation.weight = weight;
              let pillarWeight = pillar.foundations?.map((f) => f.weight).reduce((a, b) => a + b);
              pillar.weight = pillarWeight ?? 0;
              setScoring(newScore);
            }
          });
        }
      }
    });
  };

  const saveScores = () => {
    if (!scoring) return;
    let totalScore = scoring?.map((f) => f.weight).reduce((a, b) => a + b);
    if (totalScore !== 100) {
      toast.error("Total points must be equal to 100");
      return;
    }
    setSaving(true);
    agent.Questions.updatePillarsScoring(scoring)
      .then(() => toast.success("scoring updated successfully"))
      .catch(() => toast.error("Failed to update scoring"))
      .finally(() => setSaving(false));
  };

  const content = (
    <>
    
      <Grid className="t4-banner-info">
        <Grid.Row>
          <Grid.Column width={4}></Grid.Column>
          <Grid.Column width={8}></Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <span>Total: {scoring && scoring?.map((f) => f.weight).reduce((a, b) => a + b)}%</span>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment className="t4-mainmenu t4-edit-score">
      <h1>Edit Scoring Matrix</h1>
      <ul className="t4-scoring-wrapper">
        {scoring &&
          scoring.map((pillar) => (
            <li key={pillar.pillarId}>
              <h2>
                {pillar.pillarName} - {pillar.weight}
              </h2>
              <ul className="t4-pillars">
                <Table className="t4-tbl">
                  <TableHeader>
                    <TableCell>Foundation</TableCell>
                    <TableCell>Weight</TableCell>
                  </TableHeader>
                  <Table.Body>
                    {pillar.foundations &&
                      pillar.foundations.map((foundation) => (
                        <Table.Row key={foundation.foundationId}>
                          <TableCell>{foundation.foundationName}</TableCell>
                          <TableCell>
                            <Input
                              value={foundation.weight}
                              onChange={(e) => {
                                if (!isNaN(+e.target.value)) {
                                  updateFoundationWeight(
                                    pillar.pillarId,
                                    foundation.foundationId,
                                    +e.target.value
                                  );
                                }
                              }}
                            />
                          </TableCell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </ul>
            </li>
          ))}
      </ul>
      <div className="t4-form-bot-btns">
        <Button
          floated="right"
          type="submit"
          color="blue"
          content="Save"
          loading={saving}
          className="t4-btn-active"
          onClick={() => {
            saveScores();
          }}
        />
        <Button
          className="t4-btn-non"
          floated="right"
          disabled={saving}
          onClick={() => {
            navigate(`/dashboard/scoringmatrix`);
          }}
        >
          Cancel
        </Button>
        <Button
            icon="refresh"
            content={"Reset"}
            className="t4-btn-non"
          floated="right"
            onClick={() => {
              setScoring(DefaultScoringMatrix);
            }}
          ></Button>
      </div>
      </Segment>
    </>
  );

  return (
    <>
      {loading && <LoadingComponent content={""} />}
      {!loading && content}
    </>
  );
}
