import { Button, Modal } from "semantic-ui-react";

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
}

export default function ConfirmDeleteNotification({ onCancel, onConfirm }: Props) {
  return (
    <>
      <Modal open={true}>
        <Modal.Header>Add Email</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div>You are about to remove the selected email from the notification list. Do you want to continue?</div>
            <div className="t4-form-bot-btns">
              <Button
                floated="right"
                className="t4-btn-active"
                color="blue"
                content="Confirm"
                onClick={() => {
                  onConfirm();
                }}
              />
              <Button
                className="t4-btn-non"
                floated="right"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );
}
