import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Modal } from "semantic-ui-react";
import agent from "../../../api/agent";

interface Props {
  onCancel: () => void;
  onUpdate: () => void;
}

export default function AddNotification({ onCancel, onUpdate }: Props) {
  const [userEmail, setUserEmail] = useState<string>();
  const [saving, setSaving] = useState(false);
  const save = () => {
    if (!userEmail || userEmail.trim() === "") {
      toast.error("Please enter an email to save");
      return;
    }
    setSaving(true);
    agent.Settings.addNotificationEmail(userEmail)
      .then(() => {
        toast.success("Email added successfully");
        setUserEmail("");
        setSaving(false);
        onUpdate();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          toast.error(error.response.data);
        } else {
          toast.error("Failed to add email");
          setSaving(false);
        }
      });
  };

  return (
    <>
      <Modal open={true}>
        <Modal.Header>Add Email</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div>
              <label>Email: </label>
              <Input
                type="text"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
            </div>
            <div className="t4-form-bot-btns">
              <Button
                floated="right"
                className="t4-btn-active"
                color="blue"
                content="Save"
                loading={saving}
                onClick={() => {
                  save();
                  onUpdate();
                }}
              />
              <Button
                className="t4-btn-non"
                floated="right"
                disabled={saving}
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );
}
