import React from "react";
import { Table, TableBody, TableCell, TableHeader } from "semantic-ui-react";
import InvitationDto from "../../../models/surveys/invitationDto";

interface Props {
  invitations: InvitationDto[] | undefined;
  schoolCode: string;
}

export default function AnonymousInvitationsList({ invitations, schoolCode }: Props) {
  return (
    <Table className="t4-tbl">
      <TableHeader>
        <Table.Row>
          <TableCell>#</TableCell>
          <TableCell>Link</TableCell>
          <TableCell>Invitation Date</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Fill Date</TableCell>
        </Table.Row>
      </TableHeader>
      <TableBody>
        {invitations != null &&
          invitations.length > 0 &&
          invitations.map((invitation) => (
            <Table.Row key={invitation.id}>
              <Table.Cell>{invitation.id}</Table.Cell>
              <Table.Cell>
                {window.location.href.replace(
                  `dashboard/anonymousinvitations/${schoolCode}`,
                  `takesurvey/${invitation.code}`
                )}
              </Table.Cell>
              <Table.Cell>{invitation.inviteDate}</Table.Cell>
              <Table.Cell>{invitation.isFilled ? "Completed" : "-"}</Table.Cell>
              <Table.Cell>{invitation.fillDate}</Table.Cell>
            </Table.Row>
          ))}
      </TableBody>
    </Table>
  );
}
