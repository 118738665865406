import React, { useEffect, useState } from "react";
import SchoolDto from "../../../models/schools/schoolDto";
import { SchoolAgeGroups, SchoolTypes, StudentBoardingFacility } from "../../../models/enums";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../../Layout/loadingComponent";
import { Segment, Button } from "semantic-ui-react";
import { useStore } from "../../../Stores/store";
import SchoolNameControl from "../schoolNameControl";

export default function SchoolProfile() {
  let params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { lookupStore } = useStore();
  const [school, setSchool] = useState<SchoolDto>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    if (!params.schoolCode || params.schoolCode == null) return;

    agent.Schools.profile(params.schoolCode)
      .then((result) => {
        setSchool(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.schoolCode]);

  const content = (
    <>
      <div className=" t4-form-btns t4-sticky-btns" style={{ display: "contents" }}>
        <Button
          icon="edit"
          content={"Edit"}
          onClick={() => {
            navigate(`/dashboard/editschoolprofile/${params.schoolCode}`);
          }}
        ></Button>
      </div>
      <div className="t4-school-details">
        <div className="t4-school-info-section">
          <h3 className="t4-school-info-sub">Profile Information</h3>
          <div className="t4-detail-row">
            <span className="t4-detail-main">School Full Name:</span> {school?.name}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">Registration Date:</span> {school?.registrationDate}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">Country / Region:</span> {school?.country}{" "}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">Nearest Town/City:</span> {school?.nearestTown}{" "}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">Location:</span>{" "}
            {lookupStore.getSchoolLocation(school?.location)}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">School Type:</span>{" "}
            {lookupStore.getSchoolType(school?.schoolType)}
          </div>
        </div>
        <div className="t4-school-info-section">
          <h3 className="t4-school-info-sub">School Details</h3>
          <div className="t4-detail-row">
            <span className="t4-detail-main">Approximately how many students attend school:</span>{" "}
            {school?.approximateStudentCount}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">What age groups does school cater for:</span>{" "}
            {school?.ageGroups?.map((group, index) => (
              <>
                {group === SchoolAgeGroups.Kindergarten && (
                  <span className="t4-multi" key={index}>
                    Kindergarten
                  </span>
                )}
                {"  "}
                {group === SchoolAgeGroups.Primary && (
                  <span className="t4-multi" key={index}>
                    Primary
                  </span>
                )}
                {"  "}
                {group === SchoolAgeGroups.Secondary && (
                  <span className="t4-multi" key={index}>
                    Secondary
                  </span>
                )}
                {"  "}
              </>
            ))}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">
              What gender does your school cater for (Please select all that apply)?
            </span>{" "}
            {lookupStore.getSchoolGenders(school?.genders)}
          </div>
          {(school?.schoolType === SchoolTypes.PrivateIndependent ||
            school?.schoolType === SchoolTypes.PrivateIndependentFaithSchool) && (
            <div className="t4-detail-row">
              <span className="t4-detail-main">
                Does your school offer boarding facilities to students?
              </span>{" "}
              {school?.studentBoardingFacilities == null ||
              school?.studentBoardingFacilities === StudentBoardingFacility.No
                ? "No"
                : "Yes"}
            </div>
          )}
          <div className="t4-detail-row">
            <span className="t4-detail-main">
              How many staff work at school (both teachers and other staff) either full time or part
              time:
            </span>
            {school?.staffCount}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">
              How many Teaching staff work at school (either full time or part time):
            </span>
            {school?.teachingStaffCount}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">
              How many non teaching staff (i.e Operational/Administrative) work at school (both full time
              or part time):
            </span>
            {school?.nonTeachingStaffCount}
          </div>
          <div className="t4-detail-row">
            <span className="t4-detail-main">Is school part of a wider school group:</span>{" "}
            {school?.schoolChainId == null ? "No" : "Yes"}
          </div>
          {school?.schoolChainId != null && (
            <div className="t4-detail-row">
              <span className="t4-detail-main">What is the name of the parent group:</span>{" "}
              {school?.chainName}
            </div>
          )}
          {(school?.schoolType === SchoolTypes.PrivateIndependent ||
            school?.schoolType === SchoolTypes.PrivateIndependentFaithSchool) && (
            <div className="t4-detail-row">
              <span className="t4-detail-main">Average annual fees per student in local currency:</span>
              {school?.averageAnnualStudentFees}
            </div>
          )}
        </div>
        <div className="t4-detail-row">
          <span className="t4-detail-main">School Website:</span>
          {school?.schoolWebsite}
        </div>
        {school?.schoolChainId != null && (
          <div className="t4-detail-row">
            <span className="t4-detail-main">Parent group website:</span> {school?.chainWebsite}
          </div>
        )}
      </div>
    </>
  );
  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
            }}
          ></Button>
          <h2>School Profile</h2>
        </div>
        {!loading && content}
        {loading && <LoadingComponent content={""} />}
      </Segment>
    </>
  );
}
