import React, { useEffect, useState } from "react";
import { Button, Dropdown, Icon, Modal, Segment } from "semantic-ui-react";
import QuestionDto from "../../../models/surveys/questionDto";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../../../api/agent";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SchoolQuestionsList from "./schoolQuestionsList";
import SchoolNameControl from "../schoolNameControl";
import SchoolLanguageSettings from "./schoolLanguageSettings";
import SchoolLicenses from "./schoolLicenses";
import SchoolContacts from "./schoolContacts";

export default function SurveySettings() {
  let params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const [loadingQuestions, setLoadingQuestions] = useState<boolean>(true);
  const [schoolQuestions, setSchoolQuestions] = useState<QuestionDto[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionDto | null>();
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loadQuestions = () => {
    if (!params.schoolCode) return;
    setLoadingQuestions(true);

    agent.Schools.questions(params.schoolCode)
      .then((result) => {
        setSchoolQuestions(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingQuestions(false);
      });
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  const deleteQuestion = () => {
    if (!params.schoolCode || !selectedQuestion || !selectedQuestion.code) return;
    setIsDeleting(true);

    agent.Questions.deleteSchoolQuestion(params.schoolCode, selectedQuestion.code)
      .then(() => {
        setShowConfirmDelete(false);
        setSelectedQuestion(null);
        loadQuestions();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const confirmDeletePopup = (
    <>
      <Modal open={showConfirmDelete}>
        <Modal.Header>Please Confirm</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="t4-modal-txt">
              Are you sure you want to delete the selected question?
              <p>Note: All results for the question will also be deleted.</p>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={isDeleting}
            onClick={() => {
              setShowConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="blue"
            content="Confirm"
            loading={isDeleting}
            className="t4-btn-active"
            onClick={() => {
              deleteQuestion();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );

  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
            }}
          ></Button>
          <h1>Survey Settings</h1>
        </div>
        <div className="t4-spacing">
          <SchoolLanguageSettings schoolCode={params.schoolCode} />
          {confirmDeletePopup}
        </div>
        <div className="t4-survey-settings-section">
          <h3>Additional Survey Questions</h3>
          <p>This section is for adding in additional survey questions. </p>

          <div className="t4-action-btns">
            <Button disabled={schoolQuestions != null && schoolQuestions.length >= 5}>
              <Icon name="add" />
              <Dropdown text="Add" className="button t4-add-btn">
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      navigate(`/dashboard/newschoolquestion/${params.schoolCode}/text`);
                    }}
                    disabled={schoolQuestions != null && schoolQuestions.length >= 5}
                    content={"Text"}
                  ></Dropdown.Item>
                  <Dropdown.Item
                    content={"Multiple Choice"}
                    onClick={() => {
                      navigate(`/dashboard/newschoolquestion/${params.schoolCode}/mcq`);
                    }}
                  ></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Button>
            <Button
              icon="edit"
              disabled={selectedQuestion == null}
              content={"Edit"}
              onClick={() => {
                navigate(`/dashboard/editchoolquestion/${params.schoolCode}/${selectedQuestion?.code}`);
              }}
            ></Button>
            <Button
              icon="trash alternate outline"
              disabled={selectedQuestion == null}
              content={"Remove"}
              onClick={() => {
                setShowConfirmDelete(true);
              }}
            ></Button>
          </div>
          {schoolQuestions && schoolQuestions.length > 0 && (
            <SchoolQuestionsList
              loading={loadingQuestions}
              questions={schoolQuestions}
              selectedQuestion={selectedQuestion ? selectedQuestion : null}
              onQuestionSelected={(question) => setSelectedQuestion(question)}
            />
          )}
        </div>
        <div className="t4-spacing">
          {params.schoolCode && <SchoolLicenses schoolCode={params.schoolCode} />}
        </div>
        <div className="t4-spacing">
          {params.schoolCode && <SchoolContacts schoolCode={params.schoolCode} />}
        </div>
      </Segment>
    </>
  );
}
