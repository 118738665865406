import { makeAutoObservable, runInAction } from "mobx";
import { store } from "./store";
import agent from "../api/agent";
import UserDto from "../models/users/userDto";

export default class UserStore {
    user: UserDto | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    get isSystemAdmin(): boolean {
        if (!this.user || this.user == null || this.user.userRole == null) return false;
        return this.user.userRole.toUpperCase() === "SYSADMIN";
    }

    get isSchoolAdmin(): boolean {
        if (!this.user || this.user == null || this.user.userRole == null) return false;
        return this.user.userRole.toUpperCase() === "SCHOOLADMIN";
    }

    get isChainAdmin(): boolean {
        if (!this.user || this.user == null || this.user.userRole == null) return false;
        return this.user.userRole.toUpperCase() === "CHAINADMIN";
    }

    login = async (creds: any) => {
        try {
            var user: UserDto = await agent.Accounts.login(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => {
                this.user = user;
            });
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    };

    logout = () => {
        this.user = null;
        store.commonStore.setToken(null);
        window.localStorage.removeItem("jwt");
    };

    getUser = async () => {
        try {
            var user = await agent.Accounts.current();
            runInAction(() => (this.user = user));
        } catch (error) {
            console.log(error);
        }
    };
}
