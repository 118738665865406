import { makeAutoObservable, reaction } from "mobx";
import SurveyTranslationsDto from "../models/translations/surveyTranslationsDto";
import QuestionDto from "../models/surveys/questionDto";
import QuestionChoiceDto from "../models/surveys/questionChoiceDto";
import SurveyPageDto from "../models/surveys/surveyPageDto";

export default class TranslationStore {

    lang: string | null = window.localStorage.getItem("lang");
    surveyTranslations: SurveyTranslationsDto | null = null;
    currentInvitationCode: string | null = null;

    constructor() {

        makeAutoObservable(this);
        reaction(
            () => this.lang,
            (lang) => {
                if (lang) {
                    window.localStorage.setItem("lang", lang);
                }
                else { window.localStorage.removeItem("lang"); }
            }
        );
    }

    setLanguage = (language: string | null | undefined) => {
        if (language === undefined)
            this.lang = null;
        else
            this.lang = language;
    };

    setSurveyTranslation = (language: string, translations: SurveyTranslationsDto) => {
        this.surveyTranslations = translations;
    }

    setCurrentInvitationCode = (code: string | null) => {
        this.currentInvitationCode = code;
    }

    questionBody = (question: QuestionDto): string => {
        if (!question || question == null)
            return "";

        if (!this.surveyTranslations
            || this.surveyTranslations == null
            || this.surveyTranslations.questions == null
            || this.surveyTranslations.questions.length === 0)
            return question.body;

        var translation = this.surveyTranslations.questions.find(t => t.id === question.id);
        if (translation && translation != null && translation.body != null)
            return translation.body;

        return question.body;
    }

    choiceBody = (choice: QuestionChoiceDto): string => {
        if (!choice || choice == null)
            return "";

        if (!this.surveyTranslations
            || this.surveyTranslations == null
            || this.surveyTranslations.choices == null
            || this.surveyTranslations.choices.length === 0)
            return choice.body;

        var translation = this.surveyTranslations.choices.find(t => t.id === choice.id);
        if (translation && translation != null && translation.body != null)
            return translation.body;

        return choice.body;
    }

    pageContent = (page: SurveyPageDto): string => {
        if (!page || page == null)
            return "";

        if (!this.surveyTranslations
            || this.surveyTranslations == null
            || this.surveyTranslations.pages == null
            || this.surveyTranslations.pages.length === 0)
            return page.pageContent ? page.pageContent : "";

        var translation = this.surveyTranslations.pages.find(t => t.id === page.id);
        if (translation && translation != null && translation.body != null)
            return translation.body;

        return page.pageContent ? page.pageContent : "";
    }
}