import React from "react";
import { Button, Header, Segment } from "semantic-ui-react";
import ChainLicenses from "./chainLicenses";
import ChainContacts from "./chainContact";
import { useNavigate } from "react-router-dom";

interface Props {
  chainCode: string;
}

export default function ChainSettings({ chainCode }: Props) {
  const navigate = useNavigate();
  return (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/chain/${chainCode}`);
              }}
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              Settings
            </Header>
          </div>
        </div>

        <ChainLicenses chainCode={chainCode!} />
        <ChainContacts chainCode={chainCode!} />
      </Segment>
    </>
  );
}
