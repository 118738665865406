import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import agent from "../../../api/agent";
import { Button, Header, Modal, Segment } from "semantic-ui-react";
import LoadingComponent from "../../Layout/loadingComponent";
import AdminList from "./adminList";
import AddEditProjectCoordinator from "./addEditProjectCoordinator";
import ResetPasswordControl from "./resetPasswordControl";
import SchoolNameControl from "../schoolNameControl";

export default function ManageProjectCoordinators() {
  let params = useParams();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [members, setMembers] = useState<StaffMemberDto[] | null>(null);
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<StaffMemberDto | null>(null);
  const [actioning, setActioning] = useState<boolean>(false);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loadCoordinators = () => {
    setCurrentUser(null);
    if (!params.schoolCode) return;
    setLoading(true);
    agent.Schools.coordinators(params.schoolCode)
      .then((result) => {
        setMembers(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteUser = () => {
    if (currentUser == null || !params.schoolCode) return;
    setIsDeleting(true);
    agent.Schools.deleteCoordinator(currentUser.id, params.schoolCode)
      .then(() => {
        toast.success("Project Coordinator deleted successfully");
        setShowConfirmDelete(false);
        loadCoordinators();
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const addNewProjectCoordinator = (member: StaffMemberDto) => {
    setActioning(true);
    if (!params.schoolCode) return;
    agent.Schools.addCoordinator(member, params.schoolCode)
      .then((result) => {
        toast.success("Project Coordinator added successfully");
        setDialogOpened(false);
        loadCoordinators();
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error("User email already in use!");
        else handleError(error);
      })
      .finally(() => {
        setActioning(false);
      });
  };

  const updateProjectCoordinator = (member: StaffMemberDto) => {
    setActioning(true);
    if (!params.schoolCode) return;
    agent.Schools.updateCoordinator(member, params.schoolCode)
      .then((result) => {
        toast.success("Project Coordinator updated successfully");
        setDialogOpened(false);
        loadCoordinators();
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setActioning(false));
  };

  useEffect(() => {
    loadCoordinators();
  }, [params.schoolCode]);

  const editPopup = (
    <>
      <Modal className="t4-popup" open={dialogOpened}>
        <Modal.Header>Project Co-ordinator</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AddEditProjectCoordinator
              organisationCode={params.schoolCode!}
              member={currentUser}
              saving={actioning}
              onClose={(refresh: boolean) => {
                setDialogOpened(false);
                if (refresh) loadCoordinators();
              }}
              onSubmitNew={(member) => {
                addNewProjectCoordinator(member);
              }}
              onUpdate={(member) => {
                updateProjectCoordinator(member);
              }}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const confirmDeletePopup = (
    <>
      <Modal className="t4-popup" open={showConfirmDelete}>
        <Modal.Header>Please Confirm</Modal.Header>
        <Modal.Content>
          <Modal.Description>Are you sure you want to delete the selected user?</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={isDeleting}
            onClick={() => {
              setShowConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="blue"
            content="Confirm"
            loading={isDeleting}
            className="t4-btn-active"
            onClick={() => {
              deleteUser();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );

  const resetPasswordPopup = (
    <>
      <Modal className="t4-popup t4-reset-popup" open={showChangePassword}>
        <Modal.Header>Select new password</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ResetPasswordControl
              onClose={() => setShowChangePassword(false)}
              member={currentUser}
              schoolCode={params.schoolCode!}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );

  const content = (
    <>
      <Segment /*className="t4-mainmenu"*/ className="t4-mainmenu t4-header-align">
        <div className="t4-header-collection">
        <div className="t4-headers">
        <Button
        className="t4-back-btn"
          icon="arrow left"
          onClick={() => {
            navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
          }}
        ></Button>
        <Header as="h1" className="t4-header-left" textAlign="left">
            Project Co-ordinators
          </Header>
        </div>
          {/*<Header as="h4" className="t4-header-right" textAlign="right">
            Total Project Co-ordinators: {members && members != null ? members.length : "0"}
        </Header>*/}
        </div>
        {editPopup}
        {confirmDeletePopup}
        {resetPasswordPopup}
        <div className="t4-mng-btns">
          <Button
            icon="add user"
            content={"Add New"}
            onClick={() => {
              setCurrentUser(null);
              setDialogOpened(true);
            }}
          ></Button>
          <Button
            icon="edit"
            content={"Edit"}
            disabled={currentUser == null}
            onClick={() => {
              setDialogOpened(true);
            }}
          ></Button>
          <Button
            icon="user delete"
            content={"Delete"}
            disabled={currentUser == null}
            onClick={() => {
              setShowConfirmDelete(true);
            }}
          ></Button>
          <Button
            icon="repeat"
            content={"Reset Password"}
            disabled={currentUser == null}
            onClick={() => {
              setShowChangePassword(true);
            }}
          ></Button>
        </div>
        <AdminList
          members={members}
          selectedUser={currentUser}
          onUserSelected={(user: StaffMemberDto | null) => {
            setCurrentUser(user);
          }}
        />
      </Segment>
    </>
  );

  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      {!loading && content}
      {loading && <LoadingComponent content={"Loading Project Co-ordinators"} />}
    </>
  );
}
