import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextInput from "../../../Common/customTextInput";

interface Props {
  organisationCode: string;
  member: StaffMemberDto | null;
  onClose: (refresh: boolean) => void;
  onSubmitNew: (member: StaffMemberDto) => void;
  onUpdate: (member: StaffMemberDto) => void;
  saving: boolean;
}

export default function AddEditProjectCoordinator({
  organisationCode,
  onClose,
  onSubmitNew,
  onUpdate,
  member,
  saving,
}: Props) {
  const [user, setUser] = useState<StaffMemberDto>({} as StaffMemberDto);

  useEffect(() => {
    if (!member || member == null) {
      setUser({} as StaffMemberDto);
      return;
    }

    //edit values
    setUser({ ...member });
  }, [member]);

  const editValidationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
      .typeError("Invalid Email Address")
      .required("Required")
      .email("Invalid Email Address"),
  });

  const addNewValidationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    password: Yup.string()
      .required("Required")
      .test("password", "Password must be at least 8 characters long", (val) => val.length >= 8),
    email: Yup.string()
      .typeError("Invalid Email Address")
      .required("Required")
      .email("Invalid Email Address"),
  });

  const submitForm = (values: StaffMemberDto) => {
    setUser(values);
    if (!member || member.id === 0) onSubmitNew(values);
    else onUpdate(values);
  };

  return (
    <>
      <Formik
        validationSchema={!member || member.id === 0 ? addNewValidationSchema : editValidationSchema}
        enableReinitialize
        initialValues={user}
        onSubmit={(values) => submitForm(values)}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <CustomTextInput
                labeltext={"First Name *"}
                placeholder={"First Name"}
                name="firstName"
                disabled={false}
              />
              <CustomTextInput
                labeltext={"Last Name *"}
                placeholder={"Last Name"}
                name="lastName"
                disabled={false}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <CustomTextInput
                labeltext="Email Address *"
                placeholder="Email Address"
                name="email"
                disabled={member != null}
              />
              <CustomTextInput
                labeltext="Phone Number"
                placeholder="Phone Number"
                name="phone"
                disabled={false}
              />
            </Form.Group>
            <Form.Group widths="2">
              {/*<CustomTextInput labeltext="Job" placeholder="Role" name="jobRole" disabled={false} />*/}
              {member == null && (
                <CustomTextInput
                  labeltext="Password"
                  placeholder="Password"
                  name="password"
                  disabled={false}
                />
              )}
            </Form.Group>

            <div className="t4-actions t4-popup-btns">
              <Button
                disabled={saving}
                onClick={() => {
                  onClose(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="blue"
                content="Save"
                loading={saving}
                className="t4-btn-active"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
