import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Button, Header, Segment, Form, Grid } from "semantic-ui-react";
import { Formik } from "formik";
import CustomTextInput from "../../../Common/customTextInput";
import CustomCheckboxInput from "../../../Common/customCheckboxInput";
import CustomDateInput from "../../../Common/customDateInput";
import { AddSchoolChainDto } from "../../../models/schools/addSchoolChainDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

export default function AddEditChainLicense() {
  let params = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [chain, setChain] = useState<AddSchoolChainDto>({} as AddSchoolChainDto);
  const [loading, setLoading] = useState<boolean>(true);

  const newChainValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    schoolsInChain: Yup.number().typeError("Invalid numeric value").required("Required"),
    numberOfSurveysPerYear: Yup.number().typeError("Invalid numeric value").required("Required"),
    contactFirstName: Yup.string().required("Required"),
    contactLastName: Yup.string().required("Required"),
    contactEmail: Yup.string().required("Required"),
    contactPhone: Yup.string().required("Required"),
    contractStartDate: Yup.date().typeError("Invalid date value").required("Required"),
    contractEndDate: Yup.date()
      .typeError("Invalid date value")
      .required("Required")
      .min(Yup.ref("contractStartDate"), "End date can't be before Start date"),
  });

  const editChainValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    schoolsInChain: Yup.number().typeError("Invalid numeric value").required("Required"),
    numberOfSurveysPerYear: Yup.number().typeError("Invalid numeric value").required("Required"),
    contactFirstName: Yup.string().required("Required"),
    contactLastName: Yup.string().required("Required"),
    contactEmail: Yup.string().required("Required"),
    contactPhone: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (!params.chainCode) {
      setChain({ ...chain, id: 0 } as AddSchoolChainDto);
      return;
    }

    setLoading(true);
    agent.Chains.editDetails(params.chainCode)
      .then((result) => {
        setChain(result);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to load chain info");
      })
      .finally(() => setLoading(false));
  }, []);

  const addNewChain = (values: AddSchoolChainDto) => {
    setIsSaving(true);
    agent.Chains.add(values)
      .then(() => {
        toast.success("Chain added successully");
        navigate(`/dashboard/managechains`);
      })
      .catch((error) => toast.error("Failed to add school chain"))
      .finally(() => setIsSaving(false));
  };

  const updateChain = (values: AddSchoolChainDto) => {
    setIsSaving(true);
    agent.Chains.update(values)
      .then(() => {
        toast.success("Chain updated successully");
        navigate(`/dashboard/managechains`);
      })
      .catch((error) => toast.error("Failed to update school chain"))
      .finally(() => setIsSaving(false));
  };

  const submitForm = (values: AddSchoolChainDto) => {
    console.log(values);
    if (chain.id === 0) addNewChain(values);
    else updateChain(values);
  };

  const content = (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
              <h2>Chain License</h2>
            </div>
        <Formik
          validationSchema={
            chain && chain.id === 0 ? newChainValidationSchema : editChainValidationSchema
          }
          enableReinitialize
          initialValues={chain}
          onSubmit={(values) => submitForm(values)}
        >
          {({ handleSubmit }) => (
            <Form className="t4-add-edit" onSubmit={handleSubmit}>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Chain Name *"}
                      placeholder={"Chain Name"}
                      name="name"
                      disabled={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Number of schools in Chain *"}
                      placeholder={"Number of schools in Chain"}
                      name="schoolsInChain"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Number of surveys per year *"}
                      placeholder={"Number of surveys per year"}
                      name="numberOfSurveysPerYear"
                      disabled={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Website (Optional)"}
                      placeholder={"Website"}
                      name="chainWebsite"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomCheckboxInput
                      label="Include benchmark data"
                      type="checkbox"
                      disabled={false}
                      name="benchmarkData"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomCheckboxInput
                      label="Previous customer"
                      type="checkbox"
                      disabled={false}
                      name="previousCustomer"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Contact First Name *"}
                      placeholder={"Contact First Name"}
                      name="contactFirstName"
                      disabled={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Contact Last Name *"}
                      placeholder={"Contact Last Name"}
                      name="contactLastName"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Contact Email *"}
                      placeholder={"Contact Email"}
                      name="contactEmail"
                      disabled={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput
                      labeltext={"Contact Phone *"}
                      placeholder={"Contact Phone"}
                      name="contactPhone"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                {chain && chain.id === 0 && (
                  <Grid.Row>
                    <Grid.Column>
                      <CustomDateInput
                        label={"Contract start date *"}
                        placeholder={"Contract start date"}
                        name="contractStartDate"
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <CustomDateInput
                        label={"Contract end date *"}
                        placeholder={"Contract end date"}
                        name="contractEndDate"
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
              <div className="t4-form-bot-btns">
              <Button floated="right" className="t4-btn-active" type="submit" color="blue" content="Save" loading={isSaving} />
              <Button
              className="t4-btn-non"
              floated="right"
                  disabled={isSaving}
                  onClick={() => {
                    navigate(`/dashboard/managechains`);
                  }}
                >
                  Cancel
                </Button>
                
            </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );

  return <>{content}</>;
}
