import React from "react";
import { Checkbox, Table } from "semantic-ui-react";
import ChainSchoolDto from "../../../models/schools/chainSchoolDto";
import { NavLink } from "react-router-dom";

interface Props {
  schools: ChainSchoolDto[];
  selectedSchool: ChainSchoolDto | null;
  onSchoolSelected: (school: ChainSchoolDto) => void;
}

export default function ChainSchoolsList({ schools, selectedSchool, onSchoolSelected }: Props) {
  return (
    <>
      <Table className="t4-tbl sortable">
        <Table.Header>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell>School Name</Table.Cell>
            <Table.Cell>Co-ordinatiors</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {schools &&
            schools != null &&
            schools.length > 0 &&
            schools.map((school) => (
              <Table.Row key={school.schoolId}>
                <Table.Cell>
                  <Checkbox
                    checked={selectedSchool != null && selectedSchool.schoolId === school.schoolId}
                    onClick={() => onSchoolSelected(school)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <NavLink to={"/dashboard/schoolDetails/" + school.code}>{school.schoolName}</NavLink>
                </Table.Cell>
                <Table.Cell>{school.coordinatorsCount}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
