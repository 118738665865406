import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report } from "powerbi-client";
import agent from "../../../api/agent";
import ReportEmbedConfigDto from "../../../models/reports/reportEmbedConfigDto";
import { toast } from "react-toastify";
import SchoolDto from "../../../models/schools/schoolDto";
import { useStore } from "../../../Stores/store";
import { useNavigate } from "react-router-dom";

interface Props {
  schoolCode: string;
  schoolSurveyId: number;
}

export default function SchoolReportViewer({ schoolCode, schoolSurveyId }: Props) {
  const { userStore } = useStore();
  const navigate = useNavigate();
  const [reportConfig, setReportconfig] = useState<ReportEmbedConfigDto | undefined>(undefined);
  const [report, setReport] = useState<Report>();
  const [school, setSchool] = useState<SchoolDto | null>(null);

  useEffect(() => {
    agent.Schools.profile(schoolCode).then((result) => {
      setSchool(result);
    });

    agent.Reports.embed(schoolCode, schoolSurveyId)
      .then((result) => {
        setReportconfig(result);
        setTimeout(() => {
          userStore.logout();
          navigate("/");
        }, 7200000);
        //report?.reload();
      })
      .catch((error) => {
        toast.error("Failed to load school report. Please make sure the school has at least one project coordinator and try again.");
      });
  }, []);

  return (
    <>
      {reportConfig && school != null && (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: reportConfig.reportId, //report id
            embedUrl: reportConfig.embedUrl,
            accessToken: reportConfig.token,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed || Embed
            /*filters: [
              {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: "School Name",
                  column: "School",
                },
                operator: "In",
                values: [school.name],
                //values: ["GEMS Legacy School"],
                filterType: models.FilterType.Basic,
                requireSingleSelection: true,
              },
            ],*/
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  if (event) console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={(embeddedReport) => {
            setReport(embeddedReport as Report);
          }}
        />
      )}
    </>
  );
}
