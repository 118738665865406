import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingComponent from "../../Layout/loadingComponent";
import { Button, Segment, Select, Image } from "semantic-ui-react";
import InvitationDto from "../../../models/surveys/invitationDto";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import agent from "../../../api/agent";
import InvitationsList from "./invitationsList";
import InvalidEmailsWarning from "./invalidEmailsWarning";
import MissingInvitationsWarning from "./missingInvitationsWarning";
import SchoolNameControl from "../schoolNameControl";
import { SurveyStatuses } from "../../../models/enums";
import SendInvitationsConfirmation from "./sendInvitationsConfirmation";
import { PaginatedResult, PagingParams } from "../../../models/common/pagination";
import ConfirmFinalReminderPopup from "./confirmFinalReminderPopup";

export default function ManageInvitations() {
  let params = useParams();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<InvitationDto[]>();
  const [surveys, setSurveys] = useState<SchoolSurveyDto[]>();
  const [surveyOptions, setSurveyOptions] = useState<{ text: string; value: number }[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<SchoolSurveyDto>();
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [noSurveySetup, setNoSurveySetup] = useState<boolean>(false);
  const [invitationPossible, setInvitationPossible] = useState<boolean>(false);
  const [showFinalReminderConfirmation, setShowFinalReminderConfirmation] = useState<boolean>(false);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [pagingParams, setPagingParams] = useState<PagingParams>({
    pageNumber: 1,
    pageSize: 200,
    filter: "",
    orderBy: null,
  });

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    if (!params.schoolCode) return;

    setLoading(true);
    setNoSurveySetup(false);

    agent.Schools.invitationPossible(params.schoolCode).then((result) => setInvitationPossible(result));

    agent.Schools.surveys(params.schoolCode)
      .then((result) => {
        setSurveys(result);
        if (!result || result == null || result.length === 0) {
          //no surveys setup for the school
          setNoSurveySetup(true);
          setLoading(false);
          return;
        }

        let opts: { text: string; value: number }[] = [];
        result.forEach((s) => opts.push({ text: s.name, value: s.id }));
        setSurveyOptions(opts);

        let noActiveSurveys = true;
        for (var i = 0; i < result.length; i++) {
          if (
            result[i].status === SurveyStatuses.setup ||
            result[i].status === SurveyStatuses.SurveyRunning
          ) {
            setSelectedSurvey(result[i]);
            loadInvites(result[i].id, pagingParams);
            noActiveSurveys = false;
            break;
          }
        }

        if (noActiveSurveys) {
          //no surveys setup for the school
          setNoSurveySetup(true);
          setLoading(false);
          return;
        }
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });
  }, [params.schoolCode]);

  const loadInvites = (schoolSurveyId: number, pagingParams: PagingParams) => {
    setLoading(true);
    agent.Invitations.getDirectSurveyInvitations(params.schoolCode!, schoolSurveyId, pagingParams)
      .then((response) => {
        let result = response as unknown as PaginatedResult<InvitationDto[]>;
        if (result && result.pagination && result.pagination.totalItems)
          setTotalItemCount(result.pagination.totalItems);
        setInvitations(result.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectSurvey = (schoolSurveyId: number) => {
    if (!surveys) return;
    let page = { ...pagingParams, pageNumber: 1 };
    setPagingParams(page);
    loadInvites(schoolSurveyId, page);
    for (var i = 0; i < surveys.length; i++) {
      if (surveys[i].id === schoolSurveyId) {
        setSelectedSurvey(surveys[i]);
        break;
      }
    }
  };

  const noSurveyMessage = (
    <>
      <Segment>
        <Image src="/assets/nosurveys.svg" size="small" centered />
        <h3 className="t4-error-msg">There are no current active surveys!</h3>
      </Segment>
    </>
  );

  const content = (
    <>
      {showConfirmPopup && (
        <SendInvitationsConfirmation
          schoolCode={params.schoolCode}
          schoolSurveyId={selectSurvey == null ? undefined : selectedSurvey?.id}
          onClose={(refresh: boolean) => {
            setShowConfirmPopup(false);
            if (refresh && selectedSurvey) {
              let page = { ...pagingParams, pageNumber: 1 };
              setPagingParams(page);
              loadInvites(selectedSurvey.id, page);
            }
          }}
        />
      )}

      <div className="t4-mng-btns-cont">
        <div className="t4-mng-btns">
          <Button
            icon="send"
            disabled={!invitationPossible}
            content={"Invite Users"}
            onClick={() => {
              setShowConfirmPopup(true);
            }}
          ></Button>
          <Button
            icon="alarm"
            disabled={invitations == null || invitations.length === 0}
            content={"Send final reminder"}
            onClick={() => {
              setShowFinalReminderConfirmation(true);
            }}
          ></Button>
        </div>
        <div className="t4-action-ddl">
          <Select
            value={selectedSurvey?.id}
            options={surveyOptions}
            onChange={(e, d) => {
              selectSurvey(d.value as number);
            }}
          ></Select>
        </div>
      </div>
      <div id="divValidationMessages">
        <InvalidEmailsWarning schoolCode={params.schoolCode!} />
        <MissingInvitationsWarning schoolCode={params.schoolCode!} />
      </div>

      <InvitationsList
        schoolCode={params.schoolCode!}
        invitations={invitations}
        currentPage={pagingParams.pageNumber}
        totalItemCount={totalItemCount}
        onPageChange={(pageNumber) => {
          if (!selectedSurvey) return;
          let param = { ...pagingParams, pageNumber: pageNumber };
          setPagingParams(param);
          loadInvites(selectedSurvey?.id, param);
        }}
      />
    </>
  );

  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      {showFinalReminderConfirmation && (
        <ConfirmFinalReminderPopup
          onClose={() => setShowFinalReminderConfirmation(false)}
          schoolCode={params.schoolCode}
          selectedSurvey={selectedSurvey}
        />
      )}
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
          <Button
            className="t4-back-btn"
            icon="arrow left"
            onClick={() => {
              navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
            }}
          ></Button>
          <h1>Invitations</h1>
        </div>

        {!loading && !noSurveySetup && content}
        {loading && <LoadingComponent content={"Loading Invitations"} />}
        {noSurveySetup && noSurveyMessage}
      </Segment>
    </>
  );
}
