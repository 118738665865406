import React, { useEffect, useState } from "react";
import { Progress, Grid } from "semantic-ui-react";

interface Props {
  currentPage: number;
  totalPageCount: number;
}

export default function SurveyPager({ currentPage, totalPageCount }: Props) {
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    let p = Math.trunc((currentPage / totalPageCount) * 100);
    setProgress(p);
  }, [currentPage, totalPageCount]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>{progress}%</Grid.Column>
          <Grid.Column width={6}>
            <div className="t4-progress-bar">
              <Progress className="t4-progress-bar" percent={progress} />
            </div>
          </Grid.Column>
          <Grid.Column width={5}></Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
