import React, { useEffect, useState } from "react";
import { useStore } from "../../Stores/store";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Container, Dimmer, Loader, Segment, Image, Menu } from "semantic-ui-react";
import ManageAllSchools from "../Licensing/schools/manageAllSchools";
import ManageQuestions from "../Questions/QuestionManagement/manageQuestions";
import LeftNavigationMenu from "./leftNavigationMenu";
import LoginStatusControl from "../UserLogin/loginStatusControl";
import QuestionDetails from "../Questions/QuestionManagement/questionDetails";
import SchoolDetails from "../SchoolManagement/schoolDetails";
import { observer } from "mobx-react-lite";
import ViewScoringMatrix from "./ScoringMatrix/viewScoringMatrix";
import AnonymousInvitations from "../SchoolManagement/AnonymousInvitations/manageAnonymousInvitations";
import SchoolProfile from "../SchoolManagement/Profile/schoolProfile";
import SurveySettings from "../SchoolManagement/settings/surveySettings";
import SchoolReports from "../SchoolManagement/Reports/schoolReports";
import SchoolStaffMembers from "../SchoolManagement/Members/schoolStaffMembers";
import ManageSchoolSurveys from "../SchoolManagement/SurveyManagement/manageSchoolSurveys";
import ManageInvitations from "../SchoolManagement/Invitations/manageInvitations";
import ManageProjectCoordinators from "../SchoolManagement/ProjectCoordinators/manageProjectCoordinators";
import AddEditSchool from "../SchoolManagement/Profile/addEditSchool";
import ImportMembers from "../SchoolManagement/Members/importMembers";
import AddEditSchoolQuestion from "../SchoolManagement/settings/addEditSchoolQuestion";
import InvalidEmails from "../SchoolManagement/Invitations/invalidEmails";
import NoInvitationMembers from "../SchoolManagement/Invitations/NoInvitationMembers";
import SchoolChainDashboardHost from "../SchoolChainManagement/Dashboard/schoolChainDashboardHost";
import ExtraDocuments from "../SchoolManagement/extraDocuments";
import ManageChains from "../Licensing/Chains/manageChains";
import AdminView from "../Licensing/AdminView/adminView";
import AddEditSchoolLicense from "../Licensing/schools/addEditSchoolLicense";
import AddEditChainLicense from "../Licensing/Chains/addEditChainLicense";
import EditPillarsWeightings from "./ScoringMatrix/editPillarsWeightings";
import EditQuestionsWeightings from "./ScoringMatrix/editQuestionsWeightings";
import SchoolSurveysReports from "../SchoolManagement/Reports/schoolSurveysReports";
import MangeNotifications from "./Notifications/manageNotifications";

export default observer(function DashboardHost() {
  const { userStore, commonStore } = useStore();
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (commonStore.Token != null)
      userStore
        .getUser()
        .then((result) => {
          setUserLoaded(true);

          //if (userStore.isSchoolAdmin && userStore.user?.schoolCode)
          //  navigate(`/dashboard/schoolDetails/${userStore.user?.schoolCode}`);
        })
        .catch((error) => {
          commonStore.setToken(null);
          navigate("/");
        })
        .finally(() => {});
    else navigate("/");
  }, [commonStore, commonStore.Token]);

  const loader = (
    <Segment className="t4-loader">
      <Dimmer active inverted>
        <Loader className="loaderr t4-loading"></Loader>
      </Dimmer>
    </Segment>
  );

  const dashboardRouter = (
    <>
      <Container className="t4-survey-wrapper t4-mng">
        <Menu className="t4-topnav-menu">
          <Menu.Item>
            <Image src="/assets/T4_logo.svg" size="small" floated="left" />
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <div className="t4-logout">{userStore.isLoggedIn && <LoginStatusControl />}</div>
            </Menu.Item>

            <Menu.Item>{userStore.isSystemAdmin && <LeftNavigationMenu />}</Menu.Item>
          </Menu.Menu>
        </Menu>
        <Image src="/assets/t4_banner.svg" />

        <Routes>
          {userStore.isSystemAdmin && <Route path="/" element={<ManageAllSchools />} />}
          {userStore.isSystemAdmin && <Route path="/managequestions/:questionId" element={<QuestionDetails />} />}
          {userStore.isSystemAdmin && <Route path="/managequestions" element={<ManageQuestions />} />}
          {userStore.isSystemAdmin && <Route path="/manageschools" element={<ManageAllSchools />} />}
          {userStore.isSystemAdmin && <Route path="/newschool" element={<AddEditSchoolLicense />} />}
          {userStore.isSystemAdmin && <Route path="/managechains" element={<ManageChains />} />}
          {userStore.isSystemAdmin && <Route path="/newchain" element={<AddEditChainLicense />} />}
          {userStore.isSystemAdmin && <Route path="/editchain/:chainCode" element={<AddEditChainLicense />} />}
          {userStore.isSystemAdmin && <Route path="/adminView" element={<AdminView />} />}
          {userStore.isSystemAdmin && <Route path="/scoringMatrix" element={<ViewScoringMatrix />} />}
          {userStore.isSystemAdmin && <Route path="/notifications" element={<MangeNotifications />} />}
          {userStore.isSystemAdmin && <Route path="/editpillarsweightings" element={<EditPillarsWeightings />} />}
          {userStore.isSystemAdmin && <Route path="/editQuestionsWeightings/:foundationId" element={<EditQuestionsWeightings />} />}
          {userStore.isSystemAdmin && <Route path="/schoolsurveys/:schoolCode" element={<ManageSchoolSurveys />} />}
          <Route path="/schoolDetails/:schoolCode" element={<SchoolDetails />} />
          <Route path="/schoolprofile/:schoolCode" element={<SchoolProfile />} />
          <Route path="/editschoolprofile/:schoolCode" element={<AddEditSchool />} />
          <Route path="/inviteMembers/:schoolCode" element={<ManageInvitations />} />
          <Route path="/anonymousinvitations/:schoolCode" element={<AnonymousInvitations />} />
          <Route path="/schooladmins/:schoolCode" element={<ManageProjectCoordinators />} />
          <Route path="/surveysettings/:schoolCode" element={<SurveySettings />} />
          {/*<Route path="/:schoolCode/report/:schoolSurveyCode" element={<SchoolReports />} />*/}
          <Route path="/schoolsurveysreports/:schoolCode" element={<SchoolSurveysReports />} />
          <Route path="/schoolMembers/:schoolCode" element={<SchoolStaffMembers />} />
          <Route path="/importmembers/:schoolCode" element={<ImportMembers />} />
          <Route path="/failedInvitations/:schoolCode" element={<InvalidEmails />} />
          <Route path="/noinvitationmembers/:schoolCode" element={<NoInvitationMembers />} />
          <Route path="/newschoolquestion/:schoolCode/:questionType" element={<AddEditSchoolQuestion />} />

          <Route path="/editchoolquestion/:schoolCode/:questionCode" element={<AddEditSchoolQuestion />} />
          <Route path="/extradocuments/:schoolCode" element={<ExtraDocuments />} />
          <Route path="/chain/:chainCode/*" element={<SchoolChainDashboardHost />} />
        </Routes>
      </Container>
    </>
  );

  return (
    <>
      <div className="App t4-content-wrapper">
        {!userLoaded && loader}
        {userLoaded && dashboardRouter}
      </div>
    </>
  );
});
