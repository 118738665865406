import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Stores/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Header, Modal, Radio, Segment, Table, TableCell, TableHeader } from "semantic-ui-react";
import LoadingComponent from "../../Layout/loadingComponent";
import SchoolChainSurveyDto from "../../../models/chains/schoolChainSurveyDto";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { ReportStatuses, SurveyStatuses } from "../../../models/enums";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import { stat } from "fs";

interface Props {
  chainCode: string;
}

export default function ManageChainSchoolSurveys({ chainCode }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { lookupStore } = useStore();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<SchoolSurveyDto[]>([]);
  const [chainSurvey, setChainSurvey] = useState<SchoolChainSurveyDto | null>(null);
  const [closingSchoolSurveys, setClosingSchoolSurveys] = useState<boolean>(false);
  const [publishingSchoolSurveys, setPublishingSchoolSurveys] = useState<boolean>(false);

  const loadSurveys = () => {
    if (!chainCode || !params.surveyCode) return;

    setLoading(true);
    agent.Chains.surveySchools(chainCode, params.surveyCode)
      .then((result) => {
        setSurveys(result);
      })
      .catch(() => {
        toast.error("Failed to load survey details");
      })
      .finally(() => setLoading(false));
  };

  const changeSurveyStatus = (schoolCode: string | null | undefined, schoolSurveyId: number, status: SurveyStatuses) => {
    if (!schoolCode) return;
    setSaving(true);
    agent.Schools.updateSurveyStatus(schoolCode, schoolSurveyId, status)
      .then(() => {
        loadSurveys();
      })
      .catch((error) => {
        if (error.response.status === 409) toast.error(error.response.data);
        else toast.error("Faild to change survey status");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    if (!chainCode || !params.surveyCode) return;
    loadSurveyDetails();
    loadSurveys();
  }, []);

  const loadSurveyDetails = () => {
    if (!chainCode || !params.surveyCode) return;
    agent.Chains.surveyDetails(chainCode, params.surveyCode)
      .then((result) => {
        setChainSurvey(result);
      })
      .catch(() => {
        toast.error("Failed to load survey details");
      });
  };

  const updateAllSchoolStatus = (status: SurveyStatuses) => {
    if (!params.surveyCode) return;

    agent.Chains.updateSchoolsSurveyStatus(chainCode, params.surveyCode, status)
      .then(() => {
        toast.success("Survey status updated successfully");
        loadSurveys();
      })
      .catch(() => {
        toast.error("Failed to update status");
      })
      .finally(() => {
        setClosingSchoolSurveys(false);
        setPublishingSchoolSurveys(false);
      });
  };

  const toggleChainReportPublishStatus = () => {
    if (!chainSurvey || !params.surveyCode) return;
    let status = chainSurvey.reportStatus == ReportStatuses.Unpublished ? ReportStatuses.Published : ReportStatuses.Unpublished;
    setSaving(true);
    agent.Chains.updateChainReportPublishStatus(chainCode, params.surveyCode, status)
      .then(() => {
        toast.success("Status updated successfully");
        loadSurveyDetails();
      })
      .catch(() => {
        toast.error("Failed to udpate status");
      })
      .finally(() => setSaving(false));
  };

  const content = (
    <>
      <Segment className="t4-mainmenu t4-header-align">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/chain/${chainCode}/managechainsurveys`);
              }}
            ></Button>
            <Header as="h1" className="t4-header-left" textAlign="left">
              Wave Details: {chainSurvey == null ? "-" : chainSurvey.name}
            </Header>
          </div>
        </div>

        <div className="t4-mng-btns">
          {/*<Button
            icon="window close outline"
            loading={closingSchoolSurveys}
            disabled={closingSchoolSurveys || publishingSchoolSurveys || saving}
            content={"Close all school surveys"}
            onClick={() => {
              setClosingSchoolSurveys(true);
              updateAllSchoolStatus(SurveyStatuses.PendingBIApproval);
            }}
          ></Button>*/}
          {/*<Button
            icon="file alternate outline"
            loading={publishingSchoolSurveys}
            disabled={closingSchoolSurveys || publishingSchoolSurveys || saving}
            content={"Publish all school reports"}
            onClick={() => {
              setPublishingSchoolSurveys(true);
              updateAllSchoolStatus(SurveyStatuses.BiLive);
            }}
          ></Button>*/}
        </div>
        {/*<span className="t4-wave-toggle">
          Chain Report Published
          <Radio
            toggle
            disabled={chainSurvey == null || saving}
            checked={chainSurvey != null && chainSurvey.reportStatus == ReportStatuses.Published}
            onChange={() => {
              toggleChainReportPublishStatus();
            }}
          />
        </span>*/}
        <Table className="t4-tbl t4-tbl-long">
          <Table.Header>
            <Table.Row>
              <TableCell>School Survey ID</TableCell>
              <TableCell>School Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {surveys &&
              surveys.length > 0 &&
              surveys.map((survey) => (
                <Table.Row key={survey.id}>
                  <TableCell>{survey.id}</TableCell>
                  <TableCell>{survey.schoolName}</TableCell>

                  <TableCell>{lookupStore.getSurveyStatus(survey.status)}</TableCell>
                  <TableCell>
                    {survey.status === SurveyStatuses.PendingBIApproval && (
                      <Button
                        basic
                        className="t4-btn"
                        disabled={saving}
                        onClick={() => {
                          changeSurveyStatus(survey.schoolCode, survey.id, SurveyStatuses.SurveyRunning);
                        }}
                      >
                        Reopen
                      </Button>
                    )}
                    {survey.status === SurveyStatuses.BiLive && (
                      <Button
                        basic
                        className="t4-btn"
                        disabled={saving}
                        onClick={() => {
                          changeSurveyStatus(survey.schoolCode, survey.id, SurveyStatuses.PendingBIApproval);
                        }}
                      >
                        Unpublish
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {survey.status === SurveyStatuses.setup && (
                      <Button
                        basic
                        className="t4-btn"
                        disabled={saving}
                        onClick={() => {
                          changeSurveyStatus(survey.schoolCode, survey.id, SurveyStatuses.SurveyRunning);
                        }}
                      >
                        Run
                      </Button>
                    )}
                    {survey.status === SurveyStatuses.SurveyRunning && (
                      <Button
                        basic
                        className="t4-btn"
                        disabled={saving}
                        onClick={() => {
                          changeSurveyStatus(survey.schoolCode, survey.id, SurveyStatuses.PendingBIApproval);
                        }}
                      >
                        Close
                      </Button>
                    )}
                    {survey.status === SurveyStatuses.PendingBIApproval && (
                      <Button
                        basic
                        className="t4-btn"
                        disabled={saving}
                        onClick={() => {
                          changeSurveyStatus(survey.schoolCode, survey.id, SurveyStatuses.BiLive);
                        }}
                      >
                        Publish
                      </Button>
                    )}
                  </TableCell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );

  return (
    <>
      {!loading && content}
      {loading && <LoadingComponent content={"Loading Surveys"} />}
    </>
  );
}
