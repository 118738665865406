import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dimmer,
  Flag,
  FlagNameValues,
  Grid,
  GridColumn,
  GridRow,
  Input,
  Loader,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import QuestionLanguageTranslationDto from "../../../models/surveys/questionLanguageTranslationDto";
import cloneDeep from "lodash.clonedeep";
import _ from "lodash";
import LanguageDto from "../../../models/surveys/languageDto";

interface params {
  open: boolean;
  language: LanguageDto;
  questionId: number;
  setModalOpen: (open: boolean) => void;
  onSave: () => void;
}

export default function AddEditQuestionTranslation({
  open,
  setModalOpen,
  language,
  questionId,
  onSave,
}: params) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [translation, setTranslation] = useState<QuestionLanguageTranslationDto>();

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loader = (
    <Segment className="t4-loader">
      <Dimmer active inverted>
      <Loader className="loaderr t4-loading"></Loader>
      </Dimmer>
    </Segment>
  );

  const updateBody = (body: string) => {
    let t = cloneDeep(translation);
    if (t) {
      t.translatedBody = body;
      setTranslation(t);
    }
  };

  const updateChoiceBody = (choiceId: number, body: string) => {
    let t = cloneDeep(translation);
    if (t && t.choices && t.choices.length > 0) {
      for (var i = 0; i < t.choices.length; i++) {
        if (t.choices[i].id === choiceId) t.choices[i].translatedBody = body;
      }
      setTranslation(t);
    }
  };

  const hasValue = (text: string | undefined | null): boolean => {
    if (!text || text == null || text.trim() === "") return false;
    return true;
  };

  const validatePage = (): boolean => {
    if (!hasValue(translation?.translatedBody)) return false;
    if (translation?.choices && translation.choices.length > 0) {
      for (var i = 0; i < translation.choices.length; i++) {
        if (!hasValue(translation.choices[i].translatedBody)) return false;
      }
    }
    return true;
  };

  useEffect(() => {
    agent.Translations.questionTranslation(questionId, language.locale)
      .then((result) => {
        setTranslation(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [questionId, language]);

  const saveTranslations = () => {
    setSaving(true);
    if (!validatePage()) {
      toast.error("Please complete all fields !");
      setSaving(false);
      return;
    }

    if (!translation) return;
    agent.Translations.saveQuestionTranslation(questionId, language.locale, translation)
      .then((result) => {
        onSave();
        setModalOpen(false);
      })
      .catch((error) => {
        handleError(error);
        return;
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const content = (
    <>
      <div>
        <Flag name={language.flagName as FlagNameValues} />
      </div>
      <div className="t4-add-edit">
        {translation?.body}
        <TextArea
          className="t4-add-edit-questions"
          type="text"
          value={translation?.translatedBody}
          onChange={(e) => {
            updateBody(e.target.value);
          }}
        ></TextArea>
      </div>
      <Grid className="t4-add-edit">
        {translation?.choices &&
          translation.choices.length > 0 &&
          translation.choices.map((t) => (
            <GridRow key={t.id} className="t4-add-edit-answers">
              <GridColumn width={5}>
                <span>{t.body}</span>
              </GridColumn>
              <GridColumn width={11}>
                <Input
                  type="text"
                  value={t?.translatedBody}
                  onChange={(e) => {
                    updateChoiceBody(t.id, e.target.value);
                  }}
                ></Input>
              </GridColumn>
            </GridRow>
          ))}
      </Grid>
    </>
  );

  return (
    <>
      <Modal open={open} onClose={() => setModalOpen(false)} onOpen={() => setModalOpen(true)}>
        <Modal.Header>Question Management</Modal.Header>
        <Modal.Content scrolling>
          {loading && loader}
          {!loading && content}
        </Modal.Content>
        <Modal.Actions>
          <div className="t4-modal-btns">
          <Button onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button
            className="t4-btn"
            loading={saving}
            content={"Save"}
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              saveTranslations();
            }}
            positive
          />
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
}
