import React, { useEffect, useState } from "react";
import SchoolProjectStatusDto from "../../../models/schools/schoolProjectStatusDto";
import { Checkbox, Pagination, Popup, Table } from "semantic-ui-react";
import { gridPageSize } from "../../../models/constants";
import GridColumnFilterConrol from "../../../Common/gridColumnFilterConrol";
import LookupDto from "../../../models/common/lookupDto";
import agent from "../../../api/agent";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useStore } from "../../../Stores/store";

interface Props {
  items: SchoolProjectStatusDto[] | null;
  totalItemCount: number;
  currentPage: number;
  onFilter: () => void;
  onPageChange: (newPageNumber: number) => void;
  selectedSchoolsFilter: number[];
  selectedChainsFilter: number[];
  selectedStatusFilter: number[];
  onSchoolsFilterChanged: (selected: number[]) => void;
  onChainsFilterChanged: (selected: number[]) => void;
  onStatusFilterChanged: (selected: number[]) => void;
}

export default function ProjectsList({
  items,
  totalItemCount,
  currentPage,
  onPageChange,
  onFilter,
  selectedSchoolsFilter,
  onSchoolsFilterChanged,
  selectedChainsFilter,
  onChainsFilterChanged,
  selectedStatusFilter,
  onStatusFilterChanged,
}: Props) {
  const { lookupStore } = useStore();
  const [chains, setChains] = useState<LookupDto[]>([]);
  const [schools, setSchools] = useState<LookupDto[]>([]);
  const [updatingSchool, setUpdatingSchool] = useState<number>(0);
  const projectStatuses: LookupDto[] = [
    { text: "Setup", value: 1 },
    { text: "Survey Running", value: 2 },
    { text: "BI Awaiting Approval", value: 3 },
    { text: "BI Live", value: 4 },
  ];

  useEffect(() => {
    agent.Chains.lookups().then((result) => setChains(result));
    agent.Schools.lookups().then((result) => setSchools(result));
  }, []);

  const gridPaging = (
    <Pagination
      disabled={items == null || totalItemCount <= gridPageSize}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={items == null ? 0 : Math.ceil(totalItemCount / gridPageSize)}
      onPageChange={(e, d) => {
        if (d.activePage) onPageChange(+d.activePage);
      }}
    />
  );

  const content = (
    <>
      <Table className="t4-tbl">
        <Table.Header>
          <Table.Row>
            <Table.Cell>
              School Chain
              {/* Chains Filter */}
              <GridColumnFilterConrol
                items={chains}
                onFilterChanged={(items: number[]) => {
                  onChainsFilterChanged(items);
                }}
                onFilter={() => onFilter()}
                selectedItems={selectedChainsFilter}
              />
            </Table.Cell>
            <Table.Cell>
              School Name
              {/* Schools Filter */}
              <GridColumnFilterConrol
                items={schools}
                onFilterChanged={(items: number[]) => {
                  onSchoolsFilterChanged(items);
                }}
                onFilter={() => onFilter()}
                selectedItems={selectedSchoolsFilter}
              />
            </Table.Cell>
            <Table.Cell>First Survey Invite Date</Table.Cell>
            <Table.Cell>Survey Completion</Table.Cell>
            <Table.Cell>Total Logins</Table.Cell>
            <Table.Cell>Logins last 30 days</Table.Cell>
            <Table.Cell>Last login</Table.Cell>
            <Table.Cell>
              Survey Status
              {/* Project Status Filter */}
              <GridColumnFilterConrol
                items={projectStatuses}
                onFilterChanged={(items: number[]) => {
                  onStatusFilterChanged(items);
                }}
                onFilter={() => onFilter()}
                selectedItems={selectedStatusFilter}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items &&
            items.map((project) => (
              <Table.Row key={project.schoolId}>
                <Table.Cell>
                  {project.chainCode != null && project.chainCode != "" && <NavLink to={`/dashboard/chain/${project.chainCode}`}>{project.chainName}</NavLink>}
                </Table.Cell>
                <Table.Cell>
                  {" "}
                  <NavLink to={"/dashboard/schoolDetails/" + project.schoolCode}>{project.schoolName}</NavLink>
                </Table.Cell>
                <Table.Cell>{project.firstSurveyDate}</Table.Cell>
                <Table.Cell>{project.completionPercentage}%</Table.Cell>
                <Table.Cell>{project.totalLogins}</Table.Cell>
                <Table.Cell>{project.loginsLast30Days}</Table.Cell>
                <Table.Cell>{project.lastLoginDate}</Table.Cell>
                <Table.Cell>{project.projectStatus}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {items != null && gridPaging}
    </>
  );

  return <>{items != null && content}</>;
}
