import React, { useState } from "react";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";
import { Card, Label, Icon, CardContent, Button, Image, Checkbox } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import { SurveyStatuses } from "../../../models/enums";
import { useStore } from "../../../Stores/store";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

interface Props {
  survey: SchoolSurveyDto;
  schoolCode: string;
}

export default function SurveyReportSummary({ survey, schoolCode }: Props) {
  const { lookupStore } = useStore();
  const [downloading, setDownloading] = useState<boolean>(false);

  const downloadSchoolData = () => {
    if (!survey || !schoolCode) return;
    setDownloading(true);
    agent.Schools.downloadSurveyResponses(schoolCode, survey.id)
      .catch((error) => {
        if (error.response.status === 422) toast.error("Please complete the school profile to be able to download report data.");
        else toast.error("Failed to download school report data");
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <>
      <Card className="t4-reports-cards t4-chains">
        <Card.Content>
          <i className="t4-card-icn outline icon" />
          <Card.Header>{survey.name}</Card.Header>
          <Card.Description>
            <div className="ui two buttons t4-reports-container">
              <Link className="t4-reports-links" to={`/school/${schoolCode}/report/${survey.code}`} target="_blank">
                <div className="t4-report-type t4-vis">
                  <Icon className="t4-vis-report" size="large" />
                  <Label>Visual Report</Label>
                </div>
              </Link>
              <Button className="t4-reports-links" onClick={() => downloadSchoolData()} loading={downloading}>
                <div className="t4-report-type t4-data">
                  <Icon className="t4-vis-report" size="large" />
                  <Label>
                    <Icon name="download" />
                    Data Report
                  </Label>
                </div>
              </Button>
            </div>
          </Card.Description>
          <div className="t4-reports-status">
            {survey.status === SurveyStatuses.setup && (
              <Label className="t4-status-incomp" basic color="blue">
                <Icon name="circle" />
                {lookupStore.getSurveyStatus(survey.status)}
              </Label>
            )}
            {survey.status === SurveyStatuses.SurveyRunning && (
              <Label className="t4-status-incomp" basic color="yellow">
                <Icon name="circle" />
                {lookupStore.getSurveyStatus(survey.status)}
              </Label>
            )}
            {survey.status === SurveyStatuses.PendingBIApproval && (
              <Label className="t4-status-incomp" basic color="orange">
                <Icon name="circle" />
                {lookupStore.getSurveyStatus(survey.status)}
              </Label>
            )}
            {survey.status === SurveyStatuses.BiLive && (
              <Label className="t4-status-incomp" basic color="green">
                <Icon name="circle" />
                {lookupStore.getSurveyStatus(survey.status)}
              </Label>
            )}
          </div>
        </Card.Content>
      </Card>
    </>
  );
}
