import React from "react";
import QuestionDto from "../../../models/surveys/questionDto";
import { Radio, Segment } from "semantic-ui-react";
import parse from "html-react-parser";
import { useStore } from "../../../Stores/store";

interface Props {
  question: QuestionDto;
  onAnswer: (value: number, response: string) => void;
}

export default function ViewRating({ question, onAnswer }: Props) {
  const { translationStore } = useStore();
  return (
    <>
      <Segment className={question.isAnswered === false ? "t4-rate required" : "t4-rate"}>
        <div>{parse(translationStore.questionBody(question))}</div>
        <ul className="t4-rate-stars">
          {question.choices &&
            question.choices.length > 0 &&
            question.choices.map((c) => (
              <li key={c.id}>
                <Radio
                  label={""}
                  className={question.response && +c.body <= +question.response?"highlight-radio":""}
                  checked={question.userAnswer != undefined && question.userAnswer === c.id}
                  onChange={() => onAnswer(+c.id, c.body)}
                />
              </li>
            ))}
        </ul>
        {/*{question.isAnswered === false && <Message color={"red"}>Required</Message>}*/}
      </Segment>
      <br />
    </>
  );
}
