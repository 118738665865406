import { SortBy } from "../enums";

export interface Pagination {
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
    totalPages: number
}

export class PaginatedResult<T>{
    data: T;
    pagination: Pagination

    constructor(data: T, pagination: string) {
        this.data = data;
        this.pagination = JSON.parse(pagination);
    }
}

export class PagingParams {
    pageNumber = 1;
    pageSize = 10;
    filter = '';
    orderBy: SortBy | null = null;
}

