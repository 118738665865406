import { Card, CardContent, Icon, Label, Image } from "semantic-ui-react";
import SchoolChainSurveyDto from "../../../models/chains/schoolChainSurveyDto";
import { Link, NavLink } from "react-router-dom";
import { ReportStatuses } from "../../../models/enums";

interface Props {
  survey: SchoolChainSurveyDto;
  chainCode: string;
}

export default function ChainSurveyReportSummary({ survey, chainCode }: Props) {
  return (
    <>
      <Card className="t4-reports-cards t4-waves" >
        <Card.Content>
          <i className="t4-card-icn outline icon" />
          <Card.Header>{survey.name}</Card.Header>
          <Card.Description>
          <div className='ui two buttons t4-reports-container'>
            <Link className="t4-reports-links" to={`/chain/report/${chainCode}/survey/${survey.code}`} target="_blank">
            <div className="t4-report-type t4-vis">
              <Icon className="t4-vis-report" size="large" />
              <Label>Visual Report</Label>
            </div>
            </Link>
          </div>
          </Card.Description>
          <div className="t4-reports-status">
        {survey.reportStatus === ReportStatuses.Unpublished && (
              <Label className="t4-status-incomp" basic color="orange">
                <Icon name="circle" />
                {"Pending Approval"}
              </Label>
            )}
            {survey.reportStatus === ReportStatuses.Published && (
              <Label className="t4-status-incomp" basic color="green">
                <Icon name="circle" />
                {"Published"}
              </Label>
            )}
            </div>
        </Card.Content>
      </Card>
    </>
  );
}
