import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import StaffMemberDto from "../../../models/schools/staffMemberDto";
import agent from "../../../api/agent";
import LoadingComponent from "../../Layout/loadingComponent";
import { Button, Segment, Table } from "semantic-ui-react";
import SchoolNameControl from "../schoolNameControl";
import SendInvitationsConfirmation from "./sendInvitationsConfirmation";
import SchoolSurveyDto from "../../../models/schools/schoolSurveyDto";

export default function NoInvitationMembers() {
  let params = useParams();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<StaffMemberDto[]>();
  const [activeSchoolSurvey, setActiveSchoolSurvey] = useState<SchoolSurveyDto>();
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [invitationPossible, setInvitationPossible] = useState<boolean>(false);

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  useEffect(() => {
    if (!params.schoolCode) return;
    setLoading(true);
    agent.Schools.invitationPossible(params.schoolCode).then((result) => setInvitationPossible(result));

    agent.Schools.activeSchoolsurvey(params.schoolCode)
      .then((result) => {
        setActiveSchoolSurvey(result);
        if (!params.schoolCode || !result) return;
        agent.Schools.missingInvitationMembers(params.schoolCode)
          .then((result) => {
            setMembers(result);
          })
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        toast.error("Failed to retrieve active survey");
      });
  }, []);

  const inviteUser = () => {
    setShowConfirmPopup(true);
  };

  const content = (
    <>
      {showConfirmPopup && (
        <SendInvitationsConfirmation
          schoolCode={params.schoolCode}
          schoolSurveyId={activeSchoolSurvey == null ? undefined : activeSchoolSurvey?.id}
          onClose={(refresh: boolean) => {
            setShowConfirmPopup(false);
            if (refresh && activeSchoolSurvey) navigate(`/dashboard/inviteMembers/${params.schoolCode}`);
          }}
        />
      )}
      <div className="t4-sticky-btns">
        <Button
          icon="arrow left"
          onClick={() => {
            navigate(`/dashboard/schoolDetails/${params.schoolCode}`);
          }}
        ></Button>
      </div>
      <Segment className="t4-mainmenu">
        <div className="t4-mng-btns">
          <Button
            icon="send"
            disabled={!members || members == null || members.length === 0 || !invitationPossible}
            content={"Invite Users"}
            onClick={() => {
              inviteUser();
            }}
          ></Button>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>ID</Table.Cell>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>Email</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {members &&
              members.length > 0 &&
              members.map((member) => (
                <Table.Row key={member.id}>
                  <Table.Cell>{member.id}</Table.Cell>
                  <Table.Cell>{member.displayName}</Table.Cell>
                  <Table.Cell>{member.email}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );

  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      {!loading && content}
      {loading && <LoadingComponent content={"Loading..."} />}
    </>
  );
}
