import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Button, Label, Header, Icon, Segment, Grid, Image } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Stores/store";
import CustomTextInput from "../../Common/customTextInput";
import { useNavigate } from "react-router-dom";

export default observer(function LoginForm() {
  const { commonStore, userStore } = useStore();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (commonStore.Token != null && userStore.isSystemAdmin) {
      navigate("/dashboard/adminView");
    } else if (commonStore.Token != null && userStore.isSchoolAdmin) {
      navigate(`/dashboard/schoolDetails/${userStore.user?.schoolCode}`);
    } else if (commonStore.Token != null && userStore.isChainAdmin) {
      navigate(`/dashboard/chain/${userStore.user?.chainCode}`);
    }
  }, []);

  return (
    <>
      <div className="t4-home">
        <Segment className="home-segment nostyle">
          <div className="t4-parallelogram"></div>
          <Grid columns={3} stackable textAlign="center">
            <Grid.Row>
              <Grid.Column width={8}>
                <Image
                  className="t4-login-logo"
                  src="/assets/BSTW-Logo Horizontal.png"
                  size="medium"
                  floated="left"
                />
                <Image
                  className="t4-login-img"
                  src="/assets/BSTW-decoupage.png"
                  size="huge"
                  floated="left"
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Segment className="t4-login-segment">
                  <Formik
                    initialValues={{ userName: "", password: "", error: null }}
                    onSubmit={(values, { setErrors }) => {
                      setIsSubmitting(true);
                      userStore
                        .login(values)
                        .then(() => {
                          if (commonStore.Token != null && userStore.isSystemAdmin) {
                            navigate("/dashboard/adminView");
                          } else if (commonStore.Token != null && userStore.isSchoolAdmin) {
                            navigate(`/dashboard/schoolDetails/${userStore.user?.schoolCode}`);
                          } else if (commonStore.Token != null && userStore.isChainAdmin) {
                            navigate(`/dashboard/chain/${userStore.user?.chainCode}`);
                          }
                        })
                        .catch((error) => {
                          setErrors({ error: "Invalid username or password" });
                        })
                        .finally(() => {
                          setIsSubmitting(false);
                        });
                    }}
                  >
                    {({ handleSubmit, errors }) => (
                      <Form className="t4-login-form" onSubmit={handleSubmit} autoComplete="off">
                        <Header as="h2" content="Login" textAlign="center" />
                        <CustomTextInput
                          name="userName"
                          placeholder="Username"
                          labeltext="Username"
                          disabled={false}
                        ></CustomTextInput>
                        <CustomTextInput
                          disabled={false}
                          name="password"
                          placeholder="Password"
                          labeltext="Password"
                          type={showPassword ? "text" : "password"}
                          icon={
                            <Icon
                              name={showPassword ? "eye slash" : "eye"}
                              link
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            />
                          }
                        ></CustomTextInput>
                        <ErrorMessage
                          name="error"
                          render={() => (
                            <Label
                              style={{ marginBottom: 10 }}
                              basic
                              color="red"
                              content={errors.error}
                            />
                          )}
                        />
                        <Button
                          className="t4-btn-active"
                          loading={isSubmitting}
                          positive
                          content="Login"
                          type="submit"
                        />
                      </Form>
                    )}
                  </Formik>
                </Segment>
              </Grid.Column>
              <Grid.Column width={2}>
                <Image
                  className="t4-login-logo-bottom"
                  src="/assets/Logo_T4_Transparent.png"
                  size="medium"
                  floated="right"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    </>
  );
});
