import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon, Segment, Table } from "semantic-ui-react";
import { DocumentTypes } from "../../../models/enums";
import SchoolDocument from "../../../models/schools/schoolDocument";
import agent from "../../../api/agent";

interface Props {
  chainCode: string;
}

export default function ChainExtraDocuments({ chainCode }: Props) {
  let params = useParams();
  const navigate = useNavigate();

  const files: SchoolDocument[] = [
    {
      name: "Portal Guide for Chain Coordinators -Best School to Work 050424",
      path: "Portal Guide for Chain Coordinators -Best School to Work 050424",
      type: DocumentTypes.Pdf,
    },
    {
      name: "Onboarding Doc for School Chains - Best School To Work",
      path: "Onboarding Doc for School Chains - Best School To Work",
      type: DocumentTypes.Pdf,
    },
  ];

  return (
    <>
      <Segment className="t4-mainmenu t4-dox">
        <div className="t4-header-collection">
          <div className="t4-headers">
            <Button
              className="t4-back-btn"
              icon="arrow left"
              onClick={() => {
                navigate(`/dashboard/chain/${chainCode}`);
              }}
            ></Button>
            <h1>Extra Documents</h1>
          </div>
        </div>
        <div className="t4-mng-btns"></div>
        <Table className="t4-tbl t4-tbl-long">
          <Table.Header>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell>File</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {files &&
              files.map((file, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Icon
                      name={file.type === DocumentTypes.Pdf ? "file pdf" : "file word"}
                      size="large"
                    />
                  </Table.Cell>
                  <Table.Cell className="t4-file">{file.name}</Table.Cell>
                  <Table.Cell className="t4-tbl-action">
                    <a href={`/documents/${file.path}`} target="_blank" rel="noreferrer">
                      <Icon name="eye" />
                    </a>
                  </Table.Cell>
                  <Table.Cell className="t4-tbl-action">
                    <Icon
                      name="download"
                      onClick={() => {
                        agent.ContentFiles.download(file.path);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
}
