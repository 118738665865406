import React, { useEffect, useState } from "react";
import agent from "../api/agent";
import { useParams } from "react-router-dom";
import { Document } from "react-pdf";
import { pdfjs } from "react-pdf";

export default function FileViewer() {
  let { fileName } = useParams();
  const [fileContent, setFileContent] = useState<any>();

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  useEffect(() => {
    if (!fileName) return;
    agent.ContentFiles.open(fileName).then((result) => {
      setFileContent(result);
    });
  }, [fileName]);
  return (
    <>
      {/*fileContent && <Document file={fileContent} />*/}
      <object data={fileContent} width="100%" height={1500} type="application/pdf">
        Loading ...
      </object>
    </>
  );
}
