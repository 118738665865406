import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { ContactDto } from "../../../models/schools/contactDto";
import agent from "../../../api/agent";

interface Props {
  schoolCode: string;
}

export default function SchoolContacts({ schoolCode }: Props) {
  const [contacts, setContacts] = useState<ContactDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!schoolCode) return;

    setLoading(true);
    agent.Schools.contacts(schoolCode)
      .then((result) => {
        setContacts(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [schoolCode]);

  const content = (
    <>
      <h3>Contacts</h3>
      <Table className="t4-tbl">
        <Table.Header>
          <Table.Row>
            <Table.Cell>First name</Table.Cell>
            <Table.Cell>Last name</Table.Cell>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>Phone</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {contacts &&
            contacts.length > 0 &&
            contacts.map((contact) => (
              <Table.Row key={contact.id}>
                <Table.Cell>{contact.firstName}</Table.Cell>
                <Table.Cell>{contact.lastName}</Table.Cell>
                <Table.Cell>{contact.email}</Table.Cell>
                <Table.Cell>{contact.phone}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );

  return <>{content}</>;
}
