import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Segment, Image, Button, Label, Loader, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import agent from "../../api/agent";
import { useStore } from "../../Stores/store";
import SchoolSurveyStatsDto from "../../models/schools/schoolSurveyStatsDto";
import SchoolNameControl from "./schoolNameControl";

export default function SchoolDetails() {
  let params = useParams();
  const { userStore } = useStore();
  const [memberCount, setMemberCount] = useState<number>();
  const [countingMembers, setCountingMembers] = useState<boolean>();
  const [adminCount, setAdminCount] = useState<number>();
  const [countingAdmins, setCountingAdmins] = useState<boolean>();
  const [surveyStats, setSurveyStats] = useState<SchoolSurveyStatsDto>();
  const [invalidMailCount, setInvalidMailCount] = useState<number>(0);
  const [missingInvitationCount, setMissingInvitationCount] = useState<number>(0);
  const [missingProfileInformation, setMissingProfileInformation] = useState<boolean>(false);

  useEffect(() => {
    if (!params.schoolCode) return;

    setCountingMembers(true);

    agent.Schools.membersCount(params.schoolCode)
      .then((result) => {
        setMemberCount(result);
      })
      .catch((error) => {
        toast.error("Failed to retrieve school members count");
      })
      .finally(() => {
        setCountingMembers(false);
      });

    setCountingAdmins(true);
    agent.Schools.adminCount(params.schoolCode)
      .then((result) => {
        setAdminCount(result);
      })
      .catch((error) => {
        toast.error("Failed to retrieve project coordinators count");
      })
      .finally(() => {
        setCountingAdmins(false);
      });

    agent.Schools.activeSchoolsurvey(params.schoolCode)
      .then((result) => {
        if (!params.schoolCode || !result) return;
        agent.Schools.schoolSurveyStats(params.schoolCode, result.id)
          .then((result) => setSurveyStats(result))
          .catch((error) => {
            toast.error("Failed to retrieve survey stats");
          });
      })
      .catch((error) => {
        toast.error("Failed to retrieve survey stats");
      });

    agent.Schools.invalidEmailInvitationsCount(params.schoolCode).then((result) => setInvalidMailCount(result));

    agent.Schools.missingInvitationsCount(params.schoolCode).then((result) => setMissingInvitationCount(result));

    agent.Schools.isProfileCompeted(params.schoolCode).then((result) => setMissingProfileInformation(!result));
  }, [params.schoolCode]);

  const content = (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-home-settings">
          <div className="t4-mng-icon-btns">
            <Button
              className="t4-manage-btn"
              basic
              right="true"
              icon={<i className="t4-staff-icn icon" />}
              content="Manage Co-ordinators"
              as={NavLink}
              to={`/dashboard/schooladmins/${params.schoolCode}`}
            />
            <Button className="t4-settings-btn" basic floated="right" right="true" icon="settings" as={NavLink} to={`/dashboard/surveysettings/${params.schoolCode}`} />
          </div>
        </div>
        <Card.Group>
          <Card className="t4-mainmenu-cards" as={NavLink} to={`/dashboard/schoolprofile/${params.schoolCode}`}>
            <Card.Content>
              <i className="t4-card-icn edit outline icon" />
              <Card.Header>Edit School Details</Card.Header>
              <Card.Meta>
                <p>
                  {missingProfileInformation && (
                    <Label className="t4-status-incomp" basic color="orange">
                      <Icon name="exclamation triangle" />
                      incomplete
                    </Label>
                  )}
                </p>
              </Card.Meta>
            </Card.Content>
          </Card>
          <Card className="t4-mainmenu-cards" as={NavLink} to={`/dashboard/schoolMembers/${params.schoolCode}`}>
            <Card.Content>
              <i className="t4-card-icn t4-staff-icn icon" />
              <Card.Header>
                Manage Staff Profiles
                {countingMembers && <Loader size="mini">Loading</Loader>}
              </Card.Header>
              <Card.Meta>
                <p>
                  {!countingMembers && memberCount === 0 && (
                    <Label className="t4-status-incomp" basic color="orange">
                      <Icon name="exclamation triangle" />
                      incomplete
                    </Label>
                  )}
                </p>
              </Card.Meta>
            </Card.Content>
          </Card>
          <Card className="t4-mainmenu-cards" as={NavLink} to={`/dashboard/inviteMembers/${params.schoolCode}`}>
            <Card.Content>
              <i className="t4-card-icn send icon" />
              <Card.Header>Manage invitations</Card.Header>

              {(invalidMailCount > 0 || missingInvitationCount > 0) && (
                <span className="t4-warn">
                  <Icon name="exclamation triangle" /> Warning
                </span>
              )}
            </Card.Content>
          </Card>
          {userStore.isSystemAdmin && (
            <>
              <Card className="t4-mainmenu-cards" as={NavLink} to={`/dashboard/anonymousinvitations/${params.schoolCode}`}>
                <Card.Content>
                  <i className="t4-card-icn send icon" />
                  <Card.Header>Anonymous Invitations</Card.Header>
                </Card.Content>
              </Card>
              <Card className="t4-mainmenu-cards" as={NavLink} to={`/dashboard/schoolsurveys/${params.schoolCode}`}>
                <Card.Content>
                  <i className="t4-card-icn t4-waves-icn icon" />
                  <Card.Header>Manage Waves</Card.Header>
                </Card.Content>
              </Card>
            </>
          )}
        </Card.Group>
      </Segment>
    </>
  );
  const dashboard = (
    <>
      <Segment className="t4-dashboard t4-mainmenu">
        <Card.Group>
          <Card className="t4-dashboard-cards">
            <Card.Content>
              <Card.Description>Sent / Staff</Card.Description>
              <Card.Header> {surveyStats == null ? "-- / --" : `${surveyStats.sentInvitesCount} / ${surveyStats.totalStaffCount}`}</Card.Header>
            </Card.Content>
          </Card>
          <Card className="t4-dashboard-cards">
            <Card.Content>
              <Card.Description>Responses / Invitations</Card.Description>
              <Card.Header> {surveyStats == null ? "-- / --" : `${surveyStats.completedInvitations} / ${surveyStats.totalInvitations}`}</Card.Header>
            </Card.Content>
          </Card>
          <Card className="t4-dashboard-cards">
            <Card.Content>
              <Card.Description>Responses / Min. requirement</Card.Description>
              <Card.Header>{surveyStats == null ? "-- / --" : `${surveyStats.completionPercentage}% / 70%`}</Card.Header>
            </Card.Content>
          </Card>
          <Card
            className="t4-mainmenu-cards t4-content-cards"
            as={NavLink}
            to={`/dashboard/schoolsurveysreports/${params.schoolCode}`}
            /*onClick={() => {
              window.open(`/schoolsurveysreports/${params.schoolCode}`, "_blank", "noreferrer");
            }} */
          >
            <Card.Header>Reports</Card.Header>
            <Card.Content>
              <Card.Meta>
                <Image floated="right" size="large" src="/assets/reports.svg" />
              </Card.Meta>
            </Card.Content>
          </Card>
          <Card className="t4-mainmenu-cards t4-content-cards" as={NavLink} to={`/dashboard/extradocuments/${params.schoolCode}`}>
            <Card.Header>Extra Documents</Card.Header>
            <Card.Content>
              <Card.Meta>
                <Image floated="right" size="large" src="/assets/extradocs.png" />
              </Card.Meta>
            </Card.Content>
          </Card>
        </Card.Group>
      </Segment>
    </>
  );

  return (
    <>
      <SchoolNameControl schoolCode={params.schoolCode} />
      {content}
      {dashboard}
    </>
  );
}
