import React from "react";
import { useStore } from "../../Stores/store";
import { Button, Dropdown } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

export default function LoginStatusControl() {
  const { userStore } = useStore();
  const navigate = useNavigate();

  const loggedInTemplate = (
    <>
      <Dropdown className="t4-logout-dd" text={"Welcome " + userStore.user?.displayName}>
        <Dropdown.Menu>
          <Dropdown.Item>
            <Button
              onClick={() => {
                userStore.logout();
                navigate("/");
              }}
            >
              Logout
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );

  return <>{userStore && loggedInTemplate}</>;
}
